var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"left"},[_c('div',{staticClass:"left_top"},[_c('div',{staticClass:"left_top_title"},[_vm._v(" "+_vm._s(_vm.dataQuery.programmeName || _vm.dataQuery.title || _vm.dataQuery.productName)+" ")]),_c('div',{staticClass:"left_top_time"},[_vm._v(" 发布时间:"+_vm._s(_vm.dataQuery.publishTime)+" ")])]),_c('div',{directives:[{name:"resize-images",rawName:"v-resize-images"}],staticClass:"left_bottom",domProps:{"innerHTML":_vm._s(
        _vm.handleContent(
          _vm.dataQuery.programmeContent ||
            _vm.dataQuery.content ||
            _vm.dataQuery.productInfo
        )
      )}})]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("关注企业公众号")]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../assets/home/详情二维码.png"),"alt":""}})]),_c('div',{staticClass:"right_bottom"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"pict"},[_c('span',[_vm._v("使命")])]),_c('div',{staticClass:"text"},[_vm._v("振兴一方经济，造福一方人民。")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"pict"},[_c('span',[_vm._v("愿景")])]),_c('div',{staticClass:"text"},[_vm._v("成为西北地区高科技企业的领跑者。")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"pict"},[_c('span',[_vm._v("价值观")])]),_c('div',{staticClass:"text"},[_vm._v("诚信、高效、创新、协作。")])])])])
}]

export { render, staticRenderFns }