/* 诚聘英才 */
<template>
  <div class="join-us-main">
    <div class="join-us-left">
      <div class="left-title">福利待遇</div>
      <div class="item" v-for="(item, index) in welfareTreatment" :key="index">
        <div class="top">
          <div class="left">{{ item.title }}</div>
          <div class="right" @click="editWelfare(item)">
            <span class="el-icon-edit-outline"></span>
          </div>
        </div>
        <div class="bottom">
          {{ item.welfareDesc }}
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="mini"
          style="width: 120px"
          @click="addWelfare"
          >新增</el-button
        >
      </div>
    </div>
    <div class="join-us-right">
      <div class="main_head">
        <el-row :gutter="20" style="height: 100%" type="flex" align="middle">
          <el-col :span="4">
            <el-select
              size="small"
              v-model="form.joinUsDept"
              placeholder="请选择部门"
            >
              <el-option
                v-for="item in deptList"
                :key="item.state"
                :label="item.name"
                :value="item.state"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select
              size="small"
              v-model="form.workAddress"
              placeholder="请选择区域"
            >
              <el-option
                v-for="item in workAddressList"
                :key="item.state"
                :label="item.name"
                :value="item.state"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input
              size="small"
              v-model="form.jobName"
              placeholder="请输入职位名称"
            ></el-input>
          </el-col>
          <el-button
            size="small"
            icon="el-icon-search"
            type="primary"
            @click="findMenu"
            >查询</el-button
          >
          <el-button
            size="small"
            type="info"
            icon="el-icon-refresh"
            @click="refreshMenu"
            >重置</el-button
          >
          <el-button
            type="primary"
            @click="handleAdd('新增')"
            icon="el-icon-plus"
            size="small"
            >新增</el-button
          >
        </el-row>
      </div>
      <div class="table-main">
        <el-table :data="dataList" stripe style="width: 100%">
          <el-table-column label="序号" type="index" width="60" align="center">
          </el-table-column>
          <el-table-column label="招聘职位" prop="jobName" align="center">
          </el-table-column>
          <el-table-column label="部门" prop="joinUsDeptDict" align="center">
          </el-table-column>
          <el-table-column
            label="工作地点"
            prop="workAddressDict"
            align="center"
          >
          </el-table-column>
          <el-table-column label="发布时间" prop="createTime" align="center">
          </el-table-column>
          <el-table-column label="操作" width="250px" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="编辑">
                <el-button
                  type="info"
                  icon="el-icon-edit"
                  size="mini"
                  @click="handleEdit(scope.row, '编辑')"
                ></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="handleDelete(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="menu_page">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size.sync="size"
          @current-change="handleCurrentChange"
          :current-page.sync="current"
        >
        </el-pagination>
      </div>
    </div>
    <add ref="add" @loadData="loadData" />
    <welfare ref="welfare" @getWelfareData="getWelfareData" />
  </div>
</template>

<script>
import add from "./model/addDialog.vue";
import welfare from "./model/addLeftDialog.vue";
export default {
  components: { add, welfare },
  data() {
    return {
      welfareTreatment: [],
      deptList: [],
      workAddressList: [],
      form: {
        joinUsDept: "",
        workAddress: "",
        jobName: "",
      },
      url: {
        list: "/joinUsRecruit/pageList",
        edit: "/joinUsRecruit/update",
        delete: "/joinUsRecruit/deleteById",
        welfare: "/joinUsWelfare/list",
      },
      dataList: [],
      total: 0,
      size: 10,
      current: 1,
    };
  },
  async mounted() {
    this.loadData();
    this.getWelfareData();
    this.deptList = await this.$config.getDict("join_us_dept");
    this.workAddressList = await this.$config.getDict("work_address");
  },
  methods: {
    editWelfare(item) {
      this.$refs.welfare.init("编辑", item);
    },
    addWelfare() {
      this.$refs.welfare.init("新增", null);
    },
    getWelfareData() {
      this.$http.getRequest(this.url.welfare).then((res) => {
        if (res.code === 200) {
          this.welfareTreatment = res.data;
        }
      });
    },
    handleDelete(id) {
      this.$confirm("确定删除该菜单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .deleteRequest(`${this.url.delete}?id=${id}`)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.loadData();
              } else {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            });
        })
        .catch(() => {
          // 取消时的操作
        });
    },
    handleAdd() {
      this.$refs.add.init("新增", null);
    },
    handleEdit(item) {
      this.$refs.add.init("编辑", item);
    },
    // 重置
    refreshMenu() {
      this.form.joinUsDept = "";
      this.form.workAddress = "";
      this.form.jobName = "";
      this.loadData();
    },
    // 查询
    findMenu() {
      this.loadData();
    },
    // 分页
    handleCurrentChange(val) {
      this.current = val;
      this.loadData();
    },
    loadData() {
      const params = {
        current: this.current,
        size: this.size,
        joinUsDept: this.form.joinUsDept,
        workAddress: this.form.workAddress,
        jobName: this.form.jobName,
      };
      this.$http
        .getRequest(this.url.list, params)
        .then((res) => {
          if (res.code == 200) {
            this.dataList = res.data.records;
            this.total = res.data.total;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.join-us-main {
  padding: 20px;
  width: 100%;
  display: flex;
  .join-us-left {
    width: 20%;
    min-height: 200px;
    border-radius: 10px;
    background-color: #f3f3f3;
    max-height: 80vh;
    overflow-y: scroll;
    .bottom-btn {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .item {
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      width: calc(100% - 60px);
      margin-left: 20px;
      border: 1px solid #fff;
      .bottom {
        font-size: 14px;
        margin-top: 10px;
      }
      .top {
        display: flex;
        .left {
          font-size: 14px;
        }
        .right {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
    .left-title {
      margin: 20px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
  }
  .join-us-right {
    width: 76%;
    margin-left: 1.5%;
    .table-main {
      background: #f3f3f3;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px 20px;
    }
    .main_head {
      background: #f3f3f3;
      height: 80px;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 0 20px;
      .el-col {
        display: flex;
        align-items: center;
        .text {
          width: 40%;
          text-align: center;
        }
      }
    }
  }
}
</style>
