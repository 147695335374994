/* 
    存储接口请求的域名
*/

import http from "./index";

let url;

if (process.env.NODE_ENV == "development") {
  // 开发环境中
  // 测试环境
  url = "http://www.zwhldk.com/java";
  // url = "http://192.168.119.23:11331";
} else {
  // 生产环境中
  // url = "http://192.168.119.249:11331"; //
  url = "http://www.zwhldk.com/java";
  // url = "https://syzt.zwhldk.com:11189/java_phase2";//240
}

// 登陆
export const login = (params) =>
  http.postRequest(url + "/login/web/signIn", params);
//登录滑块验证
export const ImgAuth = (params) =>
  http.postRequest(url + "/login/web/imgAuth", params);
//回去图片滑块
export const slideImgCoden = (params) =>
  http.getRequest(url + "/login/web/slideImgCode", params);
//文件上传
export const fileUpload = (params) =>
  http.postFileUpload(url + "/file/uploadMinio", params);
// 文档导入
export const excelUpload = (params) =>
  http.postFileUpload(url + "/user/excel/import", params);
// export const getDict = (params) =>
//   http.getRequest(url + "/dict/select?code=" + params).then((res) => {
//     console.log("字典内容", res);
//     return res.data;
//   });

// 获取字典
async function getDict(code) {
  return new Promise((resolve) => {
    http.getRequest(url + "/dict/select?code=" + code).then((res) => {
      resolve(res.data);
    });
  });
}

// 获取字典
async function getIndexWebsiteDict(code) {
  return new Promise((resolve) => {
    http
      .getRequest(url + "/indexWebsite/dict/select?code=" + code)
      .then((res) => {
        resolve(res.data);
      });
  });
}

// 获取字典值
async function getDictValue(code, value) {
  return new Promise((resolve) => {
    http.getRequest(url + "/dict/select?code=" + code).then((res) => {
      let arr = res.data.data;
      let obj = arr.find((e) => e.state === value);
      resolve(obj.name);
    });
  });
}

// 获取全部员工
async function getUserList(name) {
  return new Promise((resolve) => {
    http.getRequest(url + "/user/list?name=" + name || "").then((res) => {
      resolve(res.data.data);
    });
  });
}

export default {
  url,
  login,
  slideImgCoden,
  ImgAuth,
  fileUpload,
  getDict,
  excelUpload,
  getUserList,
  getDictValue,
  getIndexWebsiteDict,
};
