// 诚聘英才右边表格弹窗、
<template>
  <div class="bfDialog">
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="40%"
      :close-on-click-modal="false"
    >
      <section class="main-form">
        <el-form
          ref="bfForm"
          :rules="rule"
          :model="dialogObj"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="招聘职位" prop="jobName">
                <el-input
                  style="width: 100%"
                  placeholder="请输入招聘职位"
                  v-model="dialogObj.jobName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="岗位职责" prop="jobResponsibility">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  placeholder="请输入岗位职责"
                  v-model="dialogObj.jobResponsibility"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="任职资格" prop="jobQualifications">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  placeholder="请输入任职资格"
                  v-model="dialogObj.jobQualifications"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="工作地点" prop="workAddress">
                <el-select
                  v-model="dialogObj.workAddress"
                  placeholder="请选择工作地点"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in workAddressList"
                    :key="item.state"
                    :label="item.name"
                    :value="item.state"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="接收邮箱" prop="email">
                <el-input
                  type="text"
                  placeholder="请输入接收邮箱"
                  v-model="dialogObj.email"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="部门" prop="joinUsDept">
                <el-select
                  v-model="dialogObj.joinUsDept"
                  placeholder="请选择部门"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in deptList"
                    :key="item.state"
                    :label="item.name"
                    :value="item.state"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit('bfForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deptList: [],
      workAddressList: [],
      dialogObj: {},
      title: "",
      show: false,
      url: {
        add: "/joinUsRecruit/insert",
        edit: "/joinUsRecruit/update",
      },
      rule: {
        jobName: {
          required: true,
          message: "请输入招聘职位",
          trigger: "blur",
        },
        joinUsDept: {
          required: true,
          message: "请选择招聘部门",
          trigger: "blur",
        },
        workAddress: {
          required: true,
          message: "请选择工作地点",
          trigger: "blur",
        },
        jobResponsibility: {
          required: true,
          message: "请输入岗位职责",
          trigger: "blur",
        },
        jobQualifications: {
          required: true,
          message: "请输入任职资格",
          trigger: "blur",
        },
        email: {
          trigger: "blur",
          required: true,
          pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
          message: "请输入正确的邮箱",
        },
      },
    };
  },
  methods: {
    /* 提交表单 */
    submit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogObj.id) {
            url = this.url.edit;
          } else {
            url = this.url.add;
          }
          this.$http.postRequest(url, this.dialogObj).then((res) => {
            console.log("res", res);
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$emit("loadData");
              this.show = false;
            } 
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async init(title, item) {
      this.deptList = await this.$config.getDict("join_us_dept");
      this.workAddressList = await this.$config.getDict("work_address");
      if (item) {
        this.dialogObj = item;
      } else {
        this.dialogObj = {};
      }
      this.title = title;
      this.show = true;
    },
  },
};
</script>
<style lang="less" scoped>
.bfDialog {
  .title {
    font-weight: 600;
    color: black;
  }
  .spBox {
    margin-top: 20px;
  }
  .timeLine {
    .floor {
      display: flex;
      justify-content: space-around;
    }
    .top {
      color: black;
      margin-bottom: 10px;
    }
    .bottom {
      font-size: 14px;
      .ashText {
        color: #b3b3b3;
      }
    }
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409eff;
    border-radius: 10rpx;
  }
  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 160px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 10rpx;
  }
  ::v-deep .avatar {
    width: 160px;
    height: 120px;
    display: block;
    border-radius: 10rpx;
  }
}
</style>
