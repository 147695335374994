import { render, staticRenderFns } from "./addDialog.vue?vue&type=template&id=645a6448&scoped=true&"
import script from "./addDialog.vue?vue&type=script&lang=js&"
export * from "./addDialog.vue?vue&type=script&lang=js&"
import style0 from "./addDialog.vue?vue&type=style&index=0&id=645a6448&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645a6448",
  null
  
)

export default component.exports