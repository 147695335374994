var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_main"},[_c('div',{staticClass:"about_banner"},[_c('img',{attrs:{"src":_vm.$imgUrl + _vm.bannerUrl,"alt":""}})]),_c('div',{staticClass:"about_menu"},_vm._l((_vm.topMenu),function(item,index){return _c('a',{key:index,staticClass:"item",style:({ color: _vm.topMenuI === index ? '#DD1619' : '' }),on:{"click":function($event){return _vm.handleAboutMenu(item, index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"about_introduce",attrs:{"id":"about_introduce"}},[_c('div',{staticClass:"int_left"},[(_vm.companyIntroduce.resourceFile.resourceType === 2)?_c('img',{attrs:{"src":_vm.$imgUrl + _vm.companyIntroduce.resourceFile.url,"alt":""}}):_vm._e(),(_vm.companyIntroduce.resourceFile.resourceType === 1)?_c('video',{attrs:{"src":_vm.$imgUrl + _vm.companyIntroduce.resourceFile.url,"autoplay":"","loop":"","controls":""}}):_vm._e()]),_c('div',{staticClass:"int_right"},[_c('div',{staticClass:"right_title"},[_vm._v(_vm._s(_vm.companyIntroduce.title))]),_c('div',{staticClass:"right_content"},[_vm._v(_vm._s(_vm.companyIntroduce.enterpriseInfo))])])]),_c('div',{staticClass:"about_culture",attrs:{"id":"about_culture"}},[_c('div',{staticClass:"cu_title"},[_vm._v("企业文化")]),_c('div',{staticClass:"cu_desc"},[_vm._v(" 恒力电控将继续秉承“诚信、高效、创新、协作”的理念，不断推进技术创新和服务升级，为客户提供更有针对性的软件服务和出色的解决方案。 ")]),_c('div',{staticClass:"culture_content"},_vm._l((_vm.companyCulture),function(item,index){return _c('div',{key:index + 'c',staticClass:"item",style:({
          width: 1455 / _vm.companyCulture.length + 'px',
          backgroundImage: 'url(' + _vm.$imgUrl + item.resourceFile + ')',
        })},[_c('div',{staticClass:"item_title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"item_content"},[_vm._v(_vm._s(item.enterpriseCulture))])])}),0)]),_c('div',{staticClass:"about_course",attrs:{"id":"about_course"}},[_c('div',{staticClass:"course_title"},[_vm._v("发展历程")]),_c('div',{staticClass:"course_desc"},[_vm._v(" 恒力科技历经9年的磨砺发展，旗下宁夏恒力祥自控工程科技服务有限公司、中卫市恒力电控科技服务有限公司、中卫市鸿科职业技术学校。 ")]),_c('div',{staticClass:"course_history"},[_c('div',{staticClass:"history_left_btn"},[_c('img',{attrs:{"src":require("@/assets/home/history_left.png"),"alt":""},on:{"click":function($event){return _vm.selectHistory('left')}}})]),_c('div',{staticClass:"history_content"},[_c('div',{staticClass:"hr"}),_c('div',{staticClass:"content_item"},[_vm._l((_vm.developmentHistory.slice(
              _vm.history.start,
              _vm.history.end
            )),function(item,index){return [(index % 2 === 0)?_c('div',{key:index,staticClass:"item_top",on:{"mouseover":function($event){return _vm.onMouseOver(index)},"mouseout":function($event){return _vm.onMouseOut(index)}}},[_c('div',{staticClass:"item_bottom_heng"}),_c('div',{staticClass:"item_top_con"},[_vm._v(" "+_vm._s(item.developContent)+" ")]),_c('div',{staticClass:"item_bottom_con",style:({
                  backgroundColor: _vm.hoverHistory === index ? '#DD1619' : '',
                  color: _vm.hoverHistory === index ? '#fff' : '',
                })},[_vm._v(" "+_vm._s(item.developYear)+" ")]),_c('div',{staticClass:"item_bottom_qiu",style:({
                  backgroundColor: _vm.hoverHistory === index ? '#DD1619' : '',
                })})]):_c('div',{key:index,staticClass:"item_bottom",on:{"mouseover":function($event){return _vm.onMouseOver(index)},"mouseout":function($event){return _vm.onMouseOut(index)}}},[_c('div',{staticClass:"item_bottom_heng"}),_c('div',{staticClass:"item_bottom_qiu",style:({
                  backgroundColor: _vm.hoverHistory === index ? '#DD1619' : '',
                })}),_c('div',{staticClass:"item_bottom_con",style:({
                  backgroundColor: _vm.hoverHistory === index ? '#DD1619' : '',
                  color: _vm.hoverHistory === index ? '#fff' : '',
                })},[_vm._v(" "+_vm._s(item.developYear)+" ")]),_c('div',{staticClass:"item_top_con"},[_vm._v(" "+_vm._s(item.developContent)+" ")])])]})],2)]),_c('div',{staticClass:"history_right_btn"},[_c('img',{attrs:{"src":require("@/assets/home/history_right.png"),"alt":""},on:{"click":function($event){return _vm.selectHistory('right')}}})])])]),_c('div',{staticClass:"about_strength",attrs:{"id":"about_strength"}},[_c('div',{staticClass:"str_title"},[_vm._v("技术实力")]),_c('div',{staticClass:"str_desc"},[_vm._v(" 取得了10项实用新型专利，46项计算机软件著作权证书，同时通过了质量、环境、职业健康安全管理三大体系认证。 ")]),_c('div',{staticClass:"str_img"},[_c('el-carousel',{attrs:{"interval":400000,"type":"card","height":"433px"}},_vm._l((_vm.companyStrength),function(item){return _c('el-carousel-item',{key:item},[_c('img',{staticClass:"str_img_item",attrs:{"src":_vm.$imgUrl + item,"alt":""}})])}),1)],1)]),_c('div',{staticClass:"about_honor",attrs:{"id":"about_honor"}},[_c('div',{staticClass:"honor_title"},[_vm._v("企业荣誉")]),_c('div',{staticClass:"honor_desc"},[_vm._v("以技术为先，打造领先行业的技术优势。")]),_c('div',{staticClass:"honor_img"},_vm._l((_vm.companyHonor.slice(0, 6)),function(item){return _c('div',{key:item.id,staticClass:"img_item"},[_c('div',{staticClass:"img_item_img"},[_c('img',{attrs:{"src":_vm.$imgUrl + item.img.url,"alt":""}})]),_c('div',{staticClass:"img_desc"},[_vm._v(_vm._s(item.img.desc))])])}),0)]),_vm._m(0),_c('div',{staticClass:"about_img_list"},[_vm._l((_vm.photoWallList),function(item,index){return _c('div',{key:index + 'b',class:`item${index + 1}`,staticStyle:{"overflow":"hidden"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","transition":"transform 0.5s","cursor":"pointer"},attrs:{"src":_vm.$imgUrl + item,"alt":""}})])}),_vm._l((4),function(item,index){return _c('div',{key:index + 'a',class:`item_${index + 1}`})})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_contact_us",attrs:{"id":"about_contact_us"}},[_c('div',{staticClass:"concat_title"},[_vm._v("联系我们")]),_c('div',{staticClass:"concat_content"},[_vm._v(" 您使用放心，我们努力用心，以周到的服务,赢得广大客户的信任与厚爱。 ")]),_c('div',{staticClass:"bot_concat_us"},[_c('div',{staticClass:"us_top_title"},[_c('div',{staticClass:"top_item"},[_vm._v("服务热线")]),_c('div',{staticClass:"top_item"},[_vm._v("邮箱")]),_c('div',{staticClass:"top_item"},[_vm._v("公司地址")])]),_c('div',{staticClass:"us_top_title"},[_c('div',{staticClass:"top_item"},[_vm._v("400-452-1524")]),_c('div',{staticClass:"top_item"},[_vm._v("521586489@163.com")]),_c('div',{staticClass:"top_item",staticStyle:{"width":"600px"}},[_vm._v(" 宁夏回族自治区银川市西夏区中关村创新中心20楼 ")])])])])
}]

export { render, staticRenderFns }