/* 企业介绍 */
<template>
  <div class="introduce-main" v-loading="imgLoading">
    <div class="introduce-file">
      <div class="file-top">
        <div class="top-title">企业介绍资源文件</div>
        <div class="top-btn">
          <el-button type="text" @click="handleImg()" class="button"
            >更换</el-button
          >
        </div>
      </div>
      <div class="file-content">
        <img
          v-if="fileUrl.url && fileUrl.resourceType === 2"
          :src="$imgUrl + fileUrl.url"
          alt=""
        />
        <video
          controls
          v-if="fileUrl.url && fileUrl.resourceType === 1"
          :src="$imgUrl + fileUrl.url"
        ></video>
      </div>
    </div>
    <div class="introduce-content">
      <div class="file-top">
        <div class="top-title">企业介绍标题：</div>
        <div class="top-btn">
          <el-input
            size="small"
            style="width: 100%"
            type="text"
            v-model="submit.title"
            placeholder="请输入企业标题"
          />
        </div>
      </div>
      <div class="content">
        <div class="content-title">企业介绍：</div>
        <div class="content-content">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入企业介绍"
            v-model="submit.enterpriseInfo"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div class="right-btn">
      <el-button
        type="primary"
        size="small"
        style="width: 120px"
        @click="submitObj"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgLoading: false,
      submit: {},
      fileUrl: {
        url: "",
        resourceType: null,
      },
      url: {
        list: "/aboutUsEnterpriseInfo/pageList",
        add: "/aboutUsEnterpriseInfo/insert",
        edit: "/aboutUsEnterpriseInfo/update",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.getRequest(this.url.list).then((res) => {
        if (res.code === 200) {
          if (res.data.records.length != 0) {
            let obj = res.data.records[0];
            this.submit = obj;
            this.fileUrl.url = JSON.parse(obj.resourceFile).url;
            this.fileUrl.resourceType = JSON.parse(
              obj.resourceFile
            ).resourceType;
          }
        }
      });
    },
    submitObj() {
      this.submit.resourceFile = JSON.stringify(this.fileUrl);
      var url = "";
      if (this.submit.id) {
        url = this.url.edit;
      } else {
        url = this.url.add;
      }
      this.$http.postRequest(url, this.submit).then((res) => {
        console.log("res", res);
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
      });
    },
    handleImg() {
      this.imgLoading = true;
      const input = document.createElement("input");
      input.type = "file";
      input.style.position = "absolute";
      input.style.top = "0";
      input.style.right = "0";
      input.style.opacity = "0";
      input.style.overflow = "hidden";
      document.body.appendChild(input);
      input.click();
      input.addEventListener("change", (event) => {
        const file = input.files[0];
        if (file.type.includes("video")) {
          this.fileUrl.resourceType = 1;
        } else {
          this.fileUrl.resourceType = 2;
        }
        var formData = new FormData();
        formData.append("file", file);
        this.$config.fileUpload(formData).then((res) => {
          if (res.code === 200) {
            this.fileUrl.url = res.data;
            this.imgLoading = false
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introduce-main {
  .right-btn {
    margin-left: 30px;
    margin-top: auto;
  }
  display: flex;
  .introduce-file {
    width: 40%;
    height: 350px;
    border: 1px dashed #eee;
    .file-top {
      display: flex;
      padding: 0 20px;
      border-bottom: 1px solid #eee;
      .top-title {
        font-size: 14px;
        line-height: 40px;
      }
      .top-btn {
        margin-left: auto;
      }
    }
    .file-content {
      width: 100%;
      height: 309px;
      img {
        height: 100%;
        width: 100%;
      }
      video {
        height: 100%;
        width: 100%;
      }
    }
  }
  .introduce-content {
    margin-left: 0.5%;
    width: 40%;
    height: 350px;
    border: 1px dashed #eee;
    .content {
      .content-content {
        padding: 0 20px;
      }
      .content-title {
        padding: 10px 20px;
        font-size: 14px;
      }
    }
    .file-top {
      display: flex;
      padding: 0 20px;
      border-bottom: 1px solid #eee;
      width: calc(100% - 40px);
      .top-title {
        width: 20%;
        font-size: 14px;
        line-height: 40px;
      }
      .top-btn {
        width: calc(80% + 40px);
        padding-top: 4px;
      }
    }
  }
}
</style>
