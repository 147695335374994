//更换轮播图图片
<template>
  <div class="addDialog">
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="58%"
      :close-on-click-modal="false"
    >
      <section class="main-form">
        <el-form ref="fbForm" :model="dialogObj" label-width="130px">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="" prop="nowDevicePosition">
                <el-upload
                  class="upload-demo"
                  style="margin-left: 14px"
                  action="#"
                  list-type="picture"
                  :http-request="imgUpload"
                  :on-remove="handleRemove"
                  :file-list="imgFileList"
                >
                  <i class="el-icon-plus"></i>
                  <video
                    v-if="this.fileType"
                    :src="$imgUrl + videoUrl"
                    class="avata"
                    controls="controls"
                  >
                    您的浏览器不支持视频播放
                  </video>
                  <el-progress
                    v-if="videoFlag == true"
                    type="circle"
                    v-bind:percentage="videoUploadPercent"
                    style="margin-top: 7px"
                  ></el-progress>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit('fbForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: "", //回显的变量
      },

      show: false,
      dialogObj: {},
      title: "",
      /* 图片列表 */
      imgList: [],
      imgFileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      fileType: "",
      videoUrl: "",
    };
  },

  methods: {
    async init(item, title, url, id, bannelType) {
      console.log("this.fileType", this.fileType);
      this.mainForm = item;
      console.log("this.mainForm", this.mainForm);
      this.title = title;
      this.url = url;
      this.id = id;
      this.bannelType = bannelType;
      this.show = true;
      if (item) {
        this.dialogObj = item;
      } else {
        this.dialogObj = {};
      }
    },
    //上传前回调
    beforeUploadVideo(file) {
      console.log("filessss::::", file);
      var fileSize = file.size / 1024 / 1024 < 50; //控制大小  修改50的值即可
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1 //控制格式
      ) {
        // layer.msg("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        // layer.msg("视频大小不能超过50MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      //注意在data中添加对应的变量名
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
      this.isShowUploadVideo = true;
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      //后台上传数据
      if (res.success == true) {
        this.dialogObj.fileUrl = res.data.url; //上传成功后端返回视频地址 回显
      } else {
        this.$message.error("上传失败！");
      }
    },

    handleRemove(file, fileList) {
      this.imgFileList = fileList;
    },

    /* 图片上传 */
    imgUpload(file) {
      console.log("file", file);
      var formData = new FormData();
      formData.append("file", file.file);
      // 上传的是视频
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          if (file.file.type == "video/mp4") {
            this.videoUrl = res.data;
            console.log("this.videoUrl",this.videoUrl)
            this.fileType = file.file.type;
            this.dialogObj.resourceType = 1;//视频1图片2
            this.dialogObj.fileUrl = this.videoUrl;
          } else {
            this.imgList.push({
              url: res.data,
              name: file.file.name,
            });
            this.dialogObj.fileUrl = JSON.stringify(this.imgList);
            this.dialogObj.resourceType = 2;//视频1图片2
          }
        }
      });
    },

    /* 提交*/
    submit(name) {
      this.dialogObj.id = this.id;
      this.dialogObj.bannelType = this.bannelType;
      console.log("this.dialogOBJ", this.dialogObj);
      this.$http
        .postRequest(
          "/banner/update?bannelType=" +
            this.bannelType +
            "&id=" +
            this.id,
          this.dialogObj
        )
        .then((res) => {
          console.log("res===", res);
          if (res.code == 200) {
            this.$message.success("更换成功");
            this.show = false;
            this.$emit("getImgList");
          } else {
            this.$message.info(res.data.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.avata{
  width: 350px;
  height: 350px;
}
.fbDialog {
  .title {
    font-weight: 600;
    color: black;
  }
  .spBox {
    margin-top: 20px;
  }
  .timeLine {
    .floor {
      display: flex;
      justify-content: space-around;
    }
    .top {
      color: black;
      margin-bottom: 10px;
    }
    .bottom {
      font-size: 14px;
      .ashText {
        color: #b3b3b3;
      }
    }
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409eff;
    border-radius: 10rpx;
  }
  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 160px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 10rpx;
  }
  ::v-deep .avatar {
    width: 160px;
    height: 120px;
    display: block;
    border-radius: 10rpx;
  }
}
</style>