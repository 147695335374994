<template>
  <div class="trends_main">
    <div class="trends_banner">
      <img :src="$imgUrl + bannerUrl" alt=""/>
    </div>
    <div class="trends_top">
      <div class="top_left">
        <div class="top_left_img">
          <img :src="trendsOne.picture.url" alt=""/>
        </div>
        <div class="top_title">
          <div class="title_icon">最新资讯</div>
          <div class="title_content" @click="findDetail(trendsOne)">
            {{ trendsOne.title }}
          </div>
        </div>
        <div class="top_content" v-html="trendsOne.content" v-resize-images></div>
        <div class="top_date">{{ trendsOne.publishTime }}</div>
      </div>
      <div class="top_right">
        <div
            class="right_item"
            v-for="(item, index) in trendsTopList"
            :key="index"
            @click="findDetail(item)"
        >
          <div class="item_img">
            <img :src="item.picture.url" alt=""/>
          </div>
          <div class="item_body">
            <div class="body_title">{{ item.title }}</div>
            <div class="body_date">{{ item.publishTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="trends_body">
      <div
          class="body_item"
          v-for="(item, index) in trendsList"
          :key="index"
          @click="findDetail(item)"
      >
        <div class="item_img">
          <img :src="item.picture.url" alt=""/>
        </div>
        <div class="item_body">
          <div class="body_title">{{ item.title }}</div>
          <div class="body_content" v-html="item.content"></div>
          <div class="body_date">{{ item.publishTime }}</div>
        </div>
      </div>
    </div>
    <div class="trends_page">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerUrl: "",
      trendsList: [],
      trendsOne: {
        picture: {
          url: "",
        },
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      trendsTopList: [],
      url: {
        data: "/indexWebsite/newsPageList",
        banner: "/indexWebsite/getIndexBannerByType",
      },
    };
  },
  // 富文本的img设置样式无法生效时，使用该方法，要在标签中添加v-resize-images
  directives: {
    resizeImages: {
      inserted(el) {
        console.log("el", el)
        const images = el.getElementsByTagName("img");
        console.log("images====", images.src)
        console.log("images.length====", images.length)
        for (let i = 0; i < images.length; i++) {
          images[i].style.display = "none"; // 隐藏图片
        }
      },
    },
  },
  mounted() {
    this.getTrendsDataPage();
    this.getBanner();
  },
  methods: {
    findDetail(item) {
      this.$router.push({
        path: "/details",
        query: {id: item.id},
      });
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getTrendsDataPage();
    },
    getBanner() {
      this.$http.getRequest(this.url.banner, {bannerType: 4}).then((res) => {
        if (res.code === 200) {
          this.bannerUrl = res.data.fileUrl;
        }
      });
    },
    getTrendsDataPage() {
      this.$http.getRequest(this.url.data, this.page).then((res) => {
        if (res.code === 200) {
          this.trendsList = res.data.records;
          this.trendsList.forEach((res) => {
            res.picture = JSON.parse(res.picture)[0];
          });
          this.page.total = res.data.total;

          this.trendsOne = res.data.records[0];
          this.trendsTopList = this.trendsList.slice(1, 5);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.trends_main {
  width: 100vw;
  margin-bottom: 20px;

  .trends_page {
    text-align: center;
  }

  .trends_body {
    .body_item {
      margin: 25px 259px;
      height: 229px;
      width: 1401.4px;
      background-color: #f7f8fc;
      border-radius: 8px;
      display: flex;
      cursor: pointer;

      .item_body {
        margin: 34px 0 0 0;
        position: relative;
        width: 1401.4px;

        .body_title {
          width: 1000px;
          font-size: 26px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #333333;
          overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
          text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
          white-space: nowrap; /*文本不换行*/
        }

        .body_content {
          width: 1000px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #757575;
          overflow: hidden;
          line-height: 22px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          ::v-deep img {
            display: none;
          }
        }

        .body_date {
          position: absolute;
          bottom: 20px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #757575;
          line-height: 28px;
        }
      }

      .item_img {
        margin: 29px 26px 31px 22px;
        width: 302px;
        height: 169px;
        overflow: hidden;

        img {
          width: 302px;
          height: 169px;
          border-radius: 6px;
          transition: transform 0.5s;
          cursor: pointer;
        }

        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  .trends_top {
    display: flex;
    margin: 51px 0 0 260px;

    .top_left {
      width: 747px;
      height: calc(209px + 419px);
      background: #f7f8fc;
      border-radius: 10px;
      position: relative;

      .top_date {
        position: absolute;
        left: 18px;
        bottom: 11px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #757575;
        line-height: 28px;
      }

      .top_content {
        width: 95%;
        padding-right: 20px;
        margin: 28px 18px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #757575;
        line-height: 28px;
        display: -webkit-box; /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
        -webkit-line-clamp: 2; /*设置多少行*/
        -webkit-box-orient: vertical; /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
        overflow: hidden; /*文本会被修剪，并且剩余的部分不可见*/
        text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
      }

      .top_title {
        width: 100%;
        display: flex;
        margin: 24px 0 0 20px;

        .title_icon {
          width: 101px;
          height: 31px;
          line-height: 31px;
          background: rgba(221, 22, 25, 0.1);
          border-radius: 4px;
          text-align: center;
          font-size: 18px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #dd1619;
        }

        .title_content {
          width: 100%;
          padding-right: 20px;
          margin-left: 15px;
          font-size: 26px;
          line-height: 29px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #333333;
          overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
          text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
          white-space: nowrap; /*文本不换行*/
          cursor: pointer;
        }
      }

      .top_left_img {
        width: 747px;
        height: 419px;
        overflow: hidden;

        img {
          width: 747px;
          height: 419px;
          border-radius: 10px 10px 0 0;
          transition: transform 0.5s;
          cursor: pointer;
        }

        img:hover {
          transform: scale(1.2);
        }
      }
    }

    .top_right {
      margin-left: 24px;
      width: calc(628px - 28px);
      height: calc(628px - 37px);
      background: #f7f8fc;
      border-radius: 10px;
      padding: 37px 0 0 28px;

      .right_item {
        display: flex;
        height: 97px;
        margin-bottom: 52px;
        cursor: pointer;

        .item_body {
          width: 420px;
          margin-left: 17px;
          position: relative;

          .body_title {
            width: calc(628px - 228px);
            font-size: 22px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            line-height: 32px;
            overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
            text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
            white-space: nowrap; /*文本不换行*/
          }

          .body_date {
            position: absolute;
            width: calc(628px - 28px);
            bottom: 0px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #757575;
            line-height: 28px;
          }
        }

        .item_img {
          width: 163px;
          height: 97px;
          overflow: hidden;

          img {
            width: 163px;
            height: 97px;
            border-radius: 8px;
            transition: transform 0.5s;
            cursor: pointer;
          }

          img:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .trends_banner {
    img {
      width: 100%;
      height: 408px;
    }
  }
}
</style>
