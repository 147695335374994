<template>
  <div class="us-image-main">
    <el-upload
      class="avatar-uploader"
      action="#"
      :http-request="imgUpload1"
      :show-file-list="false"
    >
      <img v-if="img1" :src="$imgUrl + img1" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-2"
      action="#"
      :http-request="imgUpload2"
      :show-file-list="false"
    >
      <img v-if="img2" :src="$imgUrl + img2" class="avatar-2" />
      <i v-else class="el-icon-plus avatar-uploader-icon-2"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-3"
      action="#"
      :http-request="imgUpload3"
      :show-file-list="false"
    >
      <img v-if="img3" :src="$imgUrl + img3" class="avatar-3" />
      <i v-else class="el-icon-plus avatar-uploader-icon-3"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-4"
      action="#"
      :http-request="imgUpload4"
      :show-file-list="false"
    >
      <img v-if="img4" :src="$imgUrl + img4" class="avatar-4" />
      <i v-else class="el-icon-plus avatar-uploader-icon-4"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-5"
      action="#"
      :http-request="imgUpload5"
      :show-file-list="false"
    >
      <img v-if="img5" :src="$imgUrl + img5" class="avatar-5" />
      <i v-else class="el-icon-plus avatar-uploader-icon-5"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-6"
      action="#"
      :http-request="imgUpload6"
      :show-file-list="false"
    >
      <img v-if="img6" :src="$imgUrl + img6" class="avatar-6" />
      <i v-else class="el-icon-plus avatar-uploader-icon-6"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-7"
      action="#"
      :http-request="imgUpload7"
      :show-file-list="false"
    >
      <img v-if="img7" :src="$imgUrl + img7" class="avatar-7" />
      <i v-else class="el-icon-plus avatar-uploader-icon-7"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-8"
      action="#"
      :http-request="imgUpload8"
      :show-file-list="false"
    >
      <img v-if="img8" :src="$imgUrl + img8" class="avatar-8" />
      <i v-else class="el-icon-plus avatar-uploader-icon-8"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-9"
      action="#"
      :http-request="imgUpload9"
      :show-file-list="false"
    >
      <img v-if="img9" :src="$imgUrl + img9" class="avatar-9" />
      <i v-else class="el-icon-plus avatar-uploader-icon-9"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-10"
      action="#"
      :http-request="imgUpload10"
      :show-file-list="false"
    >
      <img v-if="img10" :src="$imgUrl + img10" class="avatar-10" />
      <i v-else class="el-icon-plus avatar-uploader-icon-10"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-11"
      action="#"
      :http-request="imgUpload11"
      :show-file-list="false"
    >
      <img v-if="img11" :src="$imgUrl + img11" class="avatar-11" />
      <i v-else class="el-icon-plus avatar-uploader-icon-11"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-12"
      action="#"
      :http-request="imgUpload12"
      :show-file-list="false"
    >
      <img v-if="img12" :src="$imgUrl + img12" class="avatar-12" />
      <i v-else class="el-icon-plus avatar-uploader-icon-12"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-13"
      action="#"
      :http-request="imgUpload13"
      :show-file-list="false"
    >
      <img v-if="img13" :src="$imgUrl + img13" class="avatar-13" />
      <i v-else class="el-icon-plus avatar-uploader-icon-13"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-14"
      action="#"
      :http-request="imgUpload14"
      :show-file-list="false"
    >
      <img v-if="img14" :src="$imgUrl + img14" class="avatar-14" />
      <i v-else class="el-icon-plus avatar-uploader-icon-14"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-15"
      action="#"
      :http-request="imgUpload15"
      :show-file-list="false"
    >
      <img v-if="img15" :src="$imgUrl + img15" class="avatar-15" />
      <i v-else class="el-icon-plus avatar-uploader-icon-15"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-16"
      action="#"
      :http-request="imgUpload16"
      :show-file-list="false"
    >
      <img v-if="img16" :src="$imgUrl + img16" class="avatar-16" />
      <i v-else class="el-icon-plus avatar-uploader-icon-16"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-17"
      action="#"
      :http-request="imgUpload17"
      :show-file-list="false"
    >
      <img v-if="img17" :src="$imgUrl + img17" class="avatar-17" />
      <i v-else class="el-icon-plus avatar-uploader-icon-17"></i>
    </el-upload>
    <el-upload
      class="avatar-uploader-18"
      action="#"
      :http-request="imgUpload18"
      :show-file-list="false"
    >
      <img v-if="img18" :src="$imgUrl + img18" class="avatar-18" />
      <i v-else class="el-icon-plus avatar-uploader-icon-18"></i>
    </el-upload>
    <div class="bto-btn">
      <el-button type="primary" style="width: 200px" @click="submit"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: {
        list: "/aboutUsPhotoWall/pageList",
        edit: "/aboutUsPhotoWall/update",
        add: "/aboutUsPhotoWall/insert",
      },
      id: "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      img6: "",
      img7: "",
      img8: "",
      img9: "",
      img10: "",
      img11: "",
      img12: "",
      img13: "",
      img14: "",
      img15: "",
      img16: "",
      img17: "",
      img18: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    submit() {
      let obj = {
        1: this.img1,
        2: this.img2,
        3: this.img3,
        4: this.img4,
        5: this.img5,
        6: this.img6,
        7: this.img7,
        8: this.img8,
        9: this.img9,
        10: this.img10,
        11: this.img11,
        12: this.img12,
        13: this.img13,
        14: this.img14,
        15: this.img15,
        16: this.img16,
        17: this.img17,
        18: this.img18,
      };
      let url = "";
      let query = {};
      if (this.id) {
        url = this.url.edit;
        query.id = this.id;
        query.picture = JSON.stringify(obj);
      } else {
        url = this.url.add;
        query.picture = JSON.stringify(obj);
      }
      this.$http.postRequest(url, query).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
      });
    },
    getData() {
      this.$http.getRequest(this.url.list).then((res) => {
        if (res.code === 200) {
          if (res.data.records.length > 0) {
            this.id = res.data.records[0].id;
            let obj = JSON.parse(res.data.records[0].picture);
            this.img1 = obj[1];
            this.img2 = obj[2];
            this.img3 = obj[3];
            this.img4 = obj[4];
            this.img5 = obj[5];
            this.img6 = obj[6];
            this.img7 = obj[7];
            this.img8 = obj[8];
            this.img9 = obj[9];
            this.img10 = obj[10];
            this.img11 = obj[11];
            this.img12 = obj[12];
            this.img13 = obj[13];
            this.img14 = obj[14];
            this.img15 = obj[15];
            this.img16 = obj[16];
            this.img17 = obj[17];
            this.img18 = obj[18];
          }
        }
      });
    },
    imgUpload18(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img18 = res.data;
        }
      });
    },
    imgUpload17(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img17 = res.data;
        }
      });
    },
    imgUpload16(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img16 = res.data;
        }
      });
    },
    imgUpload15(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img15 = res.data;
        }
      });
    },
    imgUpload14(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img14 = res.data;
        }
      });
    },
    imgUpload13(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img13 = res.data;
        }
      });
    },
    imgUpload12(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img12 = res.data;
        }
      });
    },
    imgUpload11(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img11 = res.data;
        }
      });
    },
    imgUpload10(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img10 = res.data;
        }
      });
    },
    imgUpload9(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img9 = res.data;
        }
      });
    },
    imgUpload8(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img8 = res.data;
        }
      });
    },
    imgUpload7(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img7 = res.data;
        }
      });
    },
    imgUpload6(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img6 = res.data;
        }
      });
    },
    imgUpload5(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img5 = res.data;
        }
      });
    },
    imgUpload4(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img4 = res.data;
        }
      });
    },
    imgUpload3(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img3 = res.data;
        }
      });
    },
    imgUpload2(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img2 = res.data;
        }
      });
    },
    imgUpload1(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.img1 = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.us-image-main {
  position: relative;
  height: 583px;
  .bto-btn {
    position: absolute;
    right: 10px;
    top: 543px;
  }
  .avatar-uploader-18 {
    position: absolute;
    width: 341px;
    height: 126px;
    border: 1px dashed #e6e4e4;
    top: 400px;
    left: 1305px;
  }
  .avatar-uploader-icon-18 {
    font-size: 28px;
    color: #8c939d;
    width: 341px;
    height: 126px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-18 {
    width: 341px;
    height: 126px;
    display: block;
  }
  .avatar-uploader-17 {
    position: absolute;
    width: 227px;
    height: 126px;
    border: 1px dashed #e6e4e4;
    top: 400px;
    left: 961px;
  }
  .avatar-uploader-icon-17 {
    font-size: 28px;
    color: #8c939d;
    width: 227px;
    height: 126px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-17 {
    width: 227px;
    height: 126px;
    display: block;
  }
  .avatar-uploader-16 {
    position: absolute;
    width: 219px;
    height: 126px;
    border: 1px dashed #e6e4e4;
    top: 400px;
    left: 605px;
  }
  .avatar-uploader-icon-16 {
    font-size: 28px;
    color: #8c939d;
    width: 219px;
    height: 126px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-16 {
    width: 219px;
    height: 126px;
    display: block;
  }
  .avatar-uploader-15 {
    position: absolute;
    width: 167px;
    height: 126px;
    border: 1px dashed #e6e4e4;
    top: 400px;
    left: 430px;
  }
  .avatar-uploader-icon-15 {
    font-size: 28px;
    color: #8c939d;
    width: 167px;
    height: 126px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-15 {
    width: 167px;
    height: 126px;
    display: block;
  }
  .avatar-uploader-14 {
    position: absolute;
    width: 173px;
    height: 109px;
    border: 1px dashed #e6e4e4;
    top: 418px;
    left: 0;
  }
  .avatar-uploader-icon-14 {
    font-size: 28px;
    color: #8c939d;
    width: 173px;
    height: 109px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-14 {
    width: 173px;
    height: 109px;
    display: block;
  }
  .avatar-uploader-13 {
    position: absolute;
    width: 167px;
    height: 126px;
    border: 1px dashed #e6e4e4;
    top: 264px;
    left: 1478px;
  }
  .avatar-uploader-icon-13 {
    font-size: 28px;
    color: #8c939d;
    width: 167px;
    height: 126px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-13 {
    width: 167px;
    height: 126px;
    display: block;
  }
  .avatar-uploader-12 {
    position: absolute;
    width: 167px;
    height: 126px;
    border: 1px dashed #e6e4e4;
    top: 130px;
    left: 1478px;
  }
  .avatar-uploader-icon-12 {
    font-size: 28px;
    color: #8c939d;
    width: 167px;
    height: 126px;
    line-height: 126px;
    text-align: center;
  }
  .avatar-12 {
    width: 167px;
    height: 126px;
    display: block;
  }
  .avatar-uploader-11 {
    position: absolute;
    width: 321px;
    height: 191px;
    border: 1px dashed #e6e4e4;
    top: 199px;
    left: 1150px;
  }
  .avatar-uploader-icon-11 {
    font-size: 28px;
    color: #8c939d;
    width: 321px;
    height: 191px;
    line-height: 191px;
    text-align: center;
  }
  .avatar-11 {
    width: 321px;
    height: 191px;
    display: block;
  }
  .avatar-uploader-10 {
    position: absolute;
    width: 380px;
    height: 260px;
    border: 1px dashed #e6e4e4;
    top: 130px;
    left: 760px;
  }
  .avatar-uploader-icon-10 {
    font-size: 28px;
    color: #8c939d;
    width: 380px;
    height: 260px;
    line-height: 260px;
    text-align: center;
  }
  .avatar-10 {
    width: 380px;
    height: 260px;
    display: block;
  }
  .avatar-uploader-9 {
    position: absolute;
    width: 321px;
    height: 131px;
    border: 1px dashed #e6e4e4;
    top: 260px;
    left: 430px;
  }
  .avatar-uploader-icon-9 {
    font-size: 28px;
    color: #8c939d;
    width: 321px;
    height: 131px;
    line-height: 131px;
    text-align: center;
  }
  .avatar-9 {
    width: 321px;
    height: 131px;
    display: block;
  }
  .avatar-uploader-8 {
    position: absolute;
    width: 244px;
    height: 162px;
    border: 1px dashed #e6e4e4;
    top: 300px;
    left: 180px;
  }
  .avatar-uploader-icon-8 {
    font-size: 28px;
    color: #8c939d;
    width: 244px;
    height: 162px;
    line-height: 162px;
    text-align: center;
  }
  .avatar-8 {
    width: 244px;
    height: 162px;
    display: block;
  }
  .avatar-uploader-7 {
    position: absolute;
    width: 173px;
    height: 109px;
    border: 1px dashed #e6e4e4;
    top: 300px;
    left: 0;
  }
  .avatar-uploader-icon-7 {
    font-size: 28px;
    color: #8c939d;
    width: 173px;
    height: 109px;
    line-height: 109px;
    text-align: center;
  }
  .avatar-7 {
    width: 173px;
    height: 109px;
    display: block;
  }
  .avatar-uploader-6 {
    position: absolute;
    width: 167px;
    height: 122px;
    border: 1px dashed #e6e4e4;
    left: 1478px;
    top: 0;
  }
  .avatar-uploader-icon-6 {
    font-size: 28px;
    color: #8c939d;
    width: 167px;
    height: 122px;
    line-height: 122px;
    text-align: center;
  }
  .avatar-6 {
    width: 167px;
    height: 122px;
    display: block;
  }
  .avatar-uploader-5 {
    position: absolute;
    width: 321px;
    height: 191px;
    border: 1px dashed #e6e4e4;
    left: 1150px;
    top: 0;
  }
  .avatar-uploader-icon-5 {
    font-size: 28px;
    color: #8c939d;
    width: 321px;
    height: 191px;
    line-height: 191px;
    text-align: center;
  }
  .avatar-5 {
    width: 321px;
    height: 191px;
    display: block;
  }
  .avatar-uploader-4 {
    position: absolute;
    width: 187px;
    height: 122px;
    border: 1px dashed #e6e4e4;
    left: 955px;
    top: 0;
  }
  .avatar-uploader-icon-4 {
    font-size: 28px;
    color: #8c939d;
    width: 187px;
    height: 122px;
    line-height: 122px;
    text-align: center;
  }
  .avatar-4 {
    width: 187px;
    height: 122px;
    display: block;
  }
  .avatar-uploader-3 {
    position: absolute;
    width: 187px;
    height: 122px;
    border: 1px dashed #e6e4e4;
    left: 760px;
    top: 0;
  }
  .avatar-uploader-icon-3 {
    font-size: 28px;
    color: #8c939d;
    width: 187px;
    height: 122px;
    line-height: 122px;
    text-align: center;
  }
  .avatar-3 {
    width: 187px;
    height: 122px;
    display: block;
  }
  .avatar-uploader-2 {
    position: absolute;
    width: 321px;
    height: 191px;
    border: 1px dashed #e6e4e4;
    left: 432px;
    top: 0;
  }
  .avatar-uploader-icon-2 {
    font-size: 28px;
    color: #8c939d;
    width: 321px;
    height: 191px;
    line-height: 191px;
    text-align: center;
  }
  .avatar-2 {
    width: 321px;
    height: 191px;
    display: block;
  }
  .avatar-uploader {
    border: 1px dashed #e6e4e4;
    width: 423px;
    height: 290px;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 423px;
    height: 290px;
    line-height: 290px;
    text-align: center;
  }
  .avatar {
    width: 423px;
    height: 290px;
    display: block;
  }
}
</style>
