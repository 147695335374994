import CryptoJS from 'crypto-js';
export function encryptAES(word) {//判断是否存在ksy，不存在就用定义好的key
    let key = CryptoJS.enc.Utf8.parse("W9mTIcCUxEQTAhvc");
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}
export function decrypt(word) {
    let key = CryptoJS.enc.Utf8.parse("W9mTIcCUxEQTAhvc");
    let decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export function decode(val) {
  return String.fromCharCode.apply(null, val.trim().split(","));
}

export function encode(val) {
  var bytes = [];
  for (var i = 0; i < val.length; i++) {
    bytes.push(val.charCodeAt(i));
  }
  return bytes.join(",");
}
