<template>
  <div class="crumb_main">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item>{{ crumb }}</el-breadcrumb-item> -->
      <el-breadcrumb-item
        v-if="childrenCrumb && childrenCrumb != crumb"
        :to="{ path: pathCrumb }"
        >{{ childrenCrumb }}</el-breadcrumb-item
      >
      <el-breadcrumb-item v-if="son != childrenCrumb">{{
        son
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crumb: "",
      childrenCrumb: "",
      pathCrumb: "",
      son: "",
    };
  },
  watch: {
    $route() {
      this.childrenSon();
    },
  },
  methods: {
    init(crumb) {
      this.crumb = crumb;
      setTimeout(() => {
        this.childrenCrumb = this.$route.name;
        this.pathCrumb = this.$route.path;
      }, 1);
    },
    childrenSon() {
      setTimeout(() => {
        this.son = this.$route.name;
      }, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.crumb_main {
  .el-breadcrumb {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-left: 10px;
  }
}
</style>
