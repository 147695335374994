<template>
  <div class="technology-main">
    <div class="img-main">
      <el-carousel :interval="4000" type="card" height="400px">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <img
            class="img-item"
            :src="$imgUrl + item"
            alt=""
            @click="remove(item, index)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bto-btn">
      <el-upload
        class="avatar-uploader-18"
        action="#"
        :http-request="imgUpload"
        :show-file-list="false"
      >
        <el-button style="width: 120px" size="small" type="primary"
          >点击上传</el-button
        >
      </el-upload>
      <el-button
        type="primary"
        size="small"
        style="width: 120px; margin-left: 20px"
        @click="subimt"
        >保存</el-button
      >
    </div>
    <div class="explanatory-main">注：点击中间图片可删除！</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [],
      id: "",
      url: {
        add: "/aboutUsTechnology/insert",
        edit: "/aboutUsTechnology/update",
        list: "/aboutUsTechnology/list",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    subimt() {
      let url = "";
      let query = {};
      if (this.id) {
        url = this.url.edit;
        query.id = this.id;
        query.picture = JSON.stringify(this.imgList);
      } else {
        url = this.url.add;
        query.picture = JSON.stringify(this.imgList);
      }
      this.$http.postRequest(url, query).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
      });
    },
    getData() {
      this.$http.getRequest(this.url.list).then((res) => {
        if (res.code === 200) {
          if (res.data.length > 0) {
            this.id = res.data[0].id;
            this.imgList = JSON.parse(res.data[0].picture);
          }
        }
      });
    },
    remove(item, i) {
      this.$confirm("确定删除这张图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.imgList.splice(i, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    imgUpload(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.imgList.push(res.data);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.technology-main {
}
.explanatory-main {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}
.img-main {
  margin-top: 5%;
  .img-item {
    width: 50%;
    margin-left: 25%;
    height: 400px;
  }
}
.bto-btn {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
