<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="60%"
      :show-close="true"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <el-form
        :model="model"
        :rules="rule"
        ref="registerRef"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="方案名称" prop="programmeName">
              <el-input
                v-model="model.programmeName"
                placeholder="请输入内容"
                style="width: 100%; margin-left: 14px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="方案概述" prop="overview">
              <el-input
                type="textarea"
                :rows="2"
                v-model="model.overview"
                placeholder="请输入方案概述"
                style="width: 100%; margin-left: 14px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="方案内容" prop="programmeContent">
              <div class="form-content">
                <quill-editor
                  class="ql-editor"
                  v-model="model.programmeContent"
                  ref="myQuillEditor"
                  :options="editorOption"
                >
                </quill-editor>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="选择类型" prop="solutionTypeId">
              <div class="solution-type">
                <div
                  class="item"
                  :style="{
                    color: model.solutionTypeId === item.id ? '#3D7BFA' : '',
                    border:
                      model.solutionTypeId === item.id
                        ? '1px solid #3D7BFA'
                        : '',
                  }"
                  v-for="(item, index) in solutionType"
                  :key="index"
                  @click="selectType(item, index)"
                >
                  {{ item.solutionType }}
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="选择场景" prop="solutionScreenId">
              <div class="solution_scene">
                <div
                  class="item"
                  :style="{
                    color: model.solutionScreenId === item.id ? '#3D7BFA' : '',
                    border:
                      model.solutionScreenId === item.id
                        ? '1px solid #3D7BFA'
                        : '',
                  }"
                  v-for="(item, index) in solutionScene"
                  :key="index"
                  @click="selectScene(item, index)"
                >
                  {{ item.solutionScreen }}
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面图" prop="picture">
              <el-upload
                class="upload-demo"
                action="#"
                :http-request="imgUpload"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture"
              >
                <el-button
                  size="small"
                  type="primary"
                  :disabled="fileList.length > 0"
                  >点击上传
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权重" prop="weight">
              <el-input-number
                v-model="model.weight"
                :min="1"
                :max="100"
                label="解决方案的显示顺序"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('registerRef')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "menuSave",
  data() {
    return {
      /* 富文本相关 */
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ size: ["12px", false, "16px", "18px", "20px", "30px"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            ["link", "image", "video"], // 链接、图片、视频
          ],
        },
        placeholder: "请输入正文",
      },
      /* 富文本结束 */
      solutionTypeI: null,
      solutionType: [],
      solutionSceneI: null,
      solutionScene: [],
      fileList: [],

      model: {
        solutionScreenId: null,
        solutionTypeId: null,
      },
      dialogVisible: false,
      url: {
        type: "/solutionType/list",
        scene: "/solutionScreen/list",
        edit: "/solution/update",
        add: "/solution/insert",
      },
      title: "",
      rule: {
        programmeName: {
          required: true,
          message: "请输入方案名称",
          trigger: "blur",
        },
        programmeContent: {
          required: true,
          message: "请输入方案内容",
          trigger: "blur",
        },
        solutionTypeId: {
          required: true,
          message: "请选择方案类型",
          trigger: "change",
        },
        solutionScreenId: {
          required: true,
          message: "请选择方案场景",
          trigger: "blur",
        },
        picture: {
          required: true,
          message: "请上传方案封面图",
          trigger: "blur",
        },
        weight: {
          required: true,
          message: "请输入方案权重",
          trigger: "blur",
        },
      },
    };
  },
  mounted() {},
  methods: {
    handleImageUpload(file) {
      // 处理图片上传
      // 在此处触发上传图片的逻辑，例如调用后端接口等
      console.log("file", file);
      var formData = new FormData();
      formData.append("file", file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          const range = this.$refs.myQuillEditor.quill.getSelection();
          console.log("range", range);
          this.$refs.myQuillEditor.quill.insertEmbed(
            range.index,
            "image",
            this.$imgUrl + res.data
          );
        }
      });
      // 然后将图片URL插入到富文本编辑器
    },
    imgUpload(file) {
      console.log("file", file);
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.fileList.push({
            name: file.file.name,
            url: this.$imgUrl + res.data,
          });
          this.model.picture = JSON.stringify(this.fileList);
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    /* 获取解决方案类型 */
    getSolutionType() {
      this.$http.getRequest(this.url.type, {}).then((res) => {
        if (res.code === 200) {
          this.solutionType = res.data;
        }
      });
    },
    selectType(item, index) {
      this.$set(this.model, this.model.solutionTypeId, item.id);
      this.model.solutionTypeId = item.id;
      console.log("this.model.solutionScreenId", this.model.solutionScreenId);
      this.model.solutionTypeName = item.solutionType;
    },
    selectScene(item, index) {
      this.$set(this.model, this.model.solutionScreenId, item.id);
      this.model.solutionScreenId = item.id;
      console.log("this.model.solutionScreenId", this.model.solutionScreenId);
      this.model.solutionScreenName = item.solutionScreen;
    },
    /* 获取解决方案场景 */
    getSceneType() {
      this.$http.getRequest(this.url.scene, {}).then((res) => {
        if (res.code === 200) {
          this.solutionScene = res.data;
        }
      });
    },
    init(record, title) {
      this.title = title;
      this.getSolutionType();
      this.getSceneType();
      if (record) {
        this.model = record;
        this.fileList = JSON.parse(record.picture);
      } else {
        this.model = {
          solutionScreenId: null,
          solutionTypeId: null,
        };
        this.fileList = [];
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        const quillEditorInstance = this.$refs.myQuillEditor.quill;
        // 监听Quill Editor的图片上传事件
        quillEditorInstance.getModule("toolbar").addHandler("image", () => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/png, image/jpeg");
          input.click();
          // 处理图片选择事件
          input.onchange = () => {
            const file = input.files[0];

            // 调用图片上传方法
            this.handleImageUpload(file);
          };
        });
      });
    },
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let httpurl = "";
          if (!this.model.id) {
            httpurl += this.url.add;
          } else {
            httpurl += this.url.edit;
          }
          this.$http.postRequest(httpurl, this.model).then((res) => {
            if (res.code == 200) {
              that.$message.success("操作成功");
              that.$emit("loadData");
              this.dialogVisible = false;
            } else {
              that.$message.warning("操作失败");
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ql-editor {
}
.form-content {
  height: 400px;
}
.solution-type {
  display: flex;
  .item {
    padding: 0 10px;
    border: 1px solid #eee;
    margin-left: 14px;
    border-radius: 4px;
  }
  cursor: pointer;
}

.solution_scene {
  width: 1000px;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  .item {
    flex: 0 0 auto;
    padding: 0 10px;
    border: 1px solid #eee;
    margin-left: 14px;
    border-radius: 4px;
  }
}
</style>
