import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "./utils/utils";
import config from "./request/config";
// 引入富文本
import quillEditor from "vue-quill-editor";
// 引入样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(quillEditor /* { 默认全局 } */);
// 引入elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
/* 注册全局组件 */
import "./components.js";
//引入echarts
import * as echarts from "echarts";
// 全局引入elementUI
Vue.use(ElementUI);
// 引入pdf
import vueToPdf from "vue-to-pdf";
Vue.use(vueToPdf);
// 引入高德地图
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
// 挂载全局api
import http from "./request/index";
Vue.prototype.$http = http;
Vue.prototype.$utils = utils;
Vue.prototype.$config = config;
Vue.config.productionTip = false;
console.log(process.env.NODE_ENV, "环境");
if (process.env.NODE_ENV == "development") {
  // Vue.prototype.$imgUrl = "http://appimg.hlx.com/syzt2image/";
  Vue.prototype.$imgUrl = "http://www.zwhldk.com/image";
} else {
  //   Vue.prototype.$imgUrl = "https://syzt.zwhldk.com:11189/images";
  Vue.prototype.$imgUrl = "http://www.zwhldk.com/image";
  // Vue.prototype.$imgUrl = "http://192.168.119.249:12113/commonfile"
}



//echarts 全局挂载
Vue.prototype.$echarts = echarts;

Vue.directive("dialogDrag", {
  bind(el, binding, vnode) {
    console.log(el);
  },
});
Vue.directive("preventReClick", {
  inserted(el, binding) {
    el.addEventListener("click", () => {
      console.log("阻止点击");
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 2000); // 默认3秒后恢复可点击状态
      }
    });
  },
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
