var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-main"},[_c('div',{staticClass:"home-head"},[_vm._m(0),_c('div',{staticClass:"head-menu"},_vm._l((_vm.headMenu),function(item,index){return _c('div',{key:index,staticClass:"menu-item",style:({ color: _vm.headMenuIndex === index ? '#d81e06' : '' }),on:{"mouseover":function($event){return _vm.handleMenu(item, index)}}},[(item.icon)?_c('div',{staticClass:"item-icon"},[_c('img',{attrs:{"src":_vm.headMenuIndex === index ? item.icon : item.iconNo,"alt":""}})]):_vm._e(),_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.title)+" "),(item.children)?_c('span',{staticClass:"el-icon-arrow-down"}):_vm._e()]),(item.icon)?_c('div',{staticClass:"item-icon"},[_c('img',{attrs:{"src":_vm.headMenuIndex === index ? item.wait : item.waitNo,"alt":""}})]):_vm._e()])}),0),_vm._m(1)]),_c('div',{staticClass:"solveMenu",on:{"mouseleave":_vm.mouseleaveParent}},[(_vm.isPrudoctMore)?_c('div',{staticClass:"clickSolve"},_vm._l((_vm.solutionType),function(item,index){return _c('div',{key:index,ref:"solveParent",refInFor:true,staticClass:"parent",style:({
          color: _vm.solveIndex === index ? '#ffffff' : '',
          backgroundColor: _vm.solveIndex === index ? '#d81e06' : '',
        }),on:{"mouseover":function($event){return _vm.clickParent(item, index)},"click":function($event){return _vm.clickParent(item, index)}}},[_c('div',{staticClass:"parent_text"},[_vm._v(_vm._s(item.solutionType))])])}),0):_vm._e(),(_vm.isPrudoctMore && this.scene != '')?_c('div',{staticClass:"clickChildren",style:({
        top: _vm.solveIndex * 61 + 'px',
      })},_vm._l((_vm.scene),function(item,index){return _c('div',{key:index,staticClass:"children",style:({
          color: _vm.sceneIndex === item.screenId ? '#ffffff' : '',
          backgroundColor: _vm.sceneIndex === item.screenId ? '#d81e06' : '',
        }),on:{"click":function($event){return _vm.clickChildren(item, index)}}},[_c('div',{staticClass:"children_text"},[_vm._v(" "+_vm._s(item.solutionScreenName)+" ")])])}),0):_vm._e()]),_c('div',{staticClass:"home_body"},[_c('router-view')],1),_c('div',{staticClass:"home_bottom"},[_vm._m(2),_c('div',{staticClass:"product_center"},[_c('div',{staticClass:"pro_title"},[_vm._v("产品中心")]),_vm._l((_vm.productList),function(item,index){return _c('div',{key:index,staticClass:"pro_item",on:{"click":function($event){return _vm.producFtindDetail(item)}}},[_vm._v(" "+_vm._s(item.productName)+" ")])})],2),_c('div',{staticClass:"about_us"},[_c('div',{staticClass:"about_title"},[_vm._v("关于我们")]),_vm._l((_vm.aboutUsList),function(item,index){return _c('div',{key:index,staticClass:"about_item",on:{"click":function($event){return _vm.aboutUsFindDetail(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2),_c('div',{staticClass:"solution"},[_c('div',{staticClass:"solution_title"},[_vm._v("解决方案")]),_vm._l((_vm.solutionList),function(item,index){return _c('div',{key:index,staticClass:"solution_item",on:{"click":function($event){return _vm.findDetail(item)}}},[_vm._v(" "+_vm._s(item.programmeName)+" ")])})],2),_vm._m(3),_c('div',{staticClass:"company_info"},[_vm._v("恒力科技 宁ICP备17002344号-1")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-logo"},[_c('img',{attrs:{"src":require("../../../assets/logo.png"),"alt":"","sizes":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-phone"},[_c('img',{attrs:{"src":require("../../../assets/home/call_phone.png"),"alt":""}}),_vm._v(" 400-104-8119 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/home/index_icon.png"),"alt":""}}),_c('div',{staticClass:"company_phone"},[_c('img',{attrs:{"src":require("@/assets/home/index_phone.png"),"alt":""}}),_c('div',{staticClass:"phone_title"},[_vm._v("服务热线")])]),_c('div',{staticClass:"phone"},[_vm._v("400-104-8119")]),_c('div',{staticClass:"phone"},[_vm._v("hlkjyxgs2014@163.com")]),_c('div',{staticClass:"company_phone"},[_c('img',{attrs:{"src":require("@/assets/home/index_address.png"),"alt":""}}),_c('div',{staticClass:"phone_title"},[_vm._v("公司地址")])]),_c('div',{staticClass:"address"},[_vm._v("宁夏省银川市西夏区中关村创新中心A座20楼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wx_gz"},[_c('img',{attrs:{"src":require("@/assets/home/index_wx_gz.png"),"alt":""}}),_c('div',{staticClass:"wx_title"},[_vm._v("关注公众号")])])
}]

export { render, staticRenderFns }