import Vue from "vue";
import VueRouter from "vue-router";
/* 前台相关页面 */
import home from "@/views/front/home/home.vue";
import frontIndex from "@/views/front/index/index.vue";
import product from "@/views/front/product/productCenter.vue";
import frontSolution from "@/views/front/solution/solution.vue";
import aboutUsFront from "@/views/front/aboutUs/aboutUs.vue";
import details from "@/views/front/details/details.vue";
import industryInformation from "@/views/front/industryInformation/industryInformation.vue";
import trends from "@/views/front/trends/trends.vue";
import joinUsFront from "@/views/front/joinUs/joinUs.vue";
/* 后台相关页面 */
import index from "@/views/manage/index/index.vue";
import login from "@/views/manage/login/login.vue";
import bannerMan from "@/views/manage/bannerMan/bannerMan.vue";
import solution from "@/views/manage/solution/list.vue";
import solutionSecne from "@/views/manage/solutionSecne/list.vue";
import solutionType from "@/views/manage/solutionType/list.vue";
import productCenter from "@/views/manage/productCenter/list.vue";
import consultationMan from "@/views/manage/consultationMan/list.vue";
import aboutUs from "@/views/manage/aboutUs/list.vue";
import joinUs from "@/views/manage/joinUs/list.vue";
import partners from "@/views/manage/partners/list.vue";
import friendlyLinks from "@/views/manage/friendlyLinks/list.vue";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "官网",
    component: home,
    redirect: "/frontIndex",
    children: [
      {
        path: "/frontIndex",
        name: "首页",
        component: frontIndex,
      },
      {
        path: "/product",
        name: "产品中心",
        component: product,
      },
      {
        path: "/solution",
        name: "解决方案",
        component: frontSolution,
      },
      {
        path: "/aboutUs",
        name: "关于我们",
        component: aboutUsFront,
      },
      {
        path: "/details",
        name: "详情",
        component: details,
      },
      {
        path: "/industryInformation",
        name: "行业资讯",
        component: industryInformation,
      },
      {
        path: "/trends",
        name: "恒力动态",
        component: trends,
      },
      {
        path: "/joinUs",
        name: "诚聘英才",
        component: joinUsFront,
      },
    ],
  },
  {
    path: "/login",
    name: "用户登录",
    component: login,
  },
  {
    path: "/backManage",
    name: "用户登录",
    component: index,
    redirect: "/backManage/bannerMan",
    children: [
      {
        path: "/backManage/bannerMan",
        name: "轮播图管理",
        component: bannerMan,
      },
      {
        path: "/backManage/solution",
        name: "解决方案",
        component: solution,
      },
      {
        path: "/backManage/solutionSecne",
        name: "解决方案场景",
        component: solutionSecne,
      },
      {
        path: "/backManage/solutionType",
        name: "解决方案类别",
        component: solutionType,
      },
      {
        path: "/backManage/productCenter",
        name: "产品中心",
        component: productCenter,
      },
      {
        path: "/backManage/consultationMan",
        name: "资讯动态管理",
        component: consultationMan,
      },
      {
        path: "/backManage/aboutUs",
        name: "关于我们",
        component: aboutUs,
      },
      {
        path: "/backManage/joinUs",
        name: "诚聘英才",
        component: joinUs,
      },
      {
        path: "/backManage/partners",
        name: "合作伙伴",
        component: partners,
      },
      {
        path: "/backManage/friendlyLinks",
        name: "友情链接",
        component: friendlyLinks,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
/* 
  根据url地址以及当前是否登录进入相关页面，防止地址栏输入地址进入页面
*/
import { Message } from "element-ui";
router.beforeEach((to, from, next) => {
  if (to.matched[0].path === "/backManage") {
    let userInfo = store.state.userInfo;
    if (to.path === "/login") {
      next();
    } else {
      if (userInfo.username) {
        next();
      } else {
        Message.error("请先登录!");
        next({
          path: "/login",
        });
      }
    }
  } else {
    next();
  }
});

export default router;
