// 门户详情页面
<template>
  <div class="body">
    <div class="left" v-loading="loading">
      <div class="left_top">
        <div class="left_top_title">
          {{
            dataQuery.programmeName ||
            dataQuery.title ||
            dataQuery.productName
          }}
        </div>
        <div class="left_top_time">
          发布时间:{{ dataQuery.publishTime }}
        </div>
      </div>

      <!-- <pre
        class="left_bottom"
        v-html="

        "
      ></pre> -->
      <div
          class="left_bottom"
          v-html="
          handleContent(
            dataQuery.programmeContent ||
              dataQuery.content ||
              dataQuery.productInfo
          )
        "
          v-resize-images
      ></div>
    </div>
    <div class="right">
      <div class="title">关注企业公众号</div>
      <div class="img">
        <img src="../../../assets/home/详情二维码.png" alt=""/>
      </div>
      <div class="right_bottom">
        <div class="content">
          <div class="pict"><span>使命</span></div>
          <div class="text">振兴一方经济，造福一方人民。</div>
        </div>
        <div class="content">
          <div class="pict"><span>愿景</span></div>
          <div class="text">成为西北地区高科技企业的领跑者。</div>
        </div>
        <div class="content">
          <div class="pict"><span>价值观</span></div>
          <div class="text">诚信、高效、创新、协作。</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataQuery: {},
      url: {
        find: "/indexWebsite/newsGetById"
      },
      loading: false
    };
  },
  // 富文本的img设置样式无法生效时，使用该方法，要在标签中添加v-resize-images
  directives: {
    resizeImages: {
      inserted(el) {
        const images = el.getElementsByTagName("img");
        const p = el.getElementsByTagName("p");
        for (let i = 0; i < images.length; i++) {
          images[i].style.width = "1067px"; // 设置图片最大宽度
          images[i].style.height = "603px"; // 设置图片最大高度
          // p[i].style.textIndent = "30px";
        }
      },
    },
  },
  methods: {
    handleContent(content) {
      if (content === undefined) return
      console.log("====", content);
      const regex = /\s(?![^<>]*>)/g;
      const newStr = content.replace(regex, "&nbsp;");
      return newStr;
    },


  },
  mounted() {
    const query = this.$route.query;
    this.loading = true
    this.$http.getRequest(this.url.find, {id: query.id}).then((res) => {
      if (res.code === 200) {
        this.dataQuery = res.data
        this.loading = false
      }
    })
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  display: flex;

  .left {
    width: 1071px;
    margin-left: 259px;

    .left_top {
      width: 1070px;
      border-bottom: 1px solid #e4e4e4;

      .left_top_title {
        font-size: 48px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
      }

      .left_top_time {
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #757575;
        margin-top: 34px;
        margin-bottom: 15px;
      }
    }

    .left_bottom {
      font-size: 16px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #000000;
    }
  }

  .right {
    width: 253px;
    height: 729.8px;
    border: 1px solid #e4e4e4;
    margin-left: 96px;

    .title {
      font-size: 24px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
      margin-top: 26px;
      margin-left: 21px;
    }

    .img {
      width: 208px;
      height: 208px;
      margin-left: 19px;
      margin-top: 19px;
    }

    .right_bottom {
      width: 208px;
      height: 393px;
      border: 1px solid #e4e4e4;
      border-radius: 6px;
      margin-top: 35px;
      margin-left: 21px;

      .pict {
        width: 132px;
        height: 31px;
        margin-left: 15px;
        margin-top: 24px;
        color: #fff;
        background-image: url("../../../assets/home/使命背景图.png");

        span {
          margin-left: 10px;
          line-height: 31px;
        }
      }

      .text {
        width: 164px;
        height: 44px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
        margin-left: 17px;
        margin-top: 21px;
      }
    }
  }
}
</style>
