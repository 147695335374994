<template>
  <div class="body">
    <!-- 头部轮播图 -->
    <div class="head">
      <!-- 轮播图 -->
      <div class="head_banner">
        <img
          v-if="this.indexBanner.resourceType == 2"
          :src="$imgUrl + this.indexBanner.fileUrl"
          alt=""
        />
        <video
          v-if="this.indexBanner.resourceType == 1"
          :src="$imgUrl + this.indexBanner.fileUrl"
          autoplay
          muted
          controls
          loop
        ></video>
      </div>
      <!-- 使命 -->
      <div class="head_foot">
        <div class="head_foot_conte">
          <img src="../../../assets/home/sm.png" alt="" />
          <div><b>使命</b></div>
          <p>振兴一方经济，造福一方人民</p>
        </div>
        <div class="head_foot_conte">
          <img src="../../../assets/home/yj.png" alt="" />
          <div><b>愿景</b></div>
          <p>成为西北地区高科技企业的领跑者</p>
        </div>
        <div class="head_foot_conte">
          <img src="../../../assets/home/jzg.png" alt="" />
          <div><b>价值观</b></div>
          <p>诚信、高效、创新、协作</p>
        </div>
      </div>
    </div>
    <!-- 行业解决方案 -->
    <div class="solution">
      <!-- 解决方案文字 -->
      <div class="solution_title">
        <div class="solution_title_top">
          <b>行业解决方案</b>
          <div>丰富的行业经验，为您提供全面的行业解决方案</div>
        </div>
        <div class="solution_title_bottom">
          <img
            src="../../../assets/home/组 20.png"
            alt=""
            @click="solutionJump"
          />
        </div>
      </div>
      <!-- 解决方案内容大图 -->
      <div class="solution_conte">
        <div class="head-menu">
          <div
            class="menu-item"
            v-for="(item, index) in solutionType"
            :key="index"
            :style="{
              color: headMenuIndex === index ? '#d81e06' : '',
              width: 100 / solutionType.length + '%',
            }"
            @click="handleMenuSolution(item, index)"
          >
            <div class="item-icon">
              <img src="../../../assets/home/工厂 (1).png" />
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.solutionType"
              placement="bottom"
            >
              <div class="item-title">
                {{ item.solutionType }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="solution_conte_bottom" v-if="solutionMenu">
          <div class="solution_conte_left">
            <div class="left_type">{{ solutionMenu.solutionType }}</div>
            <div class="left_content">{{ solutionMenu.content }}</div>
            <div class="left_btn" @click="findDetail(solutionMenu)">
              ➡ 查看详情
            </div>
          </div>
          <div class="solution_conte_right">
            <img :src="$imgUrl + solutionMenu.picture" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 市场版图 -->
    <div class="territory">
      <!-- 市场版图文字 -->
      <div class="territory_title">
        <div class="territory_title_top">
          <b>市场版图</b>
          <div>讯息让世界畅联无阻，恒力科技为您提供专业的服务</div>
        </div>
      </div>
      <div class="territory_bottom">
        <div class="territory_bottom_img">
          <img src="../../../assets/home/组 30 拷贝.png" alt="" />
        </div>
        <div class="territory_bottom_text">
          <p>服务涵盖宁夏、内蒙、青海、甘肃 新疆、西藏</p>
          <div>
            目前，数字化转型已成为产业发展、城市治理的必然选择，万物互联，虚实并行，聚焦生态，赋能产业，一个全新的数字经济时代正加速到来，预示着智慧城市生态已经来临
          </div>
        </div>
      </div>
    </div>
    <!-- 为什么选择我们 -->
    <div class="why">
      <div class="why_top">
        <div class="why_top_title">
          <b>为什么选择我们</b>
          <div>
            <span>诚信</span>
            <span>高效</span>
            <span>创新</span>
            <span>协作</span>
          </div>
        </div>
        <div class="why_top_bottom">践行诚信做事，技术一流，客户至上的理念</div>
      </div>
      <div class="why_bottom">
        <div class="why_bottom_menu">
          <div
            class="why_bottom_menu_item"
            v-for="(item, index) in whyMenu"
            :key="index"
            :style="{ color: headMenuIndex === index ? '#d81e06' : '' }"
          >
            <div class="item-icon" v-if="item.icon">
              <img
                @mouseover="onMouseOver(item, index)"
                @mouseout="onMouseOut(item, index)"
                :src="headMenuIndex === index ? item.icon : item.iconNo"
                :style="{
                  marginTop: headMenuIndex === index ? '-5px' : '',
                }"
                alt=""
              />
            </div>
            <div class="why_bottom_menu_item_title">
              {{ item.title }}
            </div>
            <div class="why_bottom_menu_item_text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 恒力动态 -->
    <div class="trends">
      <!-- 文字 -->
      <div class="trends_title">
        <div class="trends_title_top">
          <b>恒力动态</b>
          <div>新事件、新活动、新成就、新变化、实事求是</div>
        </div>
        <div class="trends_title_bottom">
          <img
            src="../../../assets/home/组 20.png"
            alt=""
            @click="trendsJump"
          />
        </div>
      </div>
      <!-- 大图 -->
      <div class="trends_conte">
        <div class="trends_conte_left">
          <div class="trends_conte_left_img">
            <img
              v-for="(item, index) in news.picture"
              :src="item.url"
              alt=""
              :key="index"
            />
          </div>
          <div class="bottom_new">
            <div class="news">最新动态</div>
            <div class="news_title">{{ news.title }}</div>
          </div>
          <div v-html="news.content" class="p" v-resize-images></div>
          <span class="span">{{ news.publishTime }}</span>
        </div>
        <div class="trends_conte_right" v-if="trendsList.length != 0">
          <div v-for="(item, index) in trendsList.slice(1)" :key="index">
            <div class="list">
              <div><img :src="item.picture[0].url" alt="" /></div>
              <div class="right">
                <div class="list_title" @click="trendsListJump(item)">
                  {{ item.title }}
                </div>
                <div class="list_time">{{ item.publishTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="parents">
      <!-- 合作伙伴文字 -->
      <div class="parents_title">
        <div class="parents_title_top">
          <b>合作伙伴</b>
          <div>
            与中国移动、中国联通、中国电信、亚信科技、海康威视等知名机构深度合作
          </div>
        </div>
      </div>
      <div class="parents_bottom">
        <div
          class="parents_bottom_item"
          v-for="(item, index) in partner"
          :key="index"
        >
          <img :src="$imgUrl + item.picture" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headMenuIndex: null,
      url: {
        banner: "/indexWebsite/getIndexBannerByType", //banner
        solution: "/indexWebsite/getIndexSolution", //行业解决方案
        partner: "/indexWebsite/getIndexPartner", //合作伙伴
        news: "/indexWebsite/getIndexNews", //恒力动态
        solutionType: "/indexWebsite/getIndexSolutionType", //查询行业解决方案类型
      },
      indexBanner: "",
      solution: "",
      partner: [], //合作伙伴
      news: "", //恒力动态
      solutionMenu: "", //行业解决方案
      solutionType: [],
      trendsList: [], //恒力动态
      whyMenu: [
        {
          title: "丰富的行业研发经验",
          icon: require("../../../assets/home/组 25.png"),
          iconNo: require("../../../assets/home/组 25 拷贝.png"),
          text: "9年的研发经验与中国移动 中国联通、中国电信、亚信科技 海康威视等知名机构深度合作",
        },
        {
          title: "成熟专业的解决方案",
          icon: require("@/assets/home/组 26 拷贝.png"),
          iconNo: require("@/assets/home/组 26.png"),
          text: "在智慧城市、智慧园区 智慧水利智慧工厂、综合应急 等领域都有深入的研究",
        },
        {
          title: "软硬件结合的方式",
          icon: require("@/assets/home/组 27 拷贝.png"),
          iconNo: require("@/assets/home/组 27.png"),
          text: "以软硬件结合的方式 为客户提供专业化服务，软硬件 结合的模式让服务更便捷",
        },
        {
          title: "专业的运维团队",
          icon: require("@/assets/home/组 28 拷贝.png"),
          iconNo: require("@/assets/home/组 28.png"),
          text: "建立标准化、专业化、流程化的 运维团队，为多个政府部门和百余家企业 提供专业的第三方技术支持与运维服务",
        },
        {
          title: "完整的研发流程",
          icon: require("@/assets/home/组 29 拷贝.png"),
          iconNo: require("@/assets/home/组 29.png"),
          text: "我们拥有完整的研发流程 研发团队结构清晰，强劲的技术实力 为您保驾护航 ",
        },
      ],
    };
  },
  // 富文本的img设置样式无法生效时，使用该方法，要在标签中添加v-resize-images
  directives: {
    resizeImages: {
      inserted(el) {
        console.log("el", el);
        const images = el.getElementsByTagName("img");
        console.log("images====", images);
        console.log("images.length====", images.length);
        for (let i = 0; i < images.length; i++) {
          alert(111);
          console.log("shide");
          images[i].style.display = "none"; // 隐藏图片
        }
      },
    },
  },
  mounted() {
    this.getIndexBanner();
    this.getSolution(); //行业解决方案
    this.getPartner(); //合作伙伴
    this.getNews(); //恒力动态
    this.getSolutionType();
  },

  methods: {
    trendsListJump(item) {
      this.$router.push({
        path: "/details",
        query: { item: item },
      });
    },
    trendsJump() {
      this.$router.push({
        path: "/trends",
      });
    },
    findDetail(item) {
      this.$router.push({
        path: "/solution",
      });
    },
    solutionJump() {
      this.$router.push({
        path: "/solution",
      });
    },
    onMouseOver(item, i) {
      this.headMenuIndex = i;
      console.log("this.headMenuIndex ", this.headMenuIndex);
    },
    onMouseOut(item, i) {
      this.headMenuIndex = null;
    },
    handleMenuSolution(item, index) {
      this.headMenuIndex = index;
      this.solutionMenu = item;
    },
    handleMenu(item, index) {
      this.headMenuIndex = index;
    },
    getIndexBanner() {
      this.$http
        .getRequest(this.url.banner, { bannerType: 1 })
        .then((res) => {
          if (res.code == 200) {
            this.indexBanner = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
    // 行业解决方案
    getSolution() {
      this.$http
        .getRequest(this.url.solution, { limit: 3 })
        .then((res) => {
          if (res.code == 200) {
            this.solution = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },

    getSolutionType() {
      this.$http
        .getRequest(this.url.solutionType, { limit: 3 })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.length != 0) {
              this.solutionType = res.data;
              this.solutionMenu = res.data[0];
            }
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
    // 合作伙伴
    getPartner() {
      this.$http
        .getRequest(this.url.partner)
        .then((res) => {
          if (res.code == 200) {
            this.partner = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },

    // 恒力动态
    getNews() {
      this.$http
        .getRequest(this.url.news, { limit: 5 })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((e) => {
              e.picture = JSON.parse(e.picture);
            });
            this.trendsList = res.data;
            res.data[0].content = res.data[0].content.replace(
              /<img\b[^>]*>/g,
              ""
            );
            this.news = res.data[0];
            // console.log(this.news.content);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
}
// 轮播图
.head {
  height: 700px;
  margin: auto;
  .head_banner {
    height: 800px;
    img {
      width: 100%;
      height: 800px;
    }
    video {
      width: 100%;
      height: 800px;
    }
  }

  .head_foot {
    width: 100vw;
    padding-left: 397px;
    height: 119px;
    position: absolute;
    display: flex;
    top: 693px;
    background: rgba(255, 255, 255, 0.7);
    .head_foot_conte {
      width: 349px;
      height: 60px;
      margin-left: 70px;
      margin-top: 34px;
      justify-content: space-between;
      div {
        margin-top: -19%;
        margin-left: 25%;
        font-size: 19px;
      }
      p {
        margin-left: 70px;
        margin-top: 1px;
      }
    }
  }
}
// 行业解决方案
.solution {
  width: 100%;
  margin-top: 150px;
  height: 1005px;
  .solution_title {
    height: 205px;
    .solution_title_top {
      width: 565px;
      height: 77px;
      margin-left: 668px;
      margin-right: 687px;
      margin-top: 80px;
      background-image: url("../../../assets/home/solution.png");
      background-size: 100% 100%;
      b {
        font-size: 44px;
        margin-left: 161px;
        margin-bottom: 32px;
        margin-top: 1px;
      }
      div {
        margin-left: 104px;
        font-size: 18px;
        color: #3a3a3a;
      }
    }
    .solution_title_bottom {
      width: 100px;
      height: 29px;
      margin-left: 911px;
      margin-top: 22px;
      cursor: pointer;
    }
  }
  .solution_conte {
    width: 1920px;
    height: 672px;
    background-image: url("../../../assets/home/组 19@2x.png");
    // position: relative;
    .head-menu {
      display: flex;
      margin-left: 260px;
      width: 1400px;
      height: 85px;
      padding-top: 50px;
      border-bottom: 1px solid #c6c6c6;
      // justify-content: center;
      .menu-item {
        // width: 217px;
        height: 85px;
        line-height: 55px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-bottom: 41px;
        text-align: center;
        cursor: pointer;
        .item-title {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .item-icon {
          margin: -3px 5px 0 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
            vertical-align: middle;
          }
        }
      }
    }
    .solution_conte_bottom {
      width: 100%;
      height: 534px;
      margin-left: 278px;
      display: flex;
      .solution_conte_left {
        width: 614px;
        height: 371.4px;
        margin-top: 102px;
        .left_type {
          color: #3a3a3a;
          font-size: 36px;
          font-family: "AlibabaPuHuiTi_2_45_Light";
        }
        .left_content {
          margin-top: 38px;
          color: #3a3a3a;
          font-size: 16px;
          font-family: "AlibabaPuHuiTi_2_45_Light";
          display: -webkit-box; /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 3; /*设置多少行*/
          -webkit-box-orient: vertical; /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
          overflow: hidden; /*文本会被修剪，并且剩余的部分不可见*/
        }
        .left_btn {
          width: 159px;
          height: 51px;
          background-color: #dd1619;
          color: #fff;
          margin-top: 63px;
          font-size: 18px;
          border-radius: 4px;
          text-align: center;
          line-height: 51px;
          cursor: pointer;
        }
      }
      .solution_conte_right {
        width: 659.3px;
        height: 371.4px;
        margin-top: 70px;
        margin-left: 90px;
        overflow: hidden;
        img {
          width: 659.3px;
          height: 371.4px;
          border-radius: 5px;
          transition: transform 0.5s;
          cursor: pointer;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

//市场版图
.territory {
  width: 100%;
  height: 1005px;
  .territory_title {
    height: 150px;
  }
  .territory_title_top {
    width: 632px;
    height: 79px;
    background-image: url("../../../assets/home/territory.png");
    background-size: 100% 100%;
    margin-left: 668px;
    margin-right: 687px;
    b {
      font-size: 44px;
      margin-left: 221px;
      margin-bottom: 32px;
      margin-top: 1px;
    }
    div {
      margin-left: 104px;
      font-size: 18px;
      color: #3a3a3a;
    }
  }

  .territory_bottom {
    height: 750px;
    display: flex;
    .territory_bottom_img {
      width: 910px;
      height: 750px;
      margin-left: 252px;
      margin-top: 70px;
    }
    .territory_bottom_text {
      width: 493px;
      color: #757575;
      margin-left: 55%;
      position: absolute;
      margin-top: 21%;
      p {
        color: #dd1619;
        font-size: 36px;
      }
      div {
        font-size: 18px;
      }
    }
  }
}

// 为什么选择我们、
.why {
  height: 600px;
  background-image: url("../../../assets/home/背景.png");
  background-size: 100% 100%;
  .why_top {
    height: 210px;
    padding-top: 104px;
    .why_top_title {
      width: 403px;
      height: 73px;
      background-image: url("../../../assets/home/choice.png");
      margin-left: 758px;
      b {
        font-size: 44px;
        margin-left: 53px;
        margin-bottom: 32px;
        margin-top: 1px;
      }
      div {
        margin-left: 100px;
        span {
          color: #fff;
          background-color: #dd1619;
          width: 40px;
          height: 24px;
          margin-left: 12px;
          padding: 2px;
          border-radius: 4px;
        }
      }
    }
    .why_top_bottom {
      margin-left: 791px;
      padding-top: 21px;
      color: #3a3a3a;
      font-size: 18px;
    }
  }

  .why_bottom {
    height: 390px;
    .why_bottom_menu {
      display: flex;
      margin-left: 260px;
      width: 1400px;
      height: 135px;
    }
    .why_bottom_menu_item {
      width: 274px;
      height: 135px;
      margin: 0 30px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-bottom: 41px;
      cursor: pointer;
      .item-icon {
        margin-left: 55px;
        img {
          width: 100px;
          height: 100px;
          vertical-align: middle;
        }
      }
      .why_bottom_menu_item_title {
        color: #333333;
        font-size: 20px;
        text-align: center;
      }
      .why_bottom_menu_item_text {
        color: #333333;
        font-size: 14px;
        margin-top: 30px;
      }
    }
  }
}
// 恒力动态
.trends {
  width: 100%;
  height: 1005px;
  .trends_title {
    height: 205px;
    .trends_title_top {
      width: 520px;
      height: 77px;
      margin-left: 668px;
      margin-right: 687px;
      margin-top: 80px;
      background-image: url("../../../assets/home/message.png");
      background-size: 100% 100%;
      b {
        font-size: 44px;
        margin-left: 161px;
        margin-bottom: 32px;
        margin-top: 1px;
      }
      div {
        margin-left: 104px;
        font-size: 18px;
        color: #3a3a3a;
      }
    }
    .trends_title_bottom {
      width: 100px;
      height: 29px;
      margin-left: 911px;
      margin-top: 22px;
      cursor: pointer;
    }
  }
  .trends_conte {
    // width: calc(100vw - 260px -260px);
    display: flex;
    .trends_conte_left {
      width: 747px;
      height: 628px;
      background-color: #f7f8fc;
      border-radius: 15px;
      margin-left: 261px;
      position: relative;
      .trends_conte_left_img {
        width: 747px;
        height: 419px;
        overflow: hidden;
        img {
          width: 747px;
          height: 419px;
          cursor: pointer;

          transition: transform 0.5s;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
      .p {
        width: 716px;
        color: #757575;
        font-size: 16px;
        // margin-bottom: 84px;
        margin-left: 18px;
        display: -webkit-box; /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
        -webkit-line-clamp: 3; /*设置多少行*/
        -webkit-box-orient: vertical; /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
        overflow: hidden; /*文本会被修剪，并且剩余的部分不可见*/
        text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
      }
      .span {
        font-size: 16px;
        color: #757575;
        margin-left: 18px;
        position: absolute;
        bottom: 10px;
      }

      .bottom_new {
        display: flex;
        margin-left: -20px;
        margin-top: 24px;
        .news {
          width: 101px;
          height: 31px;
          text-align: center;
          line-height: 31px;
          color: #dd1619;
          font-size: 18px;
          margin-left: 40px;
          background: rgba(221, 22, 25, 0.1);
          border-radius: 4px;
          padding: 0 13px;
        }
        .news_title {
          font-size: 26px;
          width: 801px;
          line-height: 30px;
          margin-left: 20px;
          overflow: hidden;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }
    .trends_conte_right {
      width: 628px;
      height: 628px;
      background-color: #f7f8fc;
      margin-left: 28px;
      border-radius: 15px;
      .list {
        width: 600px;
        height: 97px;
        margin-left: 28px;
        margin-top: 52px;
        display: flex;
        div {
          margin-left: 17px;
        }
        .right {
          // display: flex;
          .list_title {
            width: 359px;
            height: 54px;
            color: #333333;
            font-size: 22px;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          .list_time {
            width: 96px;
            height: 13px;
            color: #757575;
            font-size: 16px;
            margin-top: 23px;
          }
        }

        img {
          width: 163px;
          height: 97px;
          cursor: pointer;
          transition: transform 0.5s;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

// 合作伙伴
.parents {
  width: 100%;
  background-image: url("../../../assets/home/组 24.png");
  background-size: 100% 100%;
  padding-bottom: 111px;
  .parents_title {
    height: 150px;
  }
  .parents_title_top {
    width: 632px;
    height: 79px;
    background-image: url("../../../assets/home/partner.png");
    background-size: 100% 100%;
    margin-left: 668px;
    margin-right: 687px;
    b {
      font-size: 44px;
      margin-left: 221px;
      margin-bottom: 32px;
      margin-top: 1px;
    }
    div {
      margin-left: 10px;
      font-size: 18px;
      color: #3a3a3a;
    }
  }

  .parents_bottom {
    width: calc(100vw - 259px -262px);
    margin-left: 259px;
    display: flex;
    flex-wrap: wrap;
    .parents_bottom_item {
      width: 251px;
      height: 143px;
      margin-right: 35px;
      margin-top: 37px;
      img {
        width: 251px;
        height: 143px;
        border-radius: 5px;
        box-shadow: #757575;
        cursor: pointer;
        transition: transform 0.5s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
  }
}
</style>
