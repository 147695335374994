<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="60%"
      :show-close="true"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <el-form
        :model="model"
        :rules="rule"
        ref="registerRef"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="model.productName"
                placeholder="请输入内容"
                style="width: 100%; margin-left: 14px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="产品介绍" prop="productInfo">
              <div class="form-content">
                <quill-editor
                  class="ql-editor"
                  v-model="model.productInfo"
                  ref="myQuillEditor"
                  :options="editorOption"
                >
                </quill-editor>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="产品类型" prop="productType">
              <el-select
                size="small"
                style="width: 40%; margin-left: 14px"
                v-model="model.productType"
                placeholder="请选择产品类别"
              >
                <el-option
                  v-for="item in productType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面图" prop="picture">
              <el-upload
                class="upload-demo"
                style="margin-left: 14px"
                action="#"
                :http-request="imgUpload"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture"
              >
                <el-button
                  size="small"
                  type="primary"
                  :disabled="fileList.length > 0"
                  >点击上传
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权重" prop="weight">
              <el-input-number
                v-model="model.weight"
                :min="1"
                :max="100"
                label="解决方案的显示顺序"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('registerRef')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "menuSave",
  data() {
    return {
      /* 富文本相关 */
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ size: ["12px", false, "16px", "18px", "20px", "30px"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            ["link", "image", "video"], // 链接、图片、视频
          ],
        },
        placeholder: "请输入正文",
      },
      /* 富文本结束 */
      productType: [
        {
          id: 1,
          label: "软件产品",
        },
        {
          id: 2,
          label: "硬件产品",
        },
        {
          id: 3,
          label: "其他",
        },
      ],
      solutionTypeI: null,
      solutionType: [],
      solutionSceneI: null,
      solutionScene: [],
      fileList: [],
      model: {},
      dialogVisible: false,
      url: {
        edit: "/product/update",
        add: "/product/insert",
      },
      title: "",
      rule: {
        productName: {
          required: true,
          message: "请输入产品名称",
          trigger: "blur",
        },
        productInfo: {
          required: true,
          message: "请输入产品介绍",
          trigger: "blur",
        },
        productType: {
          required: true,
          message: "请选择产品类型",
          trigger: "change",
        },
        picture: {
          required: true,
          message: "请上传方案封面图",
          trigger: ["change", "blur"],
        },
        weight: {
          required: true,
          message: "请输入方案权重",
          trigger: "blur",
        },
      },
    };
  },
  mounted() {},
  methods: {
    handleImageUpload(file) {
      // 处理图片上传
      // 在此处触发上传图片的逻辑，例如调用后端接口等
      console.log("file", file);
      var formData = new FormData();
      formData.append("file", file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          const range = this.$refs.myQuillEditor.quill.getSelection();
          console.log("range", range);
          this.$refs.myQuillEditor.quill.insertEmbed(
            range.index,
            "image",
            this.$imgUrl + res.data
          );
        }
      });
      // 然后将图片URL插入到富文本编辑器
    },
    imgUpload(file) {
      console.log("file", file);
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.fileList.push({
            name: file.file.name,
            url: this.$imgUrl + res.data,
          });
          this.model.picture = JSON.stringify(this.fileList);
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    /* 获取解决方案类型 */
    getSolutionType() {
      this.$http.getRequest(this.url.type, {}).then((res) => {
        if (res.code === 200) {
          this.solutionType = res.data;
        }
      });
    },
    selectType(item, index) {
      this.model.solutionTypeId = item.id;
      this.model.solutionTypeName = item.solutionType;
    },
    selectScene(item, index) {
      this.model.solutionScreenId = item.id;
      this.model.solutionScreenName = item.solutionScreen;
    },
    /* 获取解决方案场景 */
    getSceneType() {
      this.$http.getRequest(this.url.scene, {}).then((res) => {
        if (res.code === 200) {
          this.solutionScene = res.data;
        }
      });
    },
    init(record, title) {
      this.title = title;
      if (record) {
        this.model = record;
        this.fileList = JSON.parse(record.picture);
      } else {
        this.model = {};
        this.fileList = [];
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        const quillEditorInstance = this.$refs.myQuillEditor.quill;
        // 监听Quill Editor的图片上传事件
        quillEditorInstance.getModule("toolbar").addHandler("image", () => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/png, image/jpeg");
          input.click();
          // 处理图片选择事件
          input.onchange = () => {
            const file = input.files[0];

            // 调用图片上传方法
            this.handleImageUpload(file);
          };
        });
      });
    },
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let httpurl = "";
          if (!this.model.id) {
            httpurl += this.url.add;
          } else {
            httpurl += this.url.edit;
          }
          this.$http.postRequest(httpurl, this.model).then((res) => {
            if (res.code == 200) {
              that.$message.success("操作成功");
              that.$emit("loadData");
              this.dialogVisible = false;
            } else {
              that.$message.warning("操作失败!只能上传一张封面图");
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ql-editor {
  line-height: 1.5;
}
.form-content {
  height: 400px;
}
.solution-type {
  display: flex;
  .item {
    padding: 0 10px;
    border: 1px solid #eee;
    margin-left: 14px;
    border-radius: 4px;
  }
  cursor: pointer;
}
</style>
