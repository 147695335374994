var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":"60%","show-close":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"registerRef",attrs:{"model":_vm.model,"rules":_vm.rule,"label-width":"100px","size":"small"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"方案名称","prop":"programmeName"}},[_c('el-input',{staticStyle:{"width":"100%","margin-left":"14px"},attrs:{"placeholder":"请输入内容"},model:{value:(_vm.model.programmeName),callback:function ($$v) {_vm.$set(_vm.model, "programmeName", $$v)},expression:"model.programmeName"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"方案概述","prop":"overview"}},[_c('el-input',{staticStyle:{"width":"100%","margin-left":"14px"},attrs:{"type":"textarea","rows":2,"placeholder":"请输入方案概述"},model:{value:(_vm.model.overview),callback:function ($$v) {_vm.$set(_vm.model, "overview", $$v)},expression:"model.overview"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"方案内容","prop":"programmeContent"}},[_c('div',{staticClass:"form-content"},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"ql-editor",attrs:{"options":_vm.editorOption},model:{value:(_vm.model.programmeContent),callback:function ($$v) {_vm.$set(_vm.model, "programmeContent", $$v)},expression:"model.programmeContent"}})],1)])],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"选择类型","prop":"solutionTypeId"}},[_c('div',{staticClass:"solution-type"},_vm._l((_vm.solutionType),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
                  color: _vm.model.solutionTypeId === item.id ? '#3D7BFA' : '',
                  border:
                    _vm.model.solutionTypeId === item.id
                      ? '1px solid #3D7BFA'
                      : '',
                }),on:{"click":function($event){return _vm.selectType(item, index)}}},[_vm._v(" "+_vm._s(item.solutionType)+" ")])}),0)])],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"选择场景","prop":"solutionScreenId"}},[_c('div',{staticClass:"solution_scene"},_vm._l((_vm.solutionScene),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
                  color: _vm.model.solutionScreenId === item.id ? '#3D7BFA' : '',
                  border:
                    _vm.model.solutionScreenId === item.id
                      ? '1px solid #3D7BFA'
                      : '',
                }),on:{"click":function($event){return _vm.selectScene(item, index)}}},[_vm._v(" "+_vm._s(item.solutionScreen)+" ")])}),0)])],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"封面图","prop":"picture"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"#","http-request":_vm.imgUpload,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"list-type":"picture"}},[_c('el-button',{attrs:{"size":"small","type":"primary","disabled":_vm.fileList.length > 0}},[_vm._v("点击上传 ")])],1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"权重","prop":"weight"}},[_c('el-input-number',{attrs:{"min":1,"max":100,"label":"解决方案的显示顺序"},model:{value:(_vm.model.weight),callback:function ($$v) {_vm.$set(_vm.model, "weight", $$v)},expression:"model.weight"}})],1)],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('registerRef')}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }