/* 
    封装axios
    请求拦截器
    响应拦截器
*/

import Axios from "axios";
import router from "../router";
let url;
if (process.env.NODE_ENV == "development") {
  // url = "http://192.168.119.23:11331"; //洋哥
  url = "http://www.zwhldk.com/java";
} else {
  url = "http://www.zwhldk.com/java";
  // url = "http://192.168.119.249:11331"; //智慧工厂二期
}

// 引入提示框
import { MessageBox } from "element-ui";
import { Message } from "element-ui";
const axios = Axios.create({
  baseURL: url,
  timeout: 150000, // request timeout
});

let showMsg = true; //阻止错误多次弹窗
import store from "../store/index";
// // 添加请求拦截器  作用是在请求发送前进行一些操作
axios.interceptors.request.use(
  function (config) {
    // || config.url != '/indexWebsite/getIndexSolution'
    // // 获取存储token中的值
    let str = "/indexWebsite";
    if (!config.url.includes(str)) {
      let userinfo = store.state.userInfo.authToken || "";
      config.headers.Authorization = userinfo;
    }
    return config;
  },
  function (error) {
    // 抛出错误
    Message.error("请求中错误:" + error);
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器  作用是在接收到响应后进行一些操作
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.status == 200) {
      if (response.data.code === 4040) {
        Message.error("token已失效请从新登陆");
        router.push({
          path: "/",
        });
      } else if (response.data.code === 200) {
        showMsg = false;
        setTimeout(() => {
          showMsg = true;
        }, 3000);
        return response.data;
      } else {
        Message.error(response.data.msg);
        return response.data;
      }
    } else {
      // 获取成功后的错误提示
      MessageBox.alert("系统错误", "错误2", {
        confirmButtonText: "确定",
        type: "error",
      });
    }
  },
  function (error) {
    // 在这里还可以进行响应码的判断
    switch (error.response.status) {
      case 4040:
        Message.error("token已失效请从新登陆");
        router.push({
          path: "/",
        });
        break;
      case 500:
        error.message = "服务器端出错";
        break;
    }
    // 抛出错误提示
    MessageBox.alert(error, "错误3", {
      confirmButtonText: "确定",
      type: "error",
    });
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default axios;
