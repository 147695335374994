<template>
  <div class="product_main">
    <div class="product_banner">
      <img :src="$imgUrl + bannerUrl" alt="" />
    </div>
    <div class="pro_type">
      <div class="type_item">类型：</div>
      <div
        class="type_item"
        v-for="(item, index) in productType"
        :key="index"
        :style="{
          color: productTypeI === item.id ? '#DD1619' : '',
          backgroundColor: productTypeI === item.id ? '#FDE8E8' : '',
        }"
        @click="findType(item)"
      >
        {{ item.label }}
      </div>
      <div class="find_product">
        <el-input
          size="small"
          placeholder="请输入您要查找的产品名称"
          v-model="productName"
        >
          <template slot="append"
            ><el-button class="el-icon-search" @click="findProduct"></el-button
          ></template>
        </el-input>
      </div>
    </div>
    <div class="hr"></div>
    <div class="pro_body">
      <div class="body_item" v-for="(item, index) in productList" :key="index" id="body_item">
        <div class="left_img">
          <img :src="item.picture.url" alt="" />
        </div>
        <div class="right_info">
          <div class="info_title">{{ item.productName }}</div>
          <div class="info_content" v-html="item.productInfo"></div>
          <div class="info_btn" @click="findDetails(item)">查看详情&nbsp;></div>
        </div>
      </div>
    </div>
    <div class="trends_page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page.current"
        :page-size="page.size"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productName: "",
      bannerUrl: "",
      url: {
        data: "/indexWebsite/productPageList",
        banner: "/indexWebsite/getIndexBannerByType",
      },
      productTypeI: "",
      productType: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "软件产品",
        },
        {
          id: 2,
          label: "硬件产品",
        },
        {
          id: 3,
          label: "其他",
        },
      ],
      productList: [],
      page: {
        current: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.getBanner();
    this.getData();
  },
  methods: {
    findProduct() {
      this.getData();
    },
    findType(item) {
      this.productTypeI = item.id;
      this.getData();
    },
    findDetails(item) {
      this.$router.push({
        path: "/details",
        query: { item: item },
      });
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    getBanner() {
      this.$http.getRequest(this.url.banner, { bannerType: 2 }).then((res) => {
        if (res.code === 200) {
          this.bannerUrl = res.data.fileUrl;
        }
      });
    },
    getData() {
      let params = {
        current: this.page.current,
        size: this.page.size,
        productType: this.productTypeI,
        productName: this.productName,
      };
      this.$http.getRequest(this.url.data, params).then((res) => {
        if (res.code === 200) {
          this.page.total = res.data.total;
          this.productList = res.data.records;
          this.productList.forEach((e) => {
            e.picture = JSON.parse(e.picture)[0];
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.product_main {
  width: 100vw;
  margin-bottom: 20px;
  .trends_page {
    text-align: center;
  }
  .pro_body {
    width: 1398.6px;
    margin-top: 40px;
    flex-wrap: wrap;
    display: flex;
    margin-left: 261px;
    justify-content: space-between;
    .body_item {
      margin-bottom: 20px;
      width: 49%;
      height: 296px;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      position: relative;
      .right_info {
        margin: 26px 31px 0 10px;
        width: 240px;
        .info_title {
          width: 100%;
          font-size: 24px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #333333;
          overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
          text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
          white-space: nowrap; /*文本不换行*/
        }
        .info_content {
          width: 100%;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          line-height: 24px;
          color: #333333;
          display: -webkit-box; /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 6; /*设置多少行*/
          -webkit-box-orient: vertical; /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
          overflow: hidden; /*文本会被修剪，并且剩余的部分不可见*/
          text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
        }
        .info_btn {
          width: 113px;
          height: 33px;
          line-height: 33px;
          border: 1px solid #dd1619;
          border-radius: 2px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #dd1619;
          text-align: center;
          cursor: pointer;
          position: absolute;
          bottom: 22px;
        }
      }
      .left_img {
        width: 358px;
        height: 249px;
        margin: 24px 21px;
        overflow: hidden;
        img {
          width: 358px;
          height: 249px;
          border-radius: 2px;
          transition: transform 0.5s;
          cursor: pointer;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .hr {
    margin-top: 38px;
    width: 1398.6px;
    height: 1px;
    margin-left: 261px;
    background-color: #e4e4e4;
  }
  .pro_type {
    margin: 54px 0 0 241px;
    display: flex;
    .type_item {
      height: 32px;
      border-radius: 8px;
      line-height: 32px;
      padding: 0px 10px;
      margin: 0 22px;
      cursor: pointer;
    }
    .find_product {
      margin-left: auto;
      margin-right: 264px;
    }
  }
  .product_banner {
    img {
      width: 100%;
      height: 408px;
    }
  }
}
</style>
