/* 
    用于封装请求方式
*/

import http from "./http.js";

// get请求
let getRequest = (url, params) => {
  return http({
    url: url,
    params,
  });
};

// post请求
let postRequest = (url, data) => {
  return http({
    method: "POST",
    url: url,
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 文件上传
let postFileUpload = (url, params) => {
  return http({
    url: url,
    method: "post",
    data: params,
    transformRequest: [
      function () {
        return params;
      },
    ],
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 删除
let deleteRequest = (url, params) => {
  console.log("url", url);
  return http({
    url: url,
    method: "DELETE",
    data: params,
  });
};

//下载excel
let getExcelRequest = (url, params) => {
  return http({
    url: url,
    method: "get",
    params: params,
    headers: { "Content-Type": "application/json" },
    responseType: "blob", //此属性非常重要，不然数据是乱码
  });
};

export default {
  getRequest,
  postRequest,
  postFileUpload,
  deleteRequest,
  getExcelRequest,
};
