<template>
  <div class="menu_main">
    <div class="main_head">
      <el-row :gutter="20" style="height: 100%" type="flex" align="middle">
        <el-col :span="3">
          <el-input
            size="small"
            v-model="form.developContent"
            placeholder="请输入发展内容"
          />
        </el-col>
        <el-button
          size="small"
          icon="el-icon-search"
          type="primary"
          @click="findMenu"
          >查询</el-button
        >
        <el-button
          size="small"
          type="info"
          icon="el-icon-refresh"
          @click="refreshMenu"
          >重置</el-button
        >
        <el-button
          type="primary"
          @click="handleAdd('新增')"
          icon="el-icon-plus"
          size="small"
          >新增</el-button
        >
      </el-row>
    </div>
    <div class="table-main">
      <el-table :data="dataList" stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="60" align="center">
        </el-table-column>
        <el-table-column label="发展年份" prop="developYear" align="center">
        </el-table-column>
        <el-table-column label="发展内容" prop="developContent" align="center">
        </el-table-column>
        <el-table-column label="操作" width="250px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑">
              <el-button
                type="info"
                icon="el-icon-edit"
                size="mini"
                @click="handleEdit(scope.row, '编辑')"
              ></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="menu_page">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size.sync="size"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
      >
      </el-pagination>
    </div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.show" width="30%">
      <el-form
        ref="form"
        :rules="rules"
        :model="editInfo"
        label-width="100px"
        size="small"
      >
        <el-form-item label="选择年份" prop="developYear">
          <el-date-picker
            style="width: 100%"
            v-model="editInfo.developYear"
            type="year"
            value-format="yyyy"
            placeholder="选择年"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发展内容" prop="developContent">
          <el-input
            type="textarea"
            v-model="editInfo.developContent"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "menuTable",
  data() {
    return {
      editInfo: {},
      dialog: {
        show: false,
        title: "",
      },
      dialogVisible: false,
      dataList: [],
      total: 0,
      size: 10,
      current: 1,
      rules: {
        developYear: [
          { required: true, message: "请选择年份", trigger: "change" },
        ],
        developContent: [
          { required: true, message: "请输入发展内容", trigger: "blur" },
        ],
      },
      url: {
        add: "/aboutUsDevelop/insert",
        edit: "/aboutUsDevelop/update",
        delete: "/aboutUsDevelop/deleteById",
        list: "/aboutUsDevelop/pageList",
      },
      form: {
        developContent: "",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    submit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.editInfo.id) {
            url = this.url.edit;
          } else {
            url = this.url.add;
          }
          this.$http.postRequest(url, this.editInfo).then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialog.show = false;
              this.loadData();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改
    handleEdit(item) {
      item.developYear = String(item.developYear);
      this.editInfo = item;
      this.dialog.title = "编辑";
      this.dialog.show = true;
    },
    // 新增
    handleAdd() {
      this.editInfo = {};
      this.dialog.title = "新增";
      this.dialog.show = true;
    },
    // 重置
    refreshMenu() {
      this.form.developContent = "";
      this.loadData();
    },
    // 查询
    findMenu() {
      this.loadData();
    },
    // 分页
    handleCurrentChange(val) {
      this.current = val;
      this.loadData();
    },
    // 删除
    handleDelete(id) {
      this.$confirm("确定删除该菜单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .deleteRequest(`${this.url.delete}?id=${id}`)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.loadData();
              } else {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            });
        })
        .catch(() => {
          // 取消时的操作
        });
    },
    // 获取表格数据
    loadData() {
      const params = {
        current: this.current,
        size: this.size,
        id: this.form.id,
        developContent: this.form.developContent,
      };
      this.$http
        .getRequest(this.url.list, params)
        .then((res) => {
          if (res.code == 200) {
            this.dataList = res.data.records;
            this.total = res.data.total;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
.menu_main {
  margin: 0 20px;
  ::v-deep .el-card__body {
    padding: 20px 20px 10px 20px;
  }
  .table-main {
    background: #f3f3f3;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px 20px;
  }
  .main_head {
    background: #f3f3f3;
    height: 80px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0 20px;
    .el-col {
      display: flex;
      align-items: center;
      .text {
        width: 40%;
        text-align: center;
      }
    }
  }
  .menu_table {
    overflow-y: scroll;
  }
  .menu_page {
    margin-top: 10px;
  }
}
</style>
