<template>
  <div class="join_main" :style="{ minHeight: height + 'px' }">
    <div class="join_banner">
      <img :src="$imgUrl + bannerUrl" alt="" />
    </div>
    <div class="join_menu">
      <div
        class="menu_item"
        v-for="(item, index) in joinDept"
        :key="index"
        :style="{
          color: joinDeptI === item.state || '' ? '#fff' : '',
          backgroundColor: joinDeptI === item.state || '' ? '#DD1619' : '',
        }"
        @click="handleDept(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="join_body">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item v-for="(item, index) in joinList" :key="index">
          <template slot="title">
            <div class="item_head">
              <div class="head_name">{{ item.jobName }}</div>
              <div class="head_dept">{{ item.joinUsDeptDict }}</div>
              <div class="head_address">{{ item.workAddressDict }}</div>
            </div>
          </template>
          <div class="body_title">岗位职责：</div>
          <div class="body_job">{{ item.jobResponsibility }}</div>
          <div class="body_title">任职资格：</div>
          <div class="body_job">{{ item.jobQualifications }}</div>
          <div class="email">
            简历请投递至:
            <span style="color: #dd1619">hlkjyxgs2014@163.com</span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="join_welfare" ref="join_welfare">
      <div class="walfare_head">福利待遇</div>
      <div
        class="welfare_item"
        v-for="(item, index) in walfareList"
        :key="index"
      >
        <div class="item_title">
          {{ item.title }}
        </div>
        <div class="item_content">{{ item.welfareDesc }}</div>
        <div class="hr" v-if="index != walfareList.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: null,
      activeNames: [],
      bannerUrl: "",
      joinDeptI: "",
      joinDept: [],
      joinList: [],
      walfareList: [],
      url: {
        data: "/indexWebsite/recruitList",
        banner: "/indexWebsite/getIndexBannerByType",
        walfare: "/indexWebsite/welfareList",
      },
    };
  },
  async mounted() {
    setTimeout(() => {
      this.height = this.$refs.join_welfare.offsetHeight + 450;
      console.log(" this.height", this.height);
    }, 200);
    this.getBanner();
    this.getData();
    this.getWalfareList();
    this.joinDept = [
      {
        name: "全部",
        state: "",
      },
    ];
    this.joinDept = this.joinDept.concat(
      await this.$config.getIndexWebsiteDict("join_us_dept")
    );
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    getWalfareList() {
      this.$http.getRequest(this.url.walfare).then((res) => {
        if (res.code === 200) {
          this.walfareList = res.data;
        }
      });
    },
    handleDept(item, i) {
      console.log("item", item);
      this.joinDeptI = item.state;
      this.getData();
    },
    getData() {
      this.$http
        .getRequest(this.url.data, { joinUsDept: this.joinDeptI })
        .then((res) => {
          if (res.code === 200) {
            this.joinList = res.data;
          }
        });
    },
    getBanner() {
      this.$http.getRequest(this.url.banner, { bannerType: 6 }).then((res) => {
        if (res.code === 200) {
          this.bannerUrl = res.data.fileUrl;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.join_main {
  width: 100vw;
  margin-bottom: 20px;
  position: relative;
  .join_welfare {
    position: absolute;
    width: 373px;
    border: 1px solid #e4e4e4;
    top: 441px;
    right: 261px;
    .welfare_item {
      .hr {
        width: 95%;
        margin-left: 2.5%;
        border: 1px solid #e4e4e4;
      }
      .item_title {
        height: 18px;
        line-height: 18px;
        margin: 30px 17px 16px 17px;
        padding-left: 10px;
        font-size: 20px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
        border-left: 4px solid #dd1619;
      }
      .item_content {
        margin: 0 46px 19px 14px;
        font-size: 16px;
        line-height: 30px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
      }
    }
    .walfare_head {
      background: url("@/assets/home/walfare_head.png");
      background-size: 100% 100%;
      width: calc(100% - 23px);
      border: 1px solid #e4e4e4;
      height: 79px;
      line-height: 79px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #ffffff;
      padding-left: 21px;
    }
  }
  .join_body {
    width: 978px;
    margin: 33px 0 0 259px;
    .body_title {
      font-size: 20px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
      margin: 20px 0;
    }
    .body_job {
      font-size: 16px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
    .email {
      margin-top: 30px;
    }
    .item_head {
      display: flex;
      width: 100%;
      .head_name {
        margin-left: 29px;
        width: 45%;
        font-size: 20px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #dd1619;
      }
      .head_dept {
        width: 23%;
        font-size: 18px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
      }
      .head_address {
        font-size: 18px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
      }
    }
    ::v-deep .el-collapse-item {
      margin-bottom: 17px;
    }
    ::v-deep .el-collapse-item__arrow {
      margin: 0 20px 0 auto;
    }
    ::v-deep .el-collapse-item__wrap {
      background-color: #f7f8fc;
      border-top: 1px dashed #d1d1d1;
      padding: 20px 30px;
    }
    ::v-deep .el-collapse {
      border-top: 0px solid;
      border-bottom: 0px solid;
    }
    ::v-deep .el-collapse-item__header {
      height: 89px;
      line-height: 89px;
      background-color: #f7f8fc;
      border: 0 solid;
    }
  }
  .join_menu {
    width: 978px;
    border-bottom: 1px solid #e4e4e4;
    margin: 43px 0 0 259px;
    display: flex;
    .menu_item {
      margin-right: 37px;
      padding: 11px 30px;
      font-size: 20px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
      cursor: pointer;
    }
  }
  .join_banner {
    img {
      width: 100%;
      height: 408px;
    }
  }
}
</style>
