//更换合作伙伴
<template>
  <div class="addDialog">
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="58%"
      :close-on-click-modal="false"
    >
      <section class="main-form">
        <el-form
          ref="fbForm"
          :model="dialogObj"
          :rules="rule"
          label-width="130px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="上传资源文件：" prop="picture">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :http-request="imgUpload"
                  :show-file-list="false"
                >
                  <img
                    v-if="dialogObj.picture"
                    :src="$imgUrl + dialogObj.picture"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit('fbForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      url: {
        add: "/partners/insert",
        edit: "/partners/update",
      },
      rule: {
        picture: {
          required: true,
          message: "请上传资源图片",
          trigger: "change",
        },
      },
      dialogObj: {
        picture: "",
      },
      title: "",
      /* 图片列表 */
      img: "",
    };
  },

  methods: {
    imgUpload(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.$set(this.dialogObj, this.dialogObj.picture, res.data);
          this.dialogObj.picture = res.data;
        }
      });
    },
    init(item, title) {
      console.log("item", item);
      this.title = title;
      if (item) {
        this.dialogObj = item;
      } else {
        this.dialogObj = {};
      }
      this.show = true;
    },
    /* 提交*/
    submit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogObj.id) {
            url = this.url.edit;
          } else {
            url = this.url.add;
          }
          this.$http.postRequest(url, this.dialogObj).then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$emit("getImgList");
              this.show = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-uploader {
  width: 321px;
  height: 191px;
  border: 1px dashed #e6e4e4;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 321px;
  height: 191px;
  line-height: 191px;
  text-align: center;
}
.avatar {
  width: 321px;
  height: 191px;
  display: block;
}
</style>
