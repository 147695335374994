<template>
  <div class="index-main">
    <div class="index-top">
      <div class="top-logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="top_title">
        欢迎，{{
          $store.state.userInfo.username
        }}进入宁夏恒力科技官网后台管理系统
      </div>
      <div class="top-avatar">
        <img src="@/assets/avatar.jpg" alt="" />
      </div>
      <div class="top-user-name">
        上午好： {{ $store.state.userInfo.username }}
      </div>
      <div class="top-close" @click="loginOut">
        <span class="el-icon-switch-button"></span>退出登录
      </div>
    </div>
    <div class="index-body">
      <div class="body-menu">
        <el-menu
          class="el-menu-vertical-demo"
          v-for="(item, index) in menuList"
          :default-active="menuActive"
          :key="index"
          :router="true"
          @select="handleMenu"
        >
          <el-submenu :index="index + '1'" v-if="item.children">
            <template slot="title">
              <span>{{ item.label }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(child, x) in item.children"
                :index="child.path"
                :key="x"
                >{{ child.label }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item :index="item.path" v-else>
            <template slot="title">
              <span>{{ item.label }}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="body-center">
        <menuTabs @getNewMenu="getNewMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs/crumbs.vue";
import menuTabs from "@/components/menuTabs/menuTabs.vue";
export default {
  components: { crumbs, menuTabs },
  data() {
    return {
      crumbsRouter: "",
      menuActive: "",
      menuList: [
        {
          label: "轮播图管理",
          path: "/backManage/bannerMan",
        },
        {
          label: "解决方案",
          path: "/backManage/solution",
        },
        {
          label: "解决方案场景",
          path: "/backManage/solutionSecne",
        },
        {
          label: "解决方案类别",
          path: "/backManage/solutionType",
        },
        {
          label: "产品中心",
          path: "/backManage/productCenter",
        },
        {
          label: "资讯动态管理",
          path: "/backManage/consultationMan",
        },
        {
          label: "关于我们",
          path: "/backManage/aboutUs",
        },
        {
          label: "诚聘英才",
          path: "/backManage/joinUs",
        },
        {
          label: "合作伙伴",
          path: "/backManage/partners",
        },
        {
          label: "友情链接",
          path: "/backManage/friendlyLinks",
        },
      ],
    };
  },
  mounted() {
    this.menuActive = this.$route.path;
    this.getUserMenu();
  },
  methods: {
    getNewMenu(path) {
      console.log("path", path);
      this.menuActive = path;
    },
    /* 退出登录 */
    loginOut() {
      this.$confirm("退出登陆将清空您的用户信息,确定退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.commit("removeUserInof");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          this.$router.push({
            path: "/login",
          });
        })
        .catch(() => {});
    },
    /* 切换菜单 */
    handleMenu(index) {
      this.menuActive = index;
    },
    /* 获取菜单 */
    getUserMenu() {
      // this.$http.getRequest("/menu/web/auth/menu?model=1", {}).then((res) => {
      //   if (res.code === 200) {
      //     this.menuList = this.menuList.concat(res.data);
      //   }
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.index-main {
  width: 100vw;
  .el-menu {
    border-right: 0px solid #000;
  }
  ::v-deep .el-menu-item {
    text-align: center;
  }
  .index-body {
    display: flex;
    .body-center {
      width: calc(100% - 250px);
      height: 100vh;
      .center-body-crumbs {
        height: 40px;
      }
    }
    .body-menu {
      width: 250px;
      border-right: 1px solid #eee;
      height: 100vh;
    }
  }
  .index-top {
    height: 60px;
    line-height: 60px;
    background-color: #1890ff;
    width: 100%;
    display: flex;
    color: #fff;
    font-size: 14px;
    .top-user-name {
      margin-right: 30px;
    }
    .top-close {
      cursor: pointer;
      margin-right: 30px;
    }
    .top-avatar {
      margin-left: auto;
      margin-right: 30px;
      img {
        height: 40px;
        width: 40px;
        margin: 10px 10%;
        border-radius: 100%;
      }
    }
    .top_title {
      font-size: 14px;
    }
    .top-logo {
      width: 250px;
      img {
        height: 40px;
        width: 80%;
        margin: 10px 10%;
      }
    }
  }
}
</style>
