<template>
  <div class="about_main">
    <div class="about_banner">
      <img :src="$imgUrl + bannerUrl" alt="" />
    </div>
    <div class="about_menu">
      <a
        class="item"
        v-for="(item, index) in topMenu"
        :key="index"
        :style="{ color: topMenuI === index ? '#DD1619' : '' }"
        @click="handleAboutMenu(item, index)"
      >
        {{ item.title }}
      </a>
    </div>
    <!-- 企业介绍 -->
    <div class="about_introduce" id="about_introduce">
      <div class="int_left">
        <img
          v-if="companyIntroduce.resourceFile.resourceType === 2"
          :src="$imgUrl + companyIntroduce.resourceFile.url"
          alt=""
        />
        <video
          v-if="companyIntroduce.resourceFile.resourceType === 1"
          :src="$imgUrl + companyIntroduce.resourceFile.url"
          autoplay
          loop
          controls
        ></video>
      </div>
      <div class="int_right">
        <div class="right_title">{{ companyIntroduce.title }}</div>
        <div class="right_content">{{ companyIntroduce.enterpriseInfo }}</div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="about_culture" id="about_culture">
      <div class="cu_title">企业文化</div>
      <div class="cu_desc">
        恒力电控将继续秉承“诚信、高效、创新、协作”的理念，不断推进技术创新和服务升级，为客户提供更有针对性的软件服务和出色的解决方案。
      </div>
      <div class="culture_content">
        <div
          class="item"
          v-for="(item, index) in companyCulture"
          :style="{
            width: 1455 / companyCulture.length + 'px',
            backgroundImage: 'url(' + $imgUrl + item.resourceFile + ')',
          }"
          :key="index + 'c'"
        >
          <div class="item_title">{{ item.title }}</div>
          <div class="item_content">{{ item.enterpriseCulture }}</div>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="about_course" id="about_course">
      <div class="course_title">发展历程</div>
      <div class="course_desc">
        恒力科技历经9年的磨砺发展，旗下宁夏恒力祥自控工程科技服务有限公司、中卫市恒力电控科技服务有限公司、中卫市鸿科职业技术学校。
      </div>
      <div class="course_history">
        <div class="history_left_btn">
          <img
            src="@/assets/home/history_left.png"
            alt=""
            @click="selectHistory('left')"
          />
        </div>
        <div class="history_content">
          <div class="hr"></div>
          <div class="content_item">
            <template
              v-for="(item, index) in developmentHistory.slice(
                history.start,
                history.end
              )"
            >
              <div
                class="item_top"
                :key="index"
                v-if="index % 2 === 0"
                @mouseover="onMouseOver(index)"
                @mouseout="onMouseOut(index)"
              >
                <div class="item_bottom_heng"></div>
                <div class="item_top_con">
                  {{ item.developContent }}
                </div>
                <div
                  class="item_bottom_con"
                  :style="{
                    backgroundColor: hoverHistory === index ? '#DD1619' : '',
                    color: hoverHistory === index ? '#fff' : '',
                  }"
                >
                  {{ item.developYear }}
                </div>
                <div
                  class="item_bottom_qiu"
                  :style="{
                    backgroundColor: hoverHistory === index ? '#DD1619' : '',
                  }"
                ></div>
              </div>
              <div
                class="item_bottom"
                :key="index"
                v-else
                @mouseover="onMouseOver(index)"
                @mouseout="onMouseOut(index)"
              >
                <div class="item_bottom_heng"></div>
                <div
                  class="item_bottom_qiu"
                  :style="{
                    backgroundColor: hoverHistory === index ? '#DD1619' : '',
                  }"
                ></div>
                <div
                  class="item_bottom_con"
                  :style="{
                    backgroundColor: hoverHistory === index ? '#DD1619' : '',
                    color: hoverHistory === index ? '#fff' : '',
                  }"
                >
                  {{ item.developYear }}
                </div>
                <div class="item_top_con">
                  {{ item.developContent }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="history_right_btn">
          <img
            src="@/assets/home/history_right.png"
            alt=""
            @click="selectHistory('right')"
          />
        </div>
      </div>
    </div>
    <!-- 技术实力 -->
    <div class="about_strength" id="about_strength">
      <div class="str_title">技术实力</div>
      <div class="str_desc">
        取得了10项实用新型专利，46项计算机软件著作权证书，同时通过了质量、环境、职业健康安全管理三大体系认证。
      </div>
      <div class="str_img">
        <el-carousel :interval="400000" type="card" height="433px">
          <el-carousel-item v-for="item in companyStrength" :key="item">
            <img :src="$imgUrl + item" alt="" class="str_img_item" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 企业荣誉 -->
    <div class="about_honor" id="about_honor">
      <div class="honor_title">企业荣誉</div>
      <div class="honor_desc">以技术为先，打造领先行业的技术优势。</div>
      <div class="honor_img">
        <div
          class="img_item"
          v-for="item in companyHonor.slice(0, 6)"
          :key="item.id"
        >
          <div class="img_item_img">
            <img :src="$imgUrl + item.img.url" alt="" />
          </div>
          <div class="img_desc">{{ item.img.desc }}</div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="about_contact_us" id="about_contact_us">
      <div class="concat_title">联系我们</div>
      <div class="concat_content">
        您使用放心，我们努力用心，以周到的服务,赢得广大客户的信任与厚爱。
      </div>
      <div class="bot_concat_us">
        <div class="us_top_title">
          <div class="top_item">服务热线</div>
          <div class="top_item">邮箱</div>
          <div class="top_item">公司地址</div>
        </div>
        <div class="us_top_title">
          <div class="top_item">400-452-1524</div>
          <div class="top_item">521586489@163.com</div>
          <div class="top_item" style="width: 600px">
            宁夏回族自治区银川市西夏区中关村创新中心20楼
          </div>
        </div>
      </div>
    </div>
    <div class="about_img_list">
      <div
        v-for="(item, index) in photoWallList"
        :key="index + 'b'"
        :class="`item${index + 1}`"
        style="overflow: hidden"
      >
        <img
          :src="$imgUrl + item"
          alt=""
          style="
            width: 100%;
            height: 100%;
            transition: transform 0.5s;
            cursor: pointer;
          "
        />
      </div>
      <div
        v-for="(item, index) in 4"
        :key="index + 'a'"
        :class="`item_${index + 1}`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataQuery:{},
      history: {
        start: 0,
        end: 4,
        length: null,
      },
      hoverHistory: null,
      topMenuI: 0,
      topMenu: [
        { title: "企业介绍", id: "about_introduce" },
        { title: "企业文化", id: "about_culture" },
        { title: "发展历程", id: "about_course" },
        { title: "技术实力", id: "about_strength" },
        { title: "企业荣誉", id: "about_honor" },
        { title: "联系我们", id: "about_contact_us" },
      ],
      url: {
        data: "/indexWebsite/aboutUs",
        banner: "/indexWebsite/getIndexBannerByType",
      },
      bannerUrl: "",
      /* 企业介绍 */
      companyIntroduce: {
        title: "",
        resourceFile: {
          resourceType: "",
        },
      },
      /* 企业文化 */
      companyCulture: [],
      /* 发展历程 */
      developmentHistory: [],
      /* 技术实力 */
      companyStrength: [],
      /* 企业荣誉 */
      companyHonor: [],
      /* 招聘墙 */
      photoWallList: [],
    };
  },
  mounted() {
    this.dataQuery = this.$route.query;
    if(this.dataQuery){
      const a = document.createElement("a");
      a.href = "#" + this.dataQuery.id;
      document.body.appendChild(a);
      a.click();
    }
       console.log("this.dataQuery", this.dataQuery.id);
    this.getData();
    this.getBanner();
  },
  methods: {
    onMouseOver(i) {
      this.hoverHistory = i;
    },
    onMouseOut(i) {
      this.hoverHistory = null;
    },
    selectHistory(type) {
      switch (type) {
        case "left":
          console.log("type", this.history);
          if (this.history.start === 0) {
            return;
          } else {
            this.history.start--;
            this.history.end--;
          }
          break;
        case "right":
          console.log("type", this.history);
          if (this.history.end === this.history.length) {
            return;
          } else {
            this.history.start++;
            this.history.end++;
          }
          break;
        default:
          break;
      }
    },
    handleAboutMenu(item, i) {
      console.log("item",item)
      this.topMenuI = i;
      const a = document.createElement("a");
      a.href = "#" + item.id;
      document.body.appendChild(a);
      a.click();
         console.log("a",a)
    },
    getData() {
      this.$http.getRequest(this.url.data).then((res) => {
        if (res.code === 200) {
          console.log("res.code", res);
          /* 企业介绍 */
          this.companyIntroduce = res.data.usEnterpriseInfos[0];
          this.companyIntroduce.resourceFile = JSON.parse(
            res.data.usEnterpriseInfos[0].resourceFile
          );
          /* 企业文化 */
          this.companyCulture = res.data.aboutUsEnterpriseCultures;
          /* 技术实力 */
          this.companyStrength = JSON.parse(res.data.usTechnologies[0].picture);
          /* 企业荣誉 */
          res.data.aboutUsCorporateHonors.forEach((e) => {
            e.img = JSON.parse(e.picture);
          });
          this.companyHonor = res.data.aboutUsCorporateHonors;
          /* 照片墙 */
          this.photoWallList = Object.values(
            JSON.parse(res.data.aboutUsPhotoWalls[0].picture)
          );
          /* 发展历程 */
          this.history.length = res.data.develops.length;
          this.developmentHistory = res.data.develops.reverse();
        }
      });
    },
    getBanner() {
      this.$http.getRequest(this.url.banner, { bannerType: 7 }).then((res) => {
        if (res.code === 200) {
          this.bannerUrl = res.data.fileUrl;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.about_main {
  width: 100vw;
  .about_img_list {
    margin-top: 110px;
    position: relative;
    margin-bottom: 736px;
    img:hover {
      transform: scale(1.2);
    }
    .item_1 {
      width: 372.1px;
      height: 60.2px;
      position: absolute;
      top: 244px;
      left: 511px;
      background-color: rgba(4, 102, 254, 0.2);
    }
    .item_2 {
      width: 283.2px;
      height: 62px;
      position: absolute;
      top: 556px;
      left: 220px;
      background-color: rgba(4, 102, 254, 0.2);
    }
    .item_3 {
      width: 133.6px;
      height: 145.8px;
      position: absolute;
      top: 472px;
      left: 974px;
      background-color: rgba(4, 102, 254, 0.2);
    }
    .item_4 {
      width: 123.6px;
      height: 145.8px;
      position: absolute;
      top: 472px;
      left: 1387px;
      background-color: rgba(4, 102, 254, 0.2);
    }
    .item1 {
      width: 490.7px;
      height: 335.8px;
      position: absolute;
      top: 15px;
      left: 12px;
    }
    .item2 {
      width: 372.1px;
      height: 221.6px;
      position: absolute;
      top: 15px;
      left: 511px;
    }
    .item3 {
      width: 216.4px;
      height: 141.8px;
      position: absolute;
      top: 15px;
      left: 890px;
    }
    .item4 {
      width: 216.4px;
      height: 141.8px;
      position: absolute;
      top: 15px;
      left: 1114px;
    }
    .item5 {
      width: 372.1px;
      height: 221.6px;
      position: absolute;
      top: 15px;
      left: 1338px;
    }
    .item6 {
      width: 193.6px;
      height: 141.8px;
      position: absolute;
      top: 15px;
      left: 1719px;
    }
    .item7 {
      width: 200.4px;
      height: 126.1px;
      position: absolute;
      top: 359px;
      left: 12px;
    }

    .item8 {
      width: 283.2px;
      height: 198px;
      position: absolute;
      top: 358px;
      left: 220px;
    }
    .item9 {
      width: 372.1px;
      height: 151.6px;
      position: absolute;
      top: 312px;
      left: 511px;
    }
    .item10 {
      width: 440.4px;
      height: 301.4px;
      position: absolute;
      top: 163px;
      left: 890px;
    }
    .item11 {
      width: 372.1px;
      height: 221.6px;
      position: absolute;
      top: 242px;
      left: 1338px;
    }
    .item12 {
      width: 193.6px;
      height: 145.8px;
      position: absolute;
      top: 167px;
      left: 1719px;
    }
    .item13 {
      width: 193.6px;
      height: 141.8px;
      position: absolute;
      top: 322px;
      left: 1719px;
    }
    .item14 {
      width: 200.7px;
      height: 126.2px;
      position: absolute;
      top: 492px;
      left: 12px;
    }
    .item15 {
      width: 193.6px;
      height: 145.2px;
      position: absolute;
      top: 473px;
      left: 511px;
    }
    .item16 {
      width: 253.6px;
      height: 145.8px;
      position: absolute;
      top: 472px;
      left: 712px;
    }
    .item17 {
      width: 263.6px;
      height: 145.8px;
      position: absolute;
      top: 472px;
      left: 1116px;
    }
    .item18 {
      width: 395.3px;
      height: 145.8px;
      position: absolute;
      top: 472px;
      left: 1518px;
    }
  }
  .about_contact_us {
    height: 207px;
    width: 100%;
    .bot_concat_us {
      margin-top: 60px;
      width: 100%;
      height: 177px;
      background: url("@/assets/home/about_contact_us.png");
      background-size: 100% 100%;
      .us_top_title {
        margin: 0 0 0 259px;
        display: flex;
        height: 40px;
        line-height: 137px;
        .top_item {
          width: 300px;
          color: #fff;
        }
      }
    }
    .concat_title {
      margin: 78px 0 0 261px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
    .concat_content {
      margin: 18px 0 0 261px;
      font-size: 18px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
  }
  .about_honor {
    width: 100%;
    height: 526px;
    background: url("@/assets/home/about_honor.png");
    background-size: 100% 100%;
    .honor_img {
      margin: 21px 0 0 62px;
      width: 100%;
      display: flex;
      .img_item {
        height: 160px;
        width: 286px;
        margin-right: 22px;
        .img_desc {
          height: 80px;
          line-height: 80px;
          text-align: center;
          width: 100%;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
        .img_item_img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: transform 0.5s;
            cursor: pointer;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    .honor_title {
      padding: 63px 0 0 261px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
    .honor_desc {
      margin: 25px 0 0 263px;
      font-size: 18px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
  }
  .about_strength {
    width: 100%;
    height: 768px;
    border: 1px solid #eee;
    .str_img {
      width: 1478px;
      height: 453px;
      margin: 82px 0 0 228px;
      ::v-deep .el-carousel__item {
        transform: translateX(75%) scale(1);
      }
      .str_img_item {
        margin-left: 27%;
        width: 350px;
        height: 100%;
      }
    }
    .str_title {
      margin: 51px 0 0 260px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
    .str_desc {
      margin: 18px 0 0 261px;
      font-size: 18px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
  }
  .about_course {
    width: 100%;
    height: 682px;
    background: url("@/assets/home/about_course.png");
    background-size: 100% 100%;
    .course_history {
      margin-top: 80px;
      width: 1402px;
      min-height: 370px;
      margin-left: 259px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .content_item {
        position: absolute;
        top: 0;
        left: calc(50px + 2%);
        display: flex;
        width: 100%;
        .item_bottom {
          height: 155px;
          width: calc(100% / 4 - 50px);
          margin-left: 20px;
          margin-top: 215px;
          position: relative;
          .item_bottom_heng {
            position: absolute;
            width: 23px;
            height: 2px;
            background: #fff;
            bottom: 8px;
            left: 18px;
          }
          .item_bottom_qiu {
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: -40px;
            left: 47%;
          }
          .item_bottom_con {
            height: 40px;
            width: 300px;
            background: #ffffff;
            border-radius: 12px 12px 0px 0px;
            font-size: 24px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            text-align: center;
            line-height: 40px;
          }
          .item_bottom_con::before {
            content: "";
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent white transparent;
            /* 其他样式 */
          }
          .item_top_con {
            width: calc(300px - 50px);
            height: calc(115px - 39px);
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0px 0px 12px 12px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #ffffff;
            line-height: 25px;
            padding: 14px 35px 25px 15px;
          }
        }
        .item_top {
          height: 155px;
          width: calc(100% / 4 - 50px);
          position: relative;
          .item_bottom_heng {
            position: absolute;
            width: 23px;
            height: 2px;
            background: #fff;
            top: 8px;
            left: 18px;
          }
          .item_bottom_qiu {
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            bottom: -40px;
            left: 47%;
          }
          .item_bottom_con {
            height: 40px;
            width: 300px;
            background: #ffffff;
            border-radius: 0px 0px 12px 12px;
            font-size: 24px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            text-align: center;
            line-height: 40px;
          }
          .item_bottom_con::before {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: white transparent transparent transparent;
            /* 其他样式 */
          }
          .item_top_con {
            width: calc(300px - 50px);
            height: calc(115px - 39px);
            background: rgba(0, 0, 0, 0.5);
            border-radius: 12px 12px 0px 0px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #ffffff;
            line-height: 25px;
            padding: 25px 35px 14px 15px;
          }
        }
      }
      .history_content {
        width: 100%;
        .hr {
          height: 1px;
          background-color: #b4b6b8;
          width: calc(100% - 4%);
          margin-left: 2%;
        }
      }
      .history_right_btn {
        margin-left: auto;
        z-index: 998;
        img {
          width: 50px;
          height: 50px;
          cursor: pointer;
        }
      }
      .history_left_btn {
        img {
          width: 50px;
          height: 50px;
          cursor: pointer;
        }
      }
    }
    .course_title {
      margin-left: 261px;
      padding-top: 70px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #ffffff;
    }
    .course_desc {
      padding: 18px 0 0 261px;
      font-size: 18px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .about_introduce {
    width: 100%;
    height: 569px;
    background: url("@/assets/home/about_introduce.png");
    background-size: 100% 100%;
    display: flex;
    .int_left {
      width: 700px;
      height: 421px;
      border: 1px solid #b4b6b8;
      background-color: #f9fcfe;
      border-radius: 10px;
      margin: 63px 0 0 253px;
      img {
        margin: 25px;
        width: 650px;
        height: 371px;
        border-radius: 10px;
      }
      video {
        margin: 25px;
        width: 650px;
        height: 371px;
        border-radius: 10px;
      }
    }
    .int_right {
      margin-top: -20px;
      .right_title {
        margin: 78px 0 0 31px;
        font-size: 36px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
      }
      .right_content {
        overflow: hidden ;
        height: 355px;
        margin: 10px 261px 0 35px;
        font-size: 17px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #333333;
        line-height: 36px;
      }
    }
  }
  .about_culture {
    width: 100%;
    height: 519px;
    .culture_content {
      margin: 59px 0 0 242px;
      width: 1455px;
      display: flex;
      .item {
        height: 260px;
        background-size: 100% 100%;
        .item_title {
          margin: 56px 0 0 48px;
          font-size: 30px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #dd1619;
        }
        .item_content {
          margin: 56px 0 0 50px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #333333;
        }
      }
    }
    .cu_title {
      margin: 56px 0 0 261px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
    .cu_desc {
      margin: 18px 0 0 261px;
      font-size: 18px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      color: #333333;
    }
  }
  .about_menu {
    width: 100%;
    height: 71px;
    line-height: 71px;
    display: flex;
    padding-left: 281px;
    .item {
      margin-right: 91px;
      font-size: 18px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: normal;
      cursor: pointer;
    }
  }
  .about_banner {
    img {
      width: 100%;
      height: 408px;
    }
  }
}
</style>
