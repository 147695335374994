/* 
    公共方法
*/

// 获取当前时间
let currTime = () => {
  var myDate = new Date(); //创建Date对象
  var Y = myDate.getFullYear(); //获取当前完整年份
  var M = myDate.getMonth() + 1; //获取当前月份
  var D = myDate.getDate(); //获取当前日1-31
  var H = myDate.getHours(); //获取当前小时
  var i = myDate.getMinutes(); //获取当前分钟
  var s = myDate.getSeconds(); //获取当前秒数
  // 月份不足10补0
  if (M < 10) {
    M = "0" + M;
  }
  // 日不足10补0
  if (D < 10) {
    D = "0" + D;
  }
  // 小时不足10补0
  if (H < 10) {
    H = "0" + H;
  }
  // 分钟不足10补0
  if (i < 10) {
    i = "0" + i;
  }
  // 秒数不足10补0
  if (s < 10) {
    s = "0" + s;
  }
  // 拼接日期分隔符根据自己的需要来修改
  let nowDate = Y + "-" + M + "-" + D + " " + H + ":" + i + ":" + s;
  return nowDate;
};

// 获取当前班次
let currClasses = () => {
  var myDate = new Date(); //创建Date对象
  var H = myDate.getHours(); //获取当前小时
  // 拼接日期分隔符根据自己的需要来修改
  console.log("当前小时", H);
  if (H >= 0 && H < 9) {
    return "夜班";
  } else if (H >= 9 && H < 15) {
    return "白班";
  } else {
    return "中班";
  }
};

/* 获取当前年份 */
let currYear = () => {
  var myDate = new Date(); //创建Date对象
  var Y = myDate.getFullYear(); //获取当前完整年份
  return Y;
};

/* 获取当前月/日 */
let currMonthOrDay = () => {
  var myDate = new Date(); //创建Date对象
  var M = myDate.getMonth() + 1; //获取当前月份
  var D = myDate.getDate(); //获取当前日1-31
  // 月份不足10补0
  if (M < 10) {
    M = "0" + M;
  }
  // 日不足10补0
  if (D < 10) {
    D = "0" + D;
  }
  return `${M}/${D}`;
};

// 获取当前月
let currMonth = () => {
  var myDate = new Date(); //创建Date对象
  var Y = myDate.getFullYear(); //获取当前完整年份
  var M = myDate.getMonth() + 1; //获取当前月份
  var D = myDate.getDate(); //获取当前日1-31
  var H = myDate.getHours(); //获取当前小时
  var i = myDate.getMinutes(); //获取当前分钟
  var s = myDate.getSeconds(); //获取当前秒数
  // 月份不足10补0
  if (M < 10) {
    M = "0" + M;
  }
  // 日不足10补0
  if (D < 10) {
    D = "0" + D;
  }
  // 小时不足10补0
  if (H < 10) {
    H = "0" + H;
  }
  // 分钟不足10补0
  if (i < 10) {
    i = "0" + i;
  }
  // 秒数不足10补0
  if (s < 10) {
    s = "0" + s;
  }
  // 拼接日期分隔符根据自己的需要来修改
  let nowDate = Y + "-" + M;
  return nowDate;
};

// 转换中国标准时间(日期)
let formatDate = (d) => {
  var date = new Date(d);
  var YY = date.getFullYear() + "-";
  var MM =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hh =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var mm =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + MM + DD;
};

// 获取当前日
let currDay = () => {
  var myDate = new Date(); //创建Date对象
  var Y = myDate.getFullYear(); //获取当前完整年份
  var M = myDate.getMonth() + 1; //获取当前月份
  var D = myDate.getDate(); //获取当前日1-31
  var H = myDate.getHours(); //获取当前小时
  var i = myDate.getMinutes(); //获取当前分钟
  var s = myDate.getSeconds(); //获取当前秒数
  // 月份不足10补0
  if (M < 10) {
    M = "0" + M;
  }
  // 日不足10补0
  if (D < 10) {
    D = "0" + D;
  }
  // 小时不足10补0
  if (H < 10) {
    H = "0" + H;
  }
  // 分钟不足10补0
  if (i < 10) {
    i = "0" + i;
  }
  // 秒数不足10补0
  if (s < 10) {
    s = "0" + s;
  }
  // 拼接日期分隔符根据自己的需要来修改
  let nowDate = Y + "-" + M + "-" + D;
  return nowDate;
};

// 导出
let leadingOut = (content, filename) => {
  const blob = new Blob([content], {
    // 下载的文件格式自己在这边更改type的值就好了
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  if ("download" in document.createElement("a")) {
    const elink = document.createElement("a");
    elink.download = filename;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href);
    document.body.removeChild(elink);
  } else {
    navigator.msSaveBlob(blob, filename);
  }
};
export default {
  currTime,
  currDay,
  currClasses,
  currMonth,
  formatDate,
  leadingOut,
  currYear,
  currMonthOrDay,
};
