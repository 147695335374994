<template>
  <div class="body">
    <!-- 头部轮播图 -->
    <div class="head">
      <!-- 轮播图 -->
      <div class="head_banner">
        <img
          v-if="this.indexBanner.resourceType == 2"
          :src="$imgUrl + this.indexBanner.fileUrl"
          alt=""
        />
        <video
          v-if="this.indexBanner.resourceType == 1"
          :src="$imgUrl + this.indexBanner.fileUrl"
          autoplay
          loop
        ></video>
      </div>
    </div>
    <!-- 菜单导航 -->
    <div class="solution_nav">
      <div class="solution_nav_type">
        类型
        <div
          class="solution_nav_type_menu"
          v-for="(item, index) in solutionType"
          :key="index"
          :style="{ color: headMenuIndex === item.id ? '#d81e06' : '' }"
          @click="handleMenuSolution(item, index)"
        >
          {{ item.solutionType }}
        </div>
      </div>
      <div class="solution_nav_scene">
        <div style="flex: 0 0 auto">场景</div>
        <div
          class="solution_nav_type_menu"
          v-for="(item, index) in scene"
          :key="index"
          :style="{ color: SceneMenuIndex === item.screenId ? '#d81e06' : '' }"
          @click="handleMenuScene(item, index)"
        >
          {{ item.solutionScreenName }}
        </div>
      </div>
    </div>

    <!-- 展示内容 -->
    <div class="solution_content">
      <div class="solution_content_left">
        <div
          class="solution_content_left_title"
          v-for="(item, index) in solution"
          :key="index"
        >
          <div class="left" v-if="index % 2 == 0">
            <div class="div">
              <div class="title">
                {{ item.programmeName }}
              </div>
              <div v-html="item.overview" class="content" v-resize-images></div>
              <div class="find" @click="findDetail(item)">查看详情</div>
            </div>
            <div class="left_img">
              <img :src="JSON.parse(item.picture)[0].url" alt="" />
            </div>
          </div>
          <div class="right" v-if="index % 2 !== 0">
            <div class="right_img">
              <img :src="JSON.parse(item.picture)[0].url" alt="" />
            </div>
            <div class="div">
              <div class="title">
                {{ item.programmeName }}
              </div>
              <div v-html="item.overview" class="content" v-resize-images></div>
              <div class="find" @click="findDetail(item)">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="main_table_page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.current"
        :page-sizes="[3, 6, 9]"
        :page-size="pageObj.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageObj.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headMenuIndex: 0,
      SceneMenuIndex: 0,
      pageObj: {
        current: 1,
        size: 3,
        total: 0,
      },
      indexBanner: "",
      solution: [],
      solutionType: [],
      scene: [],
      url: {
        banner: "/indexWebsite/getIndexBannerByType", //banner
        solution: "/indexWebsite/solutionPageList", //解决方案
        solutionType: "/indexWebsite/getSolutionTypeList", //查询行业解决方案类型
        scene: "/indexWebsite/getScreenListByType", //查询行业解决方案场景
      },
    };
  },
  // 富文本的img设置样式无法生效时，使用该方法，要在标签中添加v-resize-images
  directives: {
    resizeImages: {
      inserted(el) {
        const images = el.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
          images[i].style.display = "none"; // 隐藏图片
        }
      },
    },
  },
  computed: {
    solve() {
      console.log('localStorage.getItem("solveId")', this.$store.state.solveId);
      return this.$store.state.solveId;
    },
    solveType() {
      return this.$store.state.solveTypeId;
    },
  },
  watch: {
    solve: {
      handler(newData, oldData) {
        this.updateSceneMenuIndex(newData);
      },
    },
    solveType: {
      handler(newData, oldData) {
        this.updateSolveTypeMenuIndex(newData);
      },
    },
  },
  mounted() {
    this.getIndexBanner();
    this.getSolution(); //行业解决方案
    this.getSolutionType(); //行业解决方案类型和场景
    this.getScene(); //场景
  },

  methods: {
    // 首页目录栏改变的状态
    /* 修改场景id */
    updateSceneMenuIndex(id) {
      this.SceneMenuIndex = id;
      this.getSolution("", id);
    },
    updateSolveTypeMenuIndex(id) {
      this.getScene(id);
      this.headMenuIndex = id;
    },
    findDetail(item) {
      this.$router.push({
        path: "/details",
        query: { item: item },
      });
    },
    // 点击类型场景改变的状态
    handleMenuSolution(item, index) {
      this.getScene(item.id);
      this.headMenuIndex = item.id;
    },

    handleMenuScene(item, index) {
      this.getSolution("", item.screenId);
      this.SceneMenuIndex = item.screenId;
    },
    getIndexBanner() {
      this.$http
        .getRequest(this.url.banner, { bannerType: 3 })
        .then((res) => {
          if (res.code == 200) {
            this.indexBanner = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
    // 查询解决方案
    getSolution(solutionTypeId, solutionScreenId) {
      if (solutionTypeId) {
        this.$http
          .getRequest(
            this.url.solution + "?solutionTypeId=" + solutionTypeId,
            this.pageObj
          )
          .then((res) => {
            if (res.code == 200) {
              this.solution = res.data.records;
              this.pageObj.total = res.data.total;
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {});
      } else if (solutionScreenId) {
        this.$http
          .getRequest(
            this.url.solution + "?solutionScreenId=" + solutionScreenId,
            this.pageObj
          )
          .then((res) => {
            if (res.code == 200) {
              this.solution = res.data.records;
              this.pageObj.total = res.data.total;
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {});
      } else {
        this.$http
          .getRequest(this.url.solution, this.pageObj)
          .then((res) => {
            if (res.code == 200) {
              this.solution = res.data.records;
              this.getScene();
              this.pageObj.total = res.data.total;
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {});
      }
    },
    // 类型
    getSolutionType() {
      this.$http
        .getRequest(this.url.solutionType)
        .then((res) => {
          if (res.code == 200) {
            this.solutionType = [
              {
                content: "",
                id: "",
                picture: "",
                solutionType: "全部",
              },
            ];
            this.solutionType = this.solutionType.concat(res.data);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
    // 场景
    getScene(solutionTypeId) {
      if (solutionTypeId) {
        this.$http
          .getRequest(this.url.scene + "?solutionTypeId=" + solutionTypeId)
          .then((res) => {
            if (res.code == 200) {
              this.scene = [
                {
                  id: "",
                  solutionScreenName: "全部",
                },
              ];
              this.scene = this.scene.concat(res.data);
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {});
      } else {
        this.$http
          .getRequest(this.url.scene)
          .then((res) => {
            if (res.code == 200) {
              this.scene = [
                {
                  id: "",
                  solutionScreenName: "全部",
                },
              ];
              this.scene = this.scene.concat(res.data);
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {});
      }
    },

    // 分页
    handleCurrentChange(val) {
      this.pageObj.current = val;
      this.getSolution();
    },
    //调整分页
    handleSizeChange(val) {
      this.pageObj.size = val;
      this.getSolution();
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  margin-bottom: 20px;
}
.head {
  height: 408px;
  margin: auto;
  .head_banner {
    height: 408px;
    img {
      width: 100%;
      height: 408px;
    }
    video {
      width: 100%;
      height: 408px;
    }
  }
}

.solution_nav {
  border: 1px solid #e4e4e4;
  .solution_nav_type {
    display: flex;
    margin-left: 260px;
    width: 1400px;
    height: 55px;
    padding-top: 50px;
    span {
      height: 32px;
      margin-left: 57px;
      font-family: AlibabaPuHuiTi_2_45_Light;
      background-color: #fde8e8;
    }
    .solution_nav_type_menu {
      height: 32px;
      margin-left: 57px;
      font-family: AlibabaPuHuiTi_2_45_Light;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .solution_nav_scene {
    display: flex;
    flex-direction: row;
    margin-left: 260px;
    width: 1400px;
    height: 55px;
    padding-top: 41px;
    margin-bottom: 64px;
    overflow-y: hidden;
    span {
      height: 32px;
      margin-left: 57px;
      font-family: AlibabaPuHuiTi_2_45_Light;
      background-color: #fde8e8;
    }
    .solution_nav_type_menu {
      flex: 0 0 auto;
      height: 32px;
      margin-left: 57px;
      font-family: AlibabaPuHuiTi_2_45_Light;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.solution_content {
  width: calc(100vw - 259px -262px);
  .solution_content_left {
    width: 1399px;

    .solution_content_left_title {
      margin-top: 68px;

      .left {
        display: flex;
        width: 1920px;
        height: 560px;
        padding-left: 260px;
        .div {
          width: 699px;
          height: 379px;
          .find {
            width: 113px;
            height: 33px;
            border: 1px solid #dd1619;
            border-radius: 2px;
            margin-right: 500px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #dd1619;
            text-align: center;
            line-height: 33px;
            cursor: pointer;
          }

          .title {
            font-size: 36px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            margin-top: 83px;
          }

          .content {
            height: 320px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            line-height: 32px;
            margin-top: 20px;
            margin-bottom: 20px;
            img {
              max-width: 200px; /* 设置图片最大宽度 */
              max-height: 200px; /* 设置图片最大高度 */
            }
          }
        }
        .left_img {
          width: 658px;
          height: 379px;
          margin-left: 45px;
          margin-top: 94px;
          overflow: hidden;
          img {
            width: 658px;
            height: 379px;
            border-radius: 8px;
            transition: transform 0.5s;
            cursor: pointer;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }

      .right {
        display: flex;
        width: 1920px;
        height: 560px;
        background: #f4f6f8;
        padding-left: 260px;
        .div {
          width: 699px;
          height: 379px;
          .find {
            width: 113px;
            height: 33px;
            border: 1px solid #dd1619;
            border-radius: 2px;
            margin-left: 43px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #dd1619;
            text-align: center;
            line-height: 33px;
            cursor: pointer;
          }
          .title {
            font-size: 36px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            margin-left: 44px;
            margin-top: 107px;
          }
          .content {
            height: 320px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 2;
            font-weight: normal;
            color: #333333;
            line-height: 32px;
            margin-left: 44px;
            margin-top: 20px;
            margin-bottom: 20px;
            .content img {
              max-width: 200px; /* 设置图片最大宽度 */
              max-height: 200px; /* 设置图片最大高度 */
            }
          }
        }
        .right_img {
          width: 658px;
          height: 379px;
          margin-top: 94px;
          overflow: hidden;
          img {
            width: 658px;
            height: 379px;
            border-radius: 8px;
            transition: transform 0.5s;
            cursor: pointer;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
.main_table_page {
  margin-top: 20px;
  margin-left: 685px;
}
</style>
