<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <el-form
        :model="model"
        :rules="rule"
        ref="registerRef"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="类别名称" prop="solutionType">
              <el-input
                v-model="model.solutionType"
                placeholder="请输入类别名称"
                style="width: 90%; margin-left: 14px"
              ></el-input>
            </el-form-item>
            <el-form-item label="内容描述" prop="content">
              <el-input
                type="textarea"
                :rows="2"
                v-model="model.content"
                placeholder="请输入内容描述"
                style="width: 90%; margin-left: 14px"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源图片" prop="picture">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :http-request="filesUpload"
              >
                <img
                  v-if="model.picture"
                  :src="$imgUrl + model.picture"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('registerRef')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "menuSave",
  data() {
    return {
      model: {},
      dialogVisible: false,
      url: {
        edit: "/solutionType/update",
        add: "/solutionType/insert",
      },
      title: "",
      rule: {
        solutionType: {
          required: true,
          message: "请输入类别名称",
          trigger: "blur",
        },
        content: {
          required: true,
          message: "请输入内容描述",
          trigger: "blur",
        },
        picture: {
          required: true,
          message: "请上传资源图片",
          trigger: "blur",
        },
      },
    };
  },
  mounted() {},
  methods: {
    filesUpload(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$config.fileUpload(formData).then((res) => {
        if (res.code === 200) {
          this.$set(this.model, this.model.picture, res.data);
          this.model.picture = res.data;
        }
      });
    },
    init(record, title) {
      this.title = title;
      if (record) {
        this.model = record;
      } else {
        this.model = {};
        this.fileList = [];
      }
      this.dialogVisible = true;
    },
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let httpurl = "";
          if (!this.model.id) {
            httpurl += this.url.add;
          } else {
            httpurl += this.url.edit;
          }
          this.$http.postRequest(httpurl, this.model).then((res) => {
            if (res.code == 200) {
              that.$message.success("操作成功");
              that.$emit("loadData");
              this.dialogVisible = false;
            } else {
              that.$message.warning("操作失败");
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ql-editor {
  line-height: 1.5;
}
.form-content {
  height: 400px;
}
.solution-type {
  display: flex;
  .item {
    padding: 0 10px;
    border: 1px solid #eee;
    margin-left: 14px;
    border-radius: 4px;
  }
  cursor: pointer;
}
.avatar-uploader {
  width: 200px;
  height: 100px;
  border: 1px dashed #e6e4e4;
  top: 400px;
  left: 1305px;
  margin-left: 15px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 100px;
  display: block;
}
</style>
