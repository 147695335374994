import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";//该插件是永久化存储vuex

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    persistedState({
      reducer(val) {
        return {
          userInfo: val.userInfo,
          psdDInfo: val.psdDInfo,
          dictData: val.dictData,
        };
      },
    }),
  ],
  // 初始化vuex参数
  state: {
    userInfo: {},
    psdDInfo: {},
    stoveNum: null,
    allStoveNum: null,
    dictData: [],
    solveId: null,
    solveTypeId: null,
  },
  getters: {},
  // 对定义参数进行操作
  mutations: {
    /* 保存解决方案id */
    solveIdSub(state, solveId) {
      state.solveId = solveId;
      console.log('====', state.solveId)
    },
    // 保存解决方案类型ID
    solveTypeIdSub(state, solveTypeId) {
      state.solveTypeId = solveTypeId;
    },
    // 保存用户信息
    saveUserInfo(state, userData) {
      state.userInfo = userData;
    },
    // 清除有用户信息
    removeUserInof(state) {
      state.userInfo = {};
    },
    // 记住密码
    savePsdInfo(state, psdData) {
      state.psdDInfo = psdData;
    },
    // 未开启记住密码
    removePsdInfo(state) {
      state.psdDInfo = {};
    },
    // 保存炉号
    saveStoveNumber(state, num) {
      state.stoveNum = num;
    },
    // 保存炉号
    saveAllStoveNum(state, num) {
      state.allStoveNum = num;
      localStorage.getItem("info");
      // localStorage.setItem("info", info);
    },
    // 保存字典
    saveDict(state, data) {
      state.dictData = data;
    },
  },
  actions: {},
  modules: {},
});
