<template>
  <div class="login_main">
    <div class="login-title">欢迎登录恒力科技官网后台管理系统</div>
    <div class="login-demo">
      <div class="login-wrap">
        <div class="title-flex">
          <div class="title">
            <span>用户登录</span>
          </div>
          <!-- <div class="littleico">用户登录</div> -->
        </div>
        <!-- 登录框 -->
        <div class="login-form">
          <el-form
            ref="loginForm"
            :rules="formRules"
            :model="user"
            status-icon
            label-position="top"
            label-width="70px"
          >
            <el-form-item prop="account" label="账号" class="ico-login">
              <el-input
                v-model="user.account"
                placeholder="请输入账号"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码" class="ico-login">
              <el-input
                v-model="user.password"
                show-password
                placeholder="password"
                prefix-icon="el-icon-lock"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="login-btn" @click="doLogin('loginForm')">登录</div>
            </el-form-item>
            <!-- <div class="remember-box">
              <el-checkbox v-model="remember" @change="rememberFuc"
                >记住密码</el-checkbox
              >
            </div> -->
          </el-form>
        </div>
      </div>
      <el-dialog
        append-to-body
        :visible.sync="dialogVisible"
        width="340px"
        title="图片验证"
        :close-on-click-modal="false"
      >
        <slideverify
          ref="dialogopen"
          :l="42"
          :r="10"
          :w="catcha.w"
          :h="catcha.h"
          :blocky="catcha.blocky"
          :imgurl="catcha.imgurl"
          :miniimgurl="catcha.miniimgurl"
          :slider-text="catcha.text"
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh"
        ></slideverify>
      </el-dialog>
    </div>
    <!-- <div class="footer">
      <div>联系方式：0975-8346998</div>
      <div>联系地址：青海省果洛藏族自治区玛多县西大街22号</div>
      <div>
        工作时间：周一至周五上午9：00-12：00 下午15：00-18：00（法定节假日除外）
      </div>
    </div> -->
    <div class="index_icon">
      <!-- <img src="../../assets/indexImg/companyLogo.png" /> -->
    </div>
  </div>
</template>
<script>
import slideverify from "@/components/SlideVerify.vue";
import { randomUUID } from "@/utils/time";
import { slideImgCoden, ImgAuth, login } from "../../../request/config";
import { encryptAES, decrypt } from "@/utils/aes";
export default {
  name: "about",
  components: {
    slideverify,
  },
  data() {
    return {
      catcha: {
        blocky: 0,
        imgurl: "",
        miniimgurl: "",
        text: "图片验证",
        h: "200",
        w: "300",
        sh: 50,
        sw: 50,
        modifyImg: "",
        code: "",
      },
      imgurl: "",
      miniimgurl: "",
      activeName: "login",
      uuid: "",
      status: false,
      checked: false,
      disabled: false,
      dialogVisible: false,
      user: {
        // account: "admin",
        // password: "123456",
        account: "",
        password: "",
      },
      // 通过 formRules 属性传入约定的验证规则
      formRules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        publicKey: null,
      },
      redirectUrl: "",
      remember: false,
    };
  },
  created() {
    // redirect是获取哪个系统域名 比如:http://127.0.0.1:8080 方便登录成功以后跳转相应的系统
    if (window.location.href.indexOf("redirect") >= 0) {
      //如果url中包含redirect   split？分割成数组，取第二个
      let redirect = window.location.href.split("?")[1];
      redirect = redirect.substring(9); // 截取字符串第9位开始截取到最后
      this.redirectUrl = redirect;
      console.log(this.redirectUrl);
    }
  },
  mounted() {
    if (JSON.stringify(this.$store.state.psdDInfo) != "{}") {
      this.remember = true;
      let account = this.$store.state.psdDInfo;
      this.user.account = account.account;
      this.user.password = this.decode(account.psd);
    }
    this.keyupSubmit();
  },
  methods: {
    rememberFuc(val) {
      this.remember = val;
    },
    keyupSubmit() {
      let that = this;
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        //!this.clickState是防止用户重复点击回车
        if (_key === 13 && !this.clickState) {
          that.$refs["loginForm"].validate((valid) => {
            if (valid) {
              that.dialogVisible = true;
              that.getImageVerifyCode();
            }
          });
        }
      };
    },
    // 获取图形验证码
    getImageVerifyCode() {
      slideImgCoden({ code: randomUUID() }).then((res) => {
        if (res && res.data) {
          var imgobj = res.data;
          this.catcha.blocky = imgobj.y;
          this.catcha.code = imgobj.code;
          this.catcha.imgurl = "data:image/png;base64," + imgobj.background;
          this.catcha.miniimgurl = "data:image/png;base64," + imgobj.slider;
          this.$nextTick(() => {
            if (this.$refs.dialogopen) {
              this.$refs.dialogopen.reset(imgobj.y);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onRefresh() {
      this.imgurl = "";
      this.miniimgurl = "";
      this.getImageVerifyCode();
    },
    onSuccess(left) {
      // 验证是否成功checkKaptchaImg是后台验证接口方法
      ImgAuth({
        code: this.catcha.code,
        x: left,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$refs.dialogopen.handleSuccess();
            setTimeout(() => {
              this.dialogVisible = false;
              this.imgurl = "";
              this.miniimgurl = "";
              this.uuid = res.data.loginUUID;
              this.loginform();
            }, 1000);
          } else {
            this.$refs.dialogopen.handleFail();
            this.getImageVerifyCode();
          }
        })
        .catch(() => {});
    },
    onFail() {
      this.msgError("错误");
    },
    loginform() {
      let form = {
        account: this.user.account,
        password: this.user.password,
        uuid: this.uuid,
      };
      login({ data: encryptAES(JSON.stringify(form)) }).then((res) => {
        if (res.code == 200) {
          this.$store.commit("saveUserInfo", JSON.parse(decrypt(res.data)));
          this.getDictList();
          this.$router.push({
            path: "/backManage",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取字典
    getDictList() {
      this.$http.getRequest("/dict/list").then((res) => {
        console.log("dict", res);
        this.$store.commit("saveDict", res.data);
      });
    },
    // 登录
    doLogin(formName) {
      this.$store.commit("removeUserInof");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
          this.getImageVerifyCode();
        }
      });
    },
    encode(val) {
      var bytes = [];
      for (var i = 0; i < val.length; i++) {
        bytes.push(val.charCodeAt(i));
      }
      return bytes.join(",");
    },
    decode(val) {
      return String.fromCharCode.apply(null, val.trim().split(","));
    },
  },
};
</script>
<style>
/* body {
  background-image: url(../../assets/img/Bg.png);
  height: 100%;
} */
</style>
<style lang="less" scoped>
.index_icon {
  position: absolute;
  top: 8.5%;
  left: 2%;
  img {
    width: 528px;
    height: 65px;
  }
}
.login_main {
  // background-image: url(../../assets/login/bg.png);
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}
::v-deep .login-wrap .el-input__inner {
  background-color: transparent;
  color: #fff;
  border: 1px solid #86b1ca;
}
::v-deep .login-wrap .el-form-item.is-error .el-input__inner,
::v-deep .login-wrap .el-form-item.is-error .el-input__inner:focus,
::v-deep .login-wrap .el-form-item.is-error .el-textarea__inner,
::v-deep .login-wrap .el-form-item.is-error .el-textarea__inner:focus,
.el-message-box__input input.invalid,
.el-message-box__input input.invalid:focus {
  border-color: #f56c6c;
}
::v-deep .login-wrap .el-form-item__label {
  color: #fff;
  padding: 0 0 0;
}

.login-demo {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: 9;
  .title-flex {
    display: flex;
    color: #fff;
    padding: 15px 30px 15px;
  }
  .title {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
  }
  .littleico {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: end;
  }
  .login-wrap {
    width: 420px;
    margin: 0 auto;
    background: rgba(16, 78, 133, 0.6);
    border-radius: 5px;
    padding: 10px 0;

    .login-form {
      width: 350px;
      margin: 0 auto;
    }
  }

  .login-btn {
    width: 350px;
    margin-top: 10px;
    height: 47px;
    background: #0090ff;
    border-radius: 4px;
    text-align: center;
    line-height: 47px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    transition: all 0.5s;
  }
  .login-btn:hover {
    background: #0173ca;
  }

  .remember-box {
    margin-top: -6px;
    margin-bottom: 22px;
  }
}
::v-deep .remember-box .el-checkbox {
  color: #fff;
}
.login-title {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -25%);
  font-size: 32px;
  letter-spacing: 4px;
}
.footer {
  position: fixed;
  bottom: 50px;
  left: 50%;
  text-align: center;
  transform: translate(-50%);

  div {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a5c6e5;
    line-height: 30px;
  }
}
</style>
