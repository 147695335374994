<template>
  <div class="menu_main">
    <div class="main_head">
      <el-row :gutter="20" style="height: 100%" type="flex" align="middle">
        <el-col :span="3">
          <el-input
            size="small"
            v-model="form.programmeName"
            placeholder="请输入方案名称"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-select
            size="small"
            v-model="form.solutionTypeId"
            placeholder="请选择方案类别"
          >
            <el-option
              v-for="item in solutionType"
              :key="item.id"
              :label="item.solutionType"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            size="small"
            v-model="form.solutionScreenId"
            placeholder="请选择方案场景"
          >
            <el-option
              v-for="item in solutionScene"
              :key="item.id"
              :label="item.solutionScreen"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-button
          size="small"
          icon="el-icon-search"
          type="primary"
          @click="findMenu"
          >查询</el-button
        >
        <el-button
          size="small"
          type="info"
          icon="el-icon-refresh"
          @click="refreshMenu"
          >重置</el-button
        >
        <el-button
          type="primary"
          @click="handleAdd('新增')"
          icon="el-icon-plus"
          size="small"
          >新增</el-button
        >
      </el-row>
    </div>
    <div class="table-main">
      <el-table :data="dataList" stripe style="width: 100%">
        <el-table-column label="序号" type="index" width="60" align="center">
        </el-table-column>
        <el-table-column label="缩略图" prop="name" align="center">
          <template slot-scope="scope">
            <img
              :src="JSON.parse(scope.row.picture)[0].url"
              alt=""
              style="width: 140px; height: 80px"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="方案名称"
          prop="programmeName"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="所属类型"
          prop="solutionTypeName"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="所属场景"
          prop="solutionScreenName"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="发布时间" prop="publishTime" align="center">
        </el-table-column>
        <el-table-column label="操作" width="250px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑">
              <el-button
                type="info"
                icon="el-icon-edit"
                size="mini"
                @click="handleEdit(scope.row, '编辑')"
              ></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="menu_page">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size.sync="size"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
      >
      </el-pagination>
    </div>
    <menuAdd ref="menuAdd" @loadData="loadData"> </menuAdd>
  </div>
</template>

<script>
import menuAdd from "./components/tableSave.vue";
export default {
  name: "menuTable",
  data() {
    return {
      dataList: [],
      total: 0,
      size: 10,
      current: 1,
      url: {
        type: "/solutionType/list",
        scene: "/solutionScreen/list",
        delete: "/solution/deleteById",
        list: "/solution/pageList",
      },
      solutionType: [],
      solutionScene: [],
      productType: [
        {
          id: 1,
          label: "软件产品",
        },
        {
          id: 2,
          label: "硬件产品",
        },
        {
          id: 3,
          label: "其他",
        },
      ],
      form: {
        programmeName: "",
        solutionTypeId: "",
        solutionScreenId: "",
      },
    };
  },
  components: {
    menuAdd,
  },
  mounted() {
    this.loadData();
    this.getSolutionType();
    this.getSceneType();
  },
  methods: {
    /* 获取解决方案类型 */
    getSolutionType() {
      this.$http.getRequest(this.url.type, {}).then((res) => {
        if (res.code === 200) {
          this.solutionType = res.data;
        }
      });
    },
    /* 获取解决方案场景 */
    getSceneType() {
      this.$http.getRequest(this.url.scene, {}).then((res) => {
        if (res.code === 200) {
          this.solutionScene = res.data;
        }
      });
    },
    // 修改
    handleEdit(item) {
      this.$refs.menuAdd.init(item, "编辑");
    },
    // 新增
    handleAdd() {
      this.$refs.menuAdd.init(null, "新增");
    },
    // 重置
    refreshMenu() {
      this.form.programmeName = "";
      this.form.solutionTypeId = "";
      this.form.solutionScreenId = "";
      this.loadData();
    },
    // 查询
    findMenu() {
      this.loadData();
    },
    // 分页
    handleCurrentChange(val) {
      this.current = val;
      this.loadData();
    },
    // 删除
    handleDelete(id) {
      this.$confirm("确定删除该菜单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .deleteRequest(`${this.url.delete}?id=${id}`)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.loadData();
              } else {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            });
        })
        .catch(() => {
          // 取消时的操作
        });
    },
    // 获取表格数据
    loadData() {
      const params = {
        current: this.current,
        size: this.size,
        id: this.form.id,
        programmeName: this.form.programmeName,
        solutionTypeId: this.form.solutionTypeId,
        solutionScreenId: this.form.solutionScreenId,
      };
      this.$http
        .getRequest(this.url.list, params)
        .then((res) => {
          if (res.code == 200) {
            this.dataList = res.data.records;
            this.total = res.data.total;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
.menu_main {
  margin: 0 20px;
  ::v-deep .el-card__body {
    padding: 20px 20px 10px 20px;
  }
  .table-main {
    background: #f3f3f3;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px 20px;
  }
  .main_head {
    background: #f3f3f3;
    height: 80px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0 20px;
    .el-col {
      display: flex;
      align-items: center;
      .text {
        width: 40%;
        text-align: center;
      }
    }
  }
  .menu_table {
    overflow-y: scroll;
  }
  .menu_page {
    margin-top: 10px;
  }
}
</style>
