<template>
  <div class="about-main">
    <el-tabs v-model="activeName">
      <el-tab-pane label="企业介绍" name="1">
        <companyIntroduce />
      </el-tab-pane>
      <el-tab-pane label="企业文化" name="2"><corporateCulture /></el-tab-pane>
      <el-tab-pane label="发展历程" name="3"><history /></el-tab-pane>
      <el-tab-pane label="技术实力" name="4"><technology /></el-tab-pane>
      <el-tab-pane label="企业荣誉" name="5"><companyHonor /></el-tab-pane>
      <el-tab-pane label="照片墙" name="6"><usImage /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import companyIntroduce from "./components/companyIntroduce.vue";
import usImage from "./components/usImage.vue";
import technology from "./components/technology.vue";
import history from "./components/history.vue";
import corporateCulture from "./components/corporateCulture.vue";
import companyHonor from "./components/companyHonor.vue";
export default {
  components: {
    companyIntroduce,
    usImage,
    technology,
    history,
    corporateCulture,
    companyHonor,
  },
  data() {
    return {
      activeName: "1",
    };
  },
};
</script>

<style lang="less" scoped>
.about-main {
  padding: 0 10px;
}
</style>
