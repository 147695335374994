<template>
  <div class="home-main">
    <div class="home-head">
      <div class="head-logo">
        <img src="../../../assets/logo.png" alt="" sizes="" srcset="" />
      </div>
      <div class="head-menu">
        <div
          class="menu-item"
          v-for="(item, index) in headMenu"
          :key="index"
          :style="{ color: headMenuIndex === index ? '#d81e06' : '' }"
          @mouseover="handleMenu(item, index)"
        >
          <div class="item-icon" v-if="item.icon">
            <img
              :src="headMenuIndex === index ? item.icon : item.iconNo"
              alt=""
            />
          </div>
          <div class="item-title">
            {{ item.title }}
            <span v-if="item.children" class="el-icon-arrow-down"></span>
          </div>
          <div class="item-icon" v-if="item.icon">
            <img
              :src="headMenuIndex === index ? item.wait : item.waitNo"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="head-phone">
        <img src="../../../assets/home/call_phone.png" alt="" />
        400-104-8119
      </div>
    </div>
    <!-- 点击解决方案 -->
    <div class="solveMenu" @mouseleave="mouseleaveParent">
      <div class="clickSolve" v-if="isPrudoctMore">
        <div
          class="parent"
          ref="solveParent"
          v-for="(item, index) in solutionType"
          :key="index"
          :style="{
            color: solveIndex === index ? '#ffffff' : '',
            backgroundColor: solveIndex === index ? '#d81e06' : '',
          }"
          @mouseover="clickParent(item, index)"
          @click="clickParent(item, index)"
        >
          <div class="parent_text">{{ item.solutionType }}</div>
        </div>
      </div>
      <div
        class="clickChildren"
        v-if="isPrudoctMore && this.scene != ''"
        :style="{
          top: solveIndex * 61 + 'px',
        }"
      >
        <div
          class="children"
          v-for="(item, index) in scene"
          :key="index"
          :style="{
            color: sceneIndex === item.screenId ? '#ffffff' : '',
            backgroundColor: sceneIndex === item.screenId ? '#d81e06' : '',
          }"
          @click="clickChildren(item, index)"
        >
          <div class="children_text">
            {{ item.solutionScreenName }}
          </div>
        </div>
      </div>
    </div>

    <!-- <transition name="el-zoom-in-top" v-if="isPrudoctMore">
      <div class="product_more">
        <div
          class="product_item"
          v-for="(item, index) in headMenu[1].children"
          @click="handleProcut(item, index)"
          :key="index"
        >
          {{ item.productName }}
        </div>
      </div>
    </transition> -->
    <div class="home_body">
      <router-view />
    </div>
    <div class="home_bottom">
      <div class="icon">
        <img src="@/assets/home/index_icon.png" alt="" />
        <div class="company_phone">
          <img src="@/assets/home/index_phone.png" alt="" />
          <div class="phone_title">服务热线</div>
        </div>
        <div class="phone">400-104-8119</div>
        <div class="phone">hlkjyxgs2014@163.com</div>
        <div class="company_phone">
          <img src="@/assets/home/index_address.png" alt="" />
          <div class="phone_title">公司地址</div>
        </div>
        <div class="address">宁夏省银川市西夏区中关村创新中心A座20楼</div>
      </div>
      <div class="product_center">
        <div class="pro_title">产品中心</div>
        <div
          class="pro_item"
          v-for="(item, index) in productList"
          :key="index"
          @click="producFtindDetail(item)"
        >
          {{ item.productName }}
        </div>
      </div>
      <div class="about_us">
        <div class="about_title">关于我们</div>
        <div
          class="about_item"
          v-for="(item, index) in aboutUsList"
          :key="index"
          @click="aboutUsFindDetail(item)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="solution">
        <div class="solution_title">解决方案</div>
        <div
          class="solution_item"
          v-for="(item, index) in solutionList"
          :key="index"
          @click="findDetail(item)"
        >
          {{ item.programmeName }}
        </div>
      </div>
      <div class="wx_gz">
        <img src="@/assets/home/index_wx_gz.png" alt="" />
        <div class="wx_title">关注公众号</div>
      </div>
      <!-- <div class="friend_link">
        友情链接：
        <div
          class="link_item"
          v-for="(item, index) in friendlyLinks"
          :key="index"
          @click="jumpLinks(item)"
        >
          {{ item.title }}
        </div>
      </div> -->
      <div class="company_info">恒力科技 宁ICP备17002344号-1</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productionIndex: 0,
      isPrudoctMore: false,
      headMenuIndex: 0,
      solveIndex: 0,
      sceneIndex: 0,
      solveTypeId: 0,
      headMenu: [
        {
          title: "首页",
          icon: require("@/assets/home/home_select.png"),
          iconNo: require("@/assets/home/home_no.png"),
          path: "/frontIndex",
          wait: require("@/assets/home/home_wait_select.png"),
          waitNo: require("@/assets/home/home_wait_no.png"),
        },
        {
          title: "产品中心",
          // children: [],
          icon: "",
          path: "/product",
        },
        {
          title: "解决方案",
          icon: "",
          path: "/solution",
        },
        {
          title: "恒力动态",
          icon: "",
          path: "/trends",
        },
        {
          title: "行业资讯",
          icon: "",
          path: "/industryInformation",
        },
        {
          title: "关于我们",
          icon: "",
          path: "/aboutUs",
        },
        {
          title: "诚聘英才",
          icon: "",
          path: "/joinUs",
        },
      ],
      friendlyLinks: [],
      url: {
        friendlyLinks: "/indexWebsite/getFooterFriendlyLinks",
        product: "/indexWebsite/getFooterProduct",
        solution: "/indexWebsite/getFooterSolution",
        productAll: "/indexWebsite/productPageList",
        solutionType: "/indexWebsite/getSolutionTypeList", //查询行业解决方案类型
        scene: "/indexWebsite/getScreenListByType", //查询行业解决方案场景
        solutionLists: "/indexWebsite/solutionPageList", //解决方案
      },
      aboutUsList: [
        {
          title: "公司介绍",
          url: "/aboutUs",
          id: "about_introduce",
        },
        {
          title: "公司成就",
          url: "/aboutUs",
          id: "about_honor",
        },
        {
          title: "宣传片展示",
          url: "/aboutUs",
          id: "about_introduce",
        },
        {
          title: "团队风采",
          url: "/aboutUs",
          id: "about_contact_us",
        },
      ],
      productList: [],
      productListAll: [],
      solutionList: [],
      // 解决方案类型和场景
      solutionType: [],
      scene: [],
      solution: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    // 执行你的操作，如更新数据或触发事件等
    let i = this.headMenu.findIndex((e) => e.path === to.path);
    this.headMenuIndex = i;
    next();
  },
  mounted() {
    let url = this.$route.path;
    let i = this.headMenu.findIndex((e) => e.path === url);
    this.headMenuIndex = i;
    this.getFriendlyLinks();
    this.getProductList();
    this.getSolutionList();
    // this.getProductAll();
    this.getSolutionType(); //行业解决方案类型和场景
  },
  methods: {
    handleMenu(item, index) {
      this.headMenuIndex = index;
      if (item.children) {
        this.isPrudoctMore = this.isPrudoctMore ? false : true;
      } else {
        this.isPrudoctMore = false;
        if (item.path != this.$route.path) {
          this.$router.push({
            path: item.path,
          });
        }
      }

      // 如果点击解决方案
      if (index == 2) {
        this.isPrudoctMore = true;
      }
    },
    clickParent(item, index) {
      this.solveTypeId = item.id;
      this.solveIndex = index;
      this.getScreenList(item.id);
    },
    mouseleaveParent() {
      this.isPrudoctMore = false;
    },
    clickChildren(item, index) {
      this.$store.commit("solveIdSub", item.screenId);
      this.$store.commit("solveTypeIdSub", this.solveTypeId);
      this.sceneIndex = item.screenId;
      setTimeout(() => {
        this.isPrudoctMore = false;
      }, 300);
    },
    // 类型
    getSolutionType() {
      this.$http
        .getRequest(this.url.solutionType)
        .then((res) => {
          if (res.code == 200) {
            this.solutionType = res.data;
            console.log("this.solutionType", this.solutionType);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },

    // 根据类型查询场景
    getScreenList(solutionTypeId) {
      this.$http
        .getRequest(this.url.scene + "?solutionTypeId=" + solutionTypeId)
        .then((res) => {
          if (res.code == 200) {
            this.scene = res.data;
            console.log("this.scene", this.scene);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
    getProductAll() {
      this.$http
        .getRequest(this.url.productAll, { current: 1, size: -1 })
        .then((res) => {
          if (res.code === 200) {
            this.headMenu[1].children = res.data.records;
            console.log("this.headMenu[1].children", this.headMenu[1].children);
          }
        });
    },
    // 产品中心
    producFtindDetail(item) {
      if (this.$route.path == "/product") {
        const a = document.createElement("a");
        a.href = "#body_item";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$router.push({
          path: "/product",
          hash: "#body_item",
        });
      }
    },
    // 关于我们
    aboutUsFindDetail(item) {
      if (this.$route.path == "/aboutUs") {
        const a = document.createElement("a");
        a.href = "#" + item.id;
        document.body.appendChild(a);
        a.click();
      } else {
        this.$router.push({
          path: "/aboutUs",
          query: { id: item.id },
        });
      }
    },
    // 解决方案
    findDetail(item) {
      this.$router.push({
        path: "/details",
        query: { item: item },
      });
    },
    getProductList() {
      this.$http.getRequest(`${this.url.product}?limit=7`).then((res) => {
        if (res.code === 200) {
          this.productList = res.data;
        }
      });
    },
    getSolutionList() {
      this.$http.getRequest(`${this.url.solution}?limit=7`).then((res) => {
        if (res.code === 200) {
          this.solutionList = res.data;
        }
      });
    },
    jumpLinks(item) {
      window.open(item.goUrl);
    },
    getFriendlyLinks() {
      this.$http.getRequest(this.url.friendlyLinks).then((res) => {
        if (res.code === 200) {
          this.friendlyLinks = res.data;
        }
      });
    },
    handleProcut(item, index) {
      this.productionIndex = index;
      this.$router.push({
        path: "/details",
        query: { item: item },
      });
    },
  },
};
</script>

<style scoped lang="less">
.home-main {
  width: 100vw;
  position: relative;
  // 点击解决方案出来的选择框
  .solveMenu {
    position: fixed;
    .clickSolve {
      cursor: pointer;
      width: 175px;
      height: 352px;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      position: absolute;
      margin-left: 610px;
      overflow-y: scroll;
      border-left: 2px solid #d71a18;
      .parent {
        height: 57px;
        border-bottom: 1px solid #e4e4e4;
        .parent_text {
          margin-left: 25px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 60px;
        }
      }
    }

    .clickChildren {
      cursor: pointer;
      width: 175px;
      height: 352px;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      margin-left: 783px;
      overflow-y: scroll;
      position: absolute;
      .children {
        width: 170px;
        height: 60px;
        border-bottom: 1px solid #e4e4e4;
        .children_text {
          margin-left: 25px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 60px;
        }
      }
    }
  }

  .home_bottom {
    width: 100vw;
    height: 520px;
    // background-color: #000000;
    background-image: url("@/assets/home/index_bottom_bg.png");
    background-size: 100% 100%;
    .wx_gz {
      // margin-left: 149px;
      margin-top: 59px;
      .wx_title {
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
        width: 121px;
        text-align: center;
      }
      img {
        width: 121px;
        height: 121px;
      }
    }
    .solution {
      color: #fff;
      // margin-left: 149px;
      width: 15%;
      margin-top: 59px;
      .solution_title {
        font-size: 24px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
      }
      .solution_item {
        width: 60%;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
        margin: 23px 0;
        overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
        text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
        white-space: nowrap; /*文本不换行*/
        cursor: pointer;
      }
      .solution_item:hover {
        color: #0000ee;
      }
    }
    .about_us {
      width: 15%;
      color: #fff;
      margin-top: 59px;
      .about_title {
        font-size: 24px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
      }
      .about_item {
        width: 60%;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
        margin: 23px 0;
        overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
        text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
        white-space: nowrap; /*文本不换行*/
        cursor: pointer;
      }
      .about_item:hover {
        color: #0000ee;
      }
    }
    .product_center {
      width: 15%;
      color: #fff;
      margin-left: 251px;
      margin-top: 59px;
      .pro_title {
        font-size: 24px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
      }
      .pro_item {
        width: 60%;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
        margin: 23px 0;
        overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
        text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
        white-space: nowrap; /*文本不换行*/
        cursor: pointer;
      }
      .pro_item:hover {
        color: #0000ee;
      }
    }
    .company_info {
      width: 100vw;
      height: 57px;
      line-height: 57px;
      color: #ffffff;
      bottom: 0;
      position: absolute;
      padding-left: 261px;
    }
    display: flex;
    .friend_link {
      width: 100vw;
      height: 57px;
      line-height: 57px;
      display: flex;
      position: absolute;
      bottom: 59px;
      border-top: 1px solid #474747;
      border-bottom: 1px solid #474747;
      padding-left: 261px;
      color: #ffffff;
      .link_item {
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
        cursor: pointer;
        margin: 0 20px;
      }
      .link_item:hover {
        color: #0000ee;
      }
    }
    .icon {
      .address {
        margin-top: 10px;
        margin-left: 44px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #ffffff;
      }
      .phone {
        margin-top: 10px;
        margin-left: 44px;
        font-size: 30px;
        font-family: Alibaba PuHuiTi 2;
        font-weight: normal;
        color: #dd1619;
      }
      .company_phone {
        margin: 40px 0 0 0;
        display: flex;
        .phone_title {
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #ffffff;
          margin-left: 20px;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      margin: 58px 0 0 264px;
      img {
        width: 229px;
        height: 52px;
      }
    }
  }
  .product_more {
    position: absolute;
    width: 100vw;
    height: 200px;
    overflow-y: scroll;
    background-color: #fff;
    border-top: 1px dotted #eee;
    top: 85px;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    .product_item {
      padding: 10px;
      width: 18vw;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
    }
    .product_item:hover {
      color: #dd1619;
    }
  }
  .home_body {
    margin-top: 84px;
  }
  .home-head {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 84px;
    display: flex;
    z-index: 999;
    .head-phone {
      font-size: 24px;
      font-family: Impact;
      font-weight: 400;
      color: #dd1619;
      height: 84px;
      line-height: 84px;
      margin-left: auto;
      margin-right: 30px;
      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
    }
    .head-menu {
      display: flex;
      margin-left: 60px;
      .menu-item {
        margin: 0 30px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        display: flex;
        align-items: center; //对齐
        justify-content: center; //居中
        cursor: pointer;
        .item-icon {
          margin: -3px 5px 0 5px;
          img {
            width: 22px;
            height: 22px;
            vertical-align: middle;
          }
        }
      }
    }
    .head-logo {
      margin: 20px 0 0 60px;
      img {
        width: 170px;
        height: 40px;
      }
    }
  }
}
</style>
