//合作伙伴 //后台管理轮播图
<template>
  <div class="body">
    <div style="margin-left: 15px">
      <el-button size="small" type="primary" @click="addPage" class="button"
        >添加合作伙伴</el-button
      >
    </div>
    <div class="img_main">
      <div class="img_item" v-for="(item, index) in imgList" :key="index">
        <div class="item_top">
          <el-button type="text" @click="homePage(item)">更换</el-button>
          <el-button type="text" style="color: red" @click="dele(item.id)"
            >删除</el-button
          >
        </div>
        <div class="item_content">
          <img :src="$imgUrl + item.picture" alt="" />
        </div>
      </div>
    </div>
    <addDialog ref="addDialog" @getImgList="getImgList"></addDialog>
  </div>
</template>
<script>
import addDialog from "./model/addDialog.vue";
export default {
  components: {
    addDialog,
  },
  data() {
    return {
      url: {
        imgList: "/partners/pageList",
        delete: "/partners/deleteById",
      },
      imgList: [], //全部
    };
  },
  mounted() {
    this.getImgList();
  },
  methods: {
    // 添加合作伙伴
    addPage() {
      this.$refs.addDialog.init(null, "添加");
    },
    // 合作伙伴更换
    homePage(item) {
      this.$refs.addDialog.init(item, "更换");
    },
    // 列表查询
    getImgList() {
      this.$http.getRequest(this.url.imgList).then((res) => {
        if (res.code === 200) {
          this.imgList = res.data.records;
        }
      });
    },

    // 删除
    dele(id) {
      this.$confirm("此操作将永久删除该产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .deleteRequest(`${this.url.delete}?id=${id}`)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getImgList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  .img_main {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    .img_item {
      width: calc(100% / 5);
      height: 240px;
      margin: 15px;
      border: 1px solid #ebebeb;
      .item_top {
        height: 40px;
        line-height: 40px;
        text-align: right;
        padding-right: 10px;
      }
      .item_content {
        img {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}
</style>
