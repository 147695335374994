<template>
  <div>
    <el-tabs
      v-model="menuPath"
      type="card"
      closable
      @tab-click="selectMenu"
      @tab-remove="removeTab"
    >
      <el-tab-pane
        v-for="item in editableTabs"
        :key="item.name"
        :label="item.title"
        :name="item.name"
      >
        <div class="center-body">
          <router-view />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuPath: "/backManage/bannerMan",
      editableTabs: [],
    };
  },

  watch: {
    $route: {
      handler(to, form = null) {
        let i = this.editableTabs.findIndex((e) => e.name === to.path);
        //当路由改变时，检测该路由是否已经在打开的页面之中，如果不在，就添加进去
        if (i === -1) {
          this.editableTabs.push({
            title: to.name,
            name: to.path,
            path: to.path,
          });
        }
        this.menuPath = to.path;
      },
    },
  },

  mounted() {
    let i = this.editableTabs.findIndex((e) => e.name === this.$route.path);
    //当路由改变时，检测该路由是否已经在打开的页面之中，如果不在，就添加进去
    if (i === -1) {
      this.editableTabs.push({
        title: this.$route.name,
        name: this.$route.path,
        path: this.$route.path,
      });
    }
    this.menuPath = this.$route.path;
  },
  methods: {
    selectMenu(item) {
      this.menuPath = item.name;
      this.$router.push({
        path: item.name,
      });
      this.$emit("getNewMenu", item.name);
    },
    removeTab(targetName) {
      if (this.editableTabs.length === 1) {
        this.$message.warning("最后一个标签不能关闭");
      } else {
        let i = this.editableTabs.findIndex((e) => e.name === targetName);
        if (targetName != this.$route.path) {
          this.editableTabs.splice(i, 1);
        } else {
          this.editableTabs.splice(i, 1);
          this.$router.push({
            path: this.editableTabs[this.editableTabs.length - 1].path,
          });
          this.$emit(
            "getNewMenu",
            this.editableTabs[this.editableTabs.length - 1].path
          );
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
