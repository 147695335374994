//后台管理轮播图
<template>
  <div class="body">
    <div class="content" v-for="(item, index) in imgList" :key="index">
      <div class="content_top">
        <!-- 产品中心,解决方案,恒力动态,行业资讯,诚聘英才,关于我们 -->
        <span>{{
          index === 0
            ? "首页"
            : index === 1
            ? "产品中心"
            : index === 2
            ? "解决方案"
            : index === 3
            ? "恒力动态"
            : index === 4
            ? "行业资讯"
            : index === 5
            ? "诚聘英才"
            : "关于我们"
        }}</span>
        <el-button type="text" @click="handleImg(item)" class="button"
          >更换</el-button
        >
      </div>
      <div class="content_bottom">
        <img
          v-if="item.fileUrl && item.resourceType === 2"
          :src="$imgUrl + item.fileUrl"
          alt=""
        />
        <video
          controls
          v-if="item.fileUrl && item.resourceType === 1"
          :src="$imgUrl + item.fileUrl"
        ></video>
      </div>
    </div>
    <addDialog ref="addDialog" @getImgList="getImgList"></addDialog>
  </div>
</template>
<script>
import addDialog from "./model/addDialog.vue";
export default {
  components: {
    addDialog,
  },
  data() {
    return {
      url: {
        imgList: "/banner/list",
        add: "/banner/update",
      },
      imgList: [],
    };
  },
  mounted() {
    this.getImgList();
  },
  methods: {
    // 列表查询
    getImgList() {
      this.$http.getRequest(this.url.imgList).then((res) => {
        if (res.code === 200) {
          this.imgList = res.data;
        }
      });
    },
    handleImg(item) {
      const input = document.createElement("input");
      input.type = "file";
      input.style.position = "absolute";
      input.style.top = "0";
      input.style.right = "0";
      input.style.opacity = "0";
      input.style.overflow = "hidden";
      document.body.appendChild(input);
      input.click();
      input.addEventListener("change", (event) => {
        const file = input.files[0];
        let resourceType = null;
        if (file.type.includes("video")) {
          resourceType = 1;
        } else {
          resourceType = 2;
        }
        var formData = new FormData();
        formData.append("file", file);
        this.$config.fileUpload(formData).then((res) => {
          if (res.code === 200) {
            let obj = {
              id: item.id,
              bannelType: item.bannelType,
              resourceType: resourceType,
              fileUrl: res.data,
            };
            this.$http.postRequest(this.url.add, obj).then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.getImgList();
              } 
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  .content {
    width: 47%;
    height: 260px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    margin-left: 35px;
    margin-top: 20px;
    .content_top {
      height: 40px;
      line-height: 40px;
      span {
        margin-left: 30px;
        font-size: 17px;
      }
      .button {
        margin-right: 20px;
        float: right;
      }
    }

    .content_bottom {
      height: 80%;
      img {
        width: 100%;
        height: 100%;
      }
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
