<template>
  <div class="body">
    <!-- 头部轮播图 -->
    <div class="head">
      <!-- 轮播图 -->
      <div class="head_banner">
        <img
          v-if="this.indexBanner.resourceType == 2"
          :src="$imgUrl + this.indexBanner.fileUrl"
          alt=""
        />
        <video
          v-if="this.indexBanner.resourceType == 1"
          :src="$imgUrl + this.indexBanner.fileUrl"
          autoplay
          loop
        ></video>
      </div>
    </div>
    <!-- 地下内容 -->
    <div class="content">
      <div
        class="content_title"
        v-for="(item, index) in industryList"
        :key="index"
      >
        <div class="content_title_div" @click="findDetail(item)">
          <div class="content_title_div_img">
            <img :src="JSON.parse(item.picture)[0].url" alt="" />
          </div>
          <div class="content_title_div_title">
            <div class="title">{{ item.title }}</div>
            <div
              class="title_content"
              v-html="item.content"
              v-resize-images
            ></div>
            <div class="time">{{ item.publishTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="main_table_page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.current"
        :page-sizes="[10, 20, 30]"
        :page-size="pageObj.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageObj.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageObj: {
        current: 1,
        size: 10,
        total: 0,
      },
      indexBanner: "",
      industryList: [],
      url: {
        banner: "/indexWebsite/getIndexBannerByType", //banner
        industry: "/indexWebsite/newsPageList",
      },
    };
  },
  // 富文本的img设置样式无法生效时，使用该方法，要在标签中添加v-resize-images
  directives: {
    resizeImages: {
      inserted(el) {
        const images = el.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
          // images[i].style.maxWidth = '150px'; // 设置图片最大宽度
          // images[i].style.maxHeight = '150px'; // 设置图片最大高度
          images[i].style.display = "none"; // 隐藏图片
        }
      },
    },
  },
  mounted() {
    this.getIndexBanner();
    this.getIndustryList();
  },
  methods: {
    findDetail(item) {
      this.$router.push({
        path: "/details",
        query: { item: item },
      });
    },

    getIndexBanner() {
      this.$http
        .getRequest(this.url.banner, { bannerType: 5 })
        .then((res) => {
          if (res.code == 200) {
            this.indexBanner = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
    getIndustryList() {
      this.$http
        .getRequest(this.url.industry, { type: 2 })
        .then((res) => {
          if (res.code == 200) {
            this.industryList = res.data.records;
            this.pageObj.total = res.data.total;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },

    // 分页
    handleCurrentChange(val) {
      this.pageObj.current = val;
      this.getSolution();
    },
    //调整分页
    handleSizeChange(val) {
      this.pageObj.size = val;
      this.getSolution();
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  margin-bottom: 20px;
}
.head {
  height: 408px;
  margin: auto;
  .head_banner {
    height: 408px;
    img {
      width: 100%;
      height: 408px;
    }
    video {
      width: 100%;
      height: 408px;
    }
  }
}
.content {
  .content_title {
    .content_title_div {
      display: flex;
      width: 1401px;
      height: 229px;
      background: #f7f8fc;
      cursor: pointer;
      border-radius: 12px;
      margin-left: 259px;
      margin-top: 30px;
      .content_title_div_img {
        width: 302px;
        height: 169px;
        margin-left: 22px;
        margin-top: 29px;
        overflow: hidden;
        img {
          width: 302px;
          height: 169px;
          border-radius: 6px;
          transition: transform 0.5s;
          cursor: pointer;
        }
        img:hover {
          transform: scale(1.2);
        }
      }

      .content_title_div_title {
        margin-left: 36px;
        .title {
          width: 1015px;
          font-size: 26px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #333333;
          margin-top: 34px;
          overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
          text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
          white-space: nowrap; /*文本不换行*/
        }
        .title_content {
          width: 1027px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #757575;
          margin-top: 17px;
          line-height: 30px;
          display: -webkit-box; /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 2; /*设置多少行*/
          -webkit-box-orient: vertical; /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
          overflow: hidden; /*文本会被修剪，并且剩余的部分不可见*/
          text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
        }
        .time {
          width: 1027px;
          height: 44px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2;
          font-weight: normal;
          color: #757575;
          line-height: 28px;
          margin-top: 10px;
        }
      }
    }
  }
}
.main_table_page {
  margin-top: 20px;
  margin-left: 685px;
}
</style>
