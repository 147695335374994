<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <el-form
        :model="model"
        :rules="rule"
        ref="registerRef"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="标题" prop="title">
              <el-input
                v-model="model.title"
                placeholder="请输入标题"
                style="width: 90%; margin-left: 14px"
              ></el-input>
            </el-form-item>
            <el-form-item label="跳转链接" prop="goUrl">
              <el-input
                v-model="model.goUrl"
                placeholder="请输入跳转链接"
                style="width: 90%; margin-left: 14px"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input-number
                style="margin-left: 15px"
                v-model="model.sort"
                :min="1"
                :max="999"
                label="排序"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('registerRef')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "menuSave",
  data() {
    return {
      model: {},
      dialogVisible: false,
      url: {
        edit: "/friendlyLinks/update",
        add: "/friendlyLinks/insert",
      },
      title: "",
      rule: {
        title: {
          required: true,
          message: "请输入标题",
          trigger: "blur",
        },
        goUrl: {
          required: true,
          message: "请输入跳转链接",
          trigger: "blur",
        },
        sort: {
          required: true,
          message: "请输入排序，只能输入整数",
          trigger: "blur",
        },
      },
    };
  },
  mounted() {},
  methods: {
    init(record, title) {
      this.title = title;
      if (record) {
        this.model = record;
      } else {
        this.model = {};
      }
      this.dialogVisible = true;
    },
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let httpurl = "";
          if (!this.model.id) {
            httpurl += this.url.add;
          } else {
            httpurl += this.url.edit;
          }
          this.$http.postRequest(httpurl, this.model).then((res) => {
            if (res.code == 200) {
              that.$message.success("操作成功");
              that.$emit("loadData");
              this.dialogVisible = false;
            } else {
              that.$message.warning("操作失败");
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ql-editor {
  line-height: 1.5;
}
.form-content {
  height: 400px;
}
.solution-type {
  display: flex;
  .item {
    padding: 0 10px;
    border: 1px solid #eee;
    margin-left: 14px;
    border-radius: 4px;
  }
  cursor: pointer;
}
.avatar-uploader {
  width: 200px;
  height: 100px;
  border: 1px dashed #e6e4e4;
  top: 400px;
  left: 1305px;
  margin-left: 15px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 100px;
  display: block;
}
</style>
